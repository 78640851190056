import styled from 'styled-components';
import { FontXs, SingleLineEllipsis } from 'styles/typography';

export default {
  Wrapper: styled.div``,
  CardTitle: styled.div`
    ${FontXs}

    color: ${({ theme }) => theme.colors.textDisabled};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  `,
  CardWrapper: styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing[8]};
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    border-radius: ${({ theme }) => theme.border.radius.md};
    max-width: 400px;
    overflow: hidden;

    :hover {
      .metric-filter-rule-actions {
        opacity: 1;
      }
    }
  `,
  DragHandle: styled.div`
    width: 16px;
  `,
  RuleContainer: styled.div`
    ${FontXs};

    color: ${({ theme }) => theme.colors.textBody2};
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    margin-left: ${({ theme }) => theme.spacing[8]};
  `,

  Operator: styled.span`
    ${FontXs}

    color: ${({ theme }) => theme.colors.gray400};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    width: fit-content;
    overflow: visible;
  `,
  OperatorValueWrapper: styled.div`
    margin-top: ${({ theme }) => theme.spacing[4]};
  `,
  ValueWrapper: styled.span`
    ${SingleLineEllipsis};
    ${FontXs};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.primary500};
    cursor: pointer;
  `,
};
