import { type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import { type ListGridRow } from '../grid/types';
import { CloseAction } from './close-action';
import { DeleteAction } from './delete-action';
import { SelectedCount } from './selected-count';
import styles from './styles';

const { Wrapper } = styles;

interface Props {
  onDeleteListRows: (rowNode: ListGridRow[]) => void;
}

export const ActionsFloatingWidget = ({ onDeleteListRows }: Props): ReactElement => {
  const selectedRowIds = useListStoreContext((s) => s.selectedRowIds);
  const embedPlace = useListStoreContext((s) => s.embedPlace);

  if (!selectedRowIds?.length) {
    return <></>;
  }

  return (
    <Wrapper bottom={embedPlace === 'global-list' ? 68 : 42}>
      <SelectedCount />

      <DeleteAction onDeleteListRows={onDeleteListRows} />

      <CloseAction />
    </Wrapper>
  );
};
