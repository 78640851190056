import { type FormProps } from 'components/modules/modelling/lists/crud-components/dimension-group-create-edit-form/types';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { formatName } from 'utils/data-formatter';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';

export const setInitialValues = (dimensionGroup: DimensionGroup): Partial<FormProps> => {
  const selectedGroupDimensions = dimensionGroup.columns.filter((dim) => !!dim.source);

  return {
    groupName: dimensionGroup.displayName,
    groupDimensions: selectedGroupDimensions.map((dimGrp) => ({
      parentSourceDisplayName: dimGrp.tableDisplayName,
      columnType: dimGrp.type,
      label: formatName(dimGrp.colName),
      value: dimGrp.source,
      name: dimGrp.colName,
      tableDisplayName: dimGrp.tableDisplayName,
      formula: dimGrp.formula ?? null,
    })),
    uniqueKeyColumns: dimensionGroup.uniqueKeyColumns.map((col) => {
      const groupDimension = selectedGroupDimensions?.find((dim) => dim.colName === col);

      return {
        value: groupDimension?.source || col,
        label: getColumnNameFromRawDimensionName(formatName(col)),
        parentSourceDisplayName: groupDimension?.tableDisplayName || '',
      };
    }),
    filters: dimensionGroup.filters,
  };
};
