import { type ColDef } from 'ag-grid-community';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { MoreActionsDropdown } from 'components/ui/atomic-components';
import {
  DeleteConfirmWithUsage,
  ResourceUsageModal,
  useResourceUsageWidget,
} from 'components/ui/resource-usage-widget';
import { useDimensionGroupMutations } from 'data/modelling/dimension-group/hooks/use-dimension-group-mutations';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { b } from 'styles/html-elements';
import { formatName } from 'utils/data-formatter';
import { downloadGridAsExcel } from 'utils/grid';
import { queueMacroTask } from 'utils/queue-macro-task';
import { GridTopBar } from '../../grid-top-bar';
import { EditDimensionGroupModal } from './edit-dimension-group-modal';
import { getMoreActionsOptions } from './more-actions-menu';

interface Props {
  dimensionGroup: DimensionGroup;
  toggleShowLeftNav: () => void;
  showLeftNav: boolean;
}

export const DimensionGroupTopNav = ({
  dimensionGroup,
  toggleShowLeftNav,
  showLeftNav,
}: Props): ReactElement => {
  const gridApi = useListStoreContext((s) => s.gridApi);

  const { deleteDimensionGroup } = useDimensionGroupMutations();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const isReadOnly = isInVersionMode([VersionAccessModes.View, VersionAccessModes.Edit]);

  const [isMoreActionsMenuOpen, setIsMoreActionsMenuOpen] = useState(false);
  const { usageWidgetMenuItem, usageModalProps, isTryingToCloseWhileUsageFetch, closeUsageModal } =
    useResourceUsageWidget({
      name: dimensionGroup.name,
      displayName: dimensionGroup.displayName || '',
      type: 'DIMENSION_GROUPS',
      closeMenu: () => setIsMoreActionsMenuOpen(false),
    });

  const onDownloadAsExcel = () => {
    downloadGridAsExcel({
      gridApi,
      fileName: dimensionGroup.name,
      columnKeys: gridApi
        ?.getColumnDefs()
        ?.filter((col: ColDef) => !col?.hide)
        ?.slice(1) // skip the # column
        ?.map((col: ColDef) => col.colId || ''),
    });
  };

  const menuItems = getMoreActionsOptions({
    usageWidgetMenuItem,
    onDelete: () => setOpenDeleteModal(true),
    onEdit: () => setOpenEditModal(true),
    onDownload: onDownloadAsExcel,
    isReadOnly,
  });

  const handleDelete = async () => {
    await deleteDimensionGroup.mutateAsync({ ...dimensionGroup });

    setOpenDeleteModal(false);
  };

  return (
    <>
      <GridTopBar
        isDerivedList
        isReadOnly={isReadOnly}
        listName={dimensionGroup.displayName || ''}
        rightItems={
          <MoreActionsDropdown
            menu={{ items: menuItems }}
            open={isMoreActionsMenuOpen}
            onOpenChange={(toOpen) => {
              queueMacroTask(() => {
                if (isTryingToCloseWhileUsageFetch(toOpen)) {
                  return;
                }

                setIsMoreActionsMenuOpen(toOpen);
              });
            }}
          />
        }
        showLeftNav={showLeftNav}
        toggleShowLeftNav={toggleShowLeftNav}
      />

      <EditDimensionGroupModal
        dimensionGroup={dimensionGroup}
        open={openEditModal}
        setOpen={setOpenEditModal}
      />

      <DeleteConfirmWithUsage
        displayName={dimensionGroup.displayName || ''}
        name={dimensionGroup.name}
        noUsagesMessage={
          <FormattedMessage
            id="delete_modal_formula_del.list.question"
            values={{ list: formatName(dimensionGroup.name), b }}
          />
        }
        open={openDeleteModal}
        type="DIMENSION_GROUPS"
        onCancel={() => setOpenDeleteModal(false)}
        onOk={handleDelete}
      />

      <ResourceUsageModal usageProps={usageModalProps} onClose={closeUsageModal} />
    </>
  );
};
