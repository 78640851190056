import { VaryingColGroup } from 'data/page-template/grid';
import { ACTUAL } from 'data/versions';
import { isNil } from 'lodash';
import { sign } from 'utils/null-safe-sign';

export const getStatisticalValue = ({
  statistic,
  baselineColName,
  comparedColName,
  baselineNumber,
  comparedNumber,
}: {
  statistic: VaryingColGroup;
  baselineColName: string;
  comparedColName: string;
  baselineNumber?: number | null;
  comparedNumber?: number | null;
}): number | null => {
  if (baselineColName === ACTUAL || comparedColName === ACTUAL) {
    const actualNumber = baselineColName === ACTUAL ? baselineNumber : comparedNumber;
    const nonActualNumber = baselineColName === ACTUAL ? comparedNumber : baselineNumber;

    return statisticalFunctions[statistic](nonActualNumber, actualNumber);
  }

  return statisticalFunctions[statistic](baselineNumber, comparedNumber);
};

const statisticalFunctions = {
  [VaryingColGroup.Variance]: (
    baselineNumber?: number | null,
    comparedNumber?: number | null,
  ): number | null => {
    if (isNil(baselineNumber) && isNil(comparedNumber)) {
      return null;
    }

    if (isNil(baselineNumber)) {
      return comparedNumber as number;
    }

    if (isNil(comparedNumber)) {
      return -baselineNumber;
    }

    return comparedNumber - baselineNumber;
  },
  [VaryingColGroup.VariancePercentage]: (
    baselineNumber?: number | null,
    comparedNumber?: number | null,
  ): number | null => {
    if (!baselineNumber) {
      return null;
    }

    if (!comparedNumber) {
      return Math.sign(baselineNumber) === 1 ? -1 : 1;
    }

    return (comparedNumber - baselineNumber) / Math.abs(baselineNumber);
  },
  [VaryingColGroup.Attainment]: (
    baselineNumber?: number | null,
    comparedNumber?: number | null,
  ): number | null => {
    // Both numbers are non zero. If one of the numbers is negative, attainment is negative
    if (baselineNumber && comparedNumber) {
      return (comparedNumber as number) / (baselineNumber as number);
    }

    if (!comparedNumber && sign(baselineNumber) === 1) {
      return 0;
    }

    return null;
  },
  // unused fn
  [VaryingColGroup.VarianceAndAttainment]: (
    baselineNumber?: number | null,
    comparedNumber?: number | null,
  ): number | null => {
    return null;
  },
};
