import { type EntityStatus, type ID } from 'data';
import { type QueryTableColumnDataType } from 'data/big-query';
import { type DimensionProperty } from 'data/dimension';
import { type AccessAction } from 'data/roles/permissions';

export enum ListType {
  SimpleTable = 'SIMPLE_TABLE',
  FromExistingData = 'FROM_EXISTING_DATA',
}

export interface UploadedEntry {
  value: string;
  displayValue: string;
  properties: DimensionProperty[];
}

export interface ModificationsFromData {
  rowsAdded: number;
  rowsUpdated: number;
  rowsDeleted: number;
}

export interface ListColumn {
  name: string;
  displayValue: string;
}

export enum ListSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum ListDataValidationCondition {
  ExistingColumn = 'EXISTING_COLUMN',
}

export interface ListDataValidationConfig {
  condition: ListDataValidationCondition;
  source: string;
}

export interface ListMaskedColumnConfig {
  allowedUsers: string[];
  allowedDimensions: string[];
}

export interface ListConfig {
  columnTypeMap: { [key: string]: QueryTableColumnDataType };
  dateFormatMap: { [key: string]: string };
  columnOrder: string[];
  frozenColumns?: string[];
  selectiveAccessColumns?: string[];
  editableColumns?: string[];
  backedByBQ?: boolean;
  uniqueKeyColumns?: string[];
  uniqueKey?: string;
  sortOrder?: ListSortOrder | null;
  sortedColumn?: string;
  columnFormulae?: { colName: string; formula: string; query?: string }[];
  dataValidation?: Record<string, ListDataValidationConfig>;
  maskedColumns?: Record<string, ListMaskedColumnConfig>;
}

export interface ReconciliationConfig {
  autoReconcile: boolean;
  showAutoReconciliations: boolean;
  enableMappingUi: boolean;
  changelogLastSyncedAt?: string;
  changelogLastSyncedColumn: string;
  tableName: string;
  schema: string;
  uniqueColumns: string[];
  reconEntityId: string[];
  entityColumnName: string;
  fieldsToUpdate: string[];
  essentialFields: string[];
  matchCandidatesCriteria?: MatchCandidatesCriteria;
  positionStartDateCol?: string;
  positionEndDateCol?: string;
  hideReject: boolean;
}

interface MatchCandidatesCriteria {
  column: string;
  value: string;
  displayColumn: string;
}

export interface ListRow {
  id: number;
  position: number;
  listId: number;
  data: RowStructure;
}

export interface List {
  id: number;
  name: string;
  displayName?: string;
  description: string;
  config: ListConfig;
  reconciliationConfig: ReconciliationConfig;
  rows: ListRow[];
  accessAction: AccessAction;
  accessControlled: boolean;
  reconciliationEnabled: boolean;
  isDerivedList?: boolean;
  errors: { msg: string }[];
  maskedColumns: string[];
}

export type ListDataTypes =
  | QueryTableColumnDataType.Numeric
  | QueryTableColumnDataType.String
  | QueryTableColumnDataType.Date;

export interface RowStructure {
  [key: string]: string | number;
}

export interface RowReorderRequest {
  targetRowId: ID | null;
  movedRows: ID[];
}

export enum ListUpdateType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
  Terminate = 'TERMINATE',
}

export interface ListUpdate {
  id: ID;
  entityColumnValue: string;
  cardType: ListUpdateType;
  data: Record<string, string>;
  prevData?: Record<string, string>;
  listRowId?: ID;
  status: EntityStatus;
  ids?: ID[];
}

export enum ListUpdateActionType {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
}

export interface ListUpdateRequest {
  action: ListUpdateActionType;
  listRowId?: ID;
  createNewRow?: boolean;
  ids?: ID[];
}

export interface ListReconSetupRequest {
  columns: { source: string; colName: string }[];
  uniqueKeyColumns: string[];
}
