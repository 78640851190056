import { type PickerProps } from 'antd/es/date-picker/generatePicker';
import { type Dayjs } from 'dayjs';
import styled, { createGlobalStyle } from 'styled-components';
import { DatePicker as DatePickerAtom } from '../date-picker';
import { type MonthRangePanelType } from './common/types';

type DatePicker = PickerProps<Dayjs> & {
  range?: MonthRangePanelType;
};

const Cell = styled.div.attrs({
  className: 'ant-picker-cell-inner',
})<{ $highlight?: boolean }>`
  background: ${({ $highlight, theme: { colors } }) => {
    return $highlight ? colors.blue10 : 'transparent';
  }};
`;

export const styles = {
  DatePicker: styled(DatePickerAtom).attrs<DatePicker>(({ range }) => ({
    allowClear: false,
    bordered: false,
    className: 'month-range-picker',
    popupClassName: 'month-range-picker-dropdown',
    format: 'MMM YYYY',
    inputReadOnly: true,
    picker: 'month',
    size: 'small',
    suffixIcon: <></>,
    monthCellRender: (date: Dayjs) => {
      const isDateInBetweenRanges = date.isAfter(range?.[0]) && date.isBefore(range?.[1]);

      return <Cell $highlight={isDateInBetweenRanges}>{date.format('MMM')}</Cell>;
    },
  }))<DatePicker>`
    &.ant-picker {
      border-bottom: solid 1px ${({ theme: { border } }) => border.color} !important;
      border-radius: 0;
      padding: ${({ theme: { spacing } }) => spacing[16]};
    }

    .ant-picker-active-bar {
      display: none;
    }

    .ant-picker-input input {
      border: solid 1px ${({ theme: { border } }) => border.color};
      font-size: ${({ theme: { fontSize } }) => fontSize.caption};
      height: ${({ theme: { height } }) => height.sm}px;
      padding: ${({ theme: { spacing } }) => `0 ${spacing[12]}`};
      width: 128px;

      &[readonly] {
        background: transparent;
      }
    }

    .ant-picker-range-separator {
      color: ${({ theme: { colors } }) => colors.black60};
      display: flex;
      height: ${({ theme: { height } }) => height.sm}px;
      padding: ${({ theme: { spacing } }) => `0 ${spacing[8]}`};
    }
  `,
  Wrapper: styled.div`
    margin-top: -2px;
    overflow: hidden;
    display: flex;
    height: 262px;

    .ant-picker {
      width: 100%;

      .ant-picker-suffix {
        display: none;
      }

      & + div {
        position: static !important;
      }
    }

    .ant-picker-dropdown {
      border-right: solid 1px ${({ theme: { border } }) => border.color};
      padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[16]}`};
    }
  `,

  MonthPanelWrapper: styled.div`
    flex: 1;
    width: 166px;

    &:last-child {
      .ant-picker-dropdown {
        border: none;
        margin-left: -4px;
      }
    }
  `,

  RightArrow: styled.div`
    && {
      border-bottom: solid 1px ${({ theme: { border } }) => border.color};
      margin-left: -8px;
      margin-right: -8px;
      display: inline-flex;
      align-items: center;
      height: 62px;
      width: 16px;
      color: ${({ theme: { colors } }) => colors.black60};
    }
  `,
};

export const MonthRangeStyles = createGlobalStyle`
  .month-range-picker-dropdown {
    &.ant-picker-dropdown-range {
      padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[0]}`};
    }

    .ant-picker-panel-container {
      box-shadow: none;
      transition: none;
      font-size: ${({ theme }) => theme.fontSize.caption};
    }

    .ant-picker-header,
    .ant-picker-panel {
      border-bottom: none;
    }

    .ant-picker-range-arrow {
      display: none;
    }

    .ant-picker-month-panel {
      width: 132px;

      .ant-picker-content {
        height: 144px;
      }

      .ant-picker-body {
        padding: 0;
      }
    }

    .ant-picker-panels .ant-picker-panel {
      padding: ${({ theme: { spacing } }) => `0 ${spacing[12]} 0 ${spacing[16]}`};
      margin-left: -1px;
    }

    .ant-picker-panels .ant-picker-panel:first-child {
      border-right: 1px solid ${({ theme: { border } }) => border.color};

    }

    .ant-picker-header {
      padding: 0;

      button {
        min-width: 32px;
        height: 32px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 32px;
      }

      .ant-picker-header-view button {
        pointer-events: none;
      }
    }

    .ant-picker-header-view {
      line-height: 32px;
    }

    .ant-picker-cell {
      padding: 0;

      ::before {
        width: 40px;
        border-radius: ${({ theme: { border } }) => border.radius.md};
      }

      ::before,
      ::after {
        height: ${({ theme }) => theme.height.md}px;
      }

      ::after {
        display: none;
      }

      .ant-picker-cell-inner {
        border-radius: ${({ theme: { border } }) => border.radius.md};
        height: 32px;
        width: 40px;
        padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[12]}`};
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: -4px;
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
        left: 0;
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
        border-radius: ${({ theme: { border } }) => `${border.radius.md} 0 0 ${border.radius.md}`};
      }
      
      &.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
        border-radius: ${({ theme: { border } }) => `0 ${border.radius.md} ${border.radius.md} 0`};
      }

      &.ant-picker-cell-range-hover::before,
      &.ant-picker-cell-range-hover-start::before,
      &.ant-picker-cell-range-hover-end::before {
        background: ${({ theme: { colors } }) => colors.blue20};
      }
    }

    .ant-picker-super-prev-icon::after,
    .ant-picker-super-next-icon::after {
      display: none;
    }
  }
`;
