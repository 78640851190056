import { Spinner } from 'components/ui/atomic-components';
import { Divider } from 'components/ui/atomic-components/divider';
import { SearchBox } from 'components/ui/atomic-components/searchbox';
import { type MetricApplicableDimension, TimeDimensionName } from 'data/dimension';
import { useTimeDimension } from 'data/dimension/hooks';
import { useGetMetricSpaces } from 'data/metrics/hooks';
import { useScenarioDimension } from 'data/scenarios/hooks/use-scenario-dimension';
import { isEmpty } from 'lodash';
import { type InputRef } from 'rc-input';
import { useRef, type ReactElement, useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DimensionOptions } from './dimension-options';
import { MenuItem } from './menu-item';
import { styles } from './styles';
import { TimeOption } from './time-option';

const { Wrapper, Title, SearchBoxWrapper, Content } = styles;

interface Props {
  metricName: string;
  selectionsMap: Record<string, string[]>;
  onClickDimension?: (dim: MetricApplicableDimension) => void;
}

export const DimensionSelector = ({
  metricName,
  selectionsMap,
  onClickDimension,
}: Props): ReactElement => {
  const intl = useIntl();
  const timeDimension = useTimeDimension();
  const scenarioDimension = useScenarioDimension();

  const { data: { dimensions = [], scenarios } = {}, isLoading } = useGetMetricSpaces(metricName);

  const searchRef = useRef<InputRef>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = useMemo(() => {
    return dimensions.filter((dim) =>
      dim.name.toLowerCase().includes(searchTerm.toLowerCase().trim()),
    );
  }, [dimensions, searchTerm]);

  const showTimeOption = timeDimension.displayName
    .toLowerCase()
    .includes(searchTerm.toLowerCase().trim());

  const selectedTimeValue = selectionsMap[TimeDimensionName]?.[0]?.replaceAll(
    'time.moduleActualsEndDate',
    intl.formatMessage({ id: 'formula.constructs.last_actuals_date' }),
  );

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="dimensions" />
      </Title>

      {!isEmpty(dimensions) && (
        <SearchBoxWrapper>
          <SearchBox ref={searchRef} onChange={(e) => setSearchTerm(e.target.value)} />
        </SearchBoxWrapper>
      )}

      {!isLoading ? (
        <Content>
          {showTimeOption && (
            <TimeOption selectedTimeValue={selectedTimeValue} onClick={onClickDimension} />
          )}

          {!isEmpty(scenarios) && (
            <MenuItem
              label={intl.formatMessage({ id: 'scenarios.name_in_singular' })}
              onClick={() => onClickDimension?.(scenarioDimension)}
            />
          )}

          {!isEmpty(filteredOptions) && <Divider $mb={2} $mt={2} />}

          <DimensionOptions
            options={filteredOptions}
            selectionsMap={selectionsMap}
            onClick={onClickDimension}
          />
        </Content>
      ) : (
        <Spinner $mb={8} size="medium" />
      )}
    </Wrapper>
  );
};
