import { type GridApi } from 'ag-grid-community';
import { ReactComponent as EyeBlockIcon } from 'assets/v2/eye-block.svg';
import { ReactComponent as KeyIcon } from 'assets/v2/key.svg';
import { ReactComponent as FormulaIcon } from 'assets/v2/math-function.svg';
import { ReactComponent as AscendingIcon } from 'assets/v2/sort-ascending.svg';
import { ReactComponent as DescendingIcon } from 'assets/v2/sort-descending.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type QueryTableColumnDataType } from 'data/big-query';
import { ListSortOrder } from 'data/modelling/lists';
import { isNil } from 'lodash';
import { useRef, type FC } from 'react';
import { useListStoreContext } from 'store/lists';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { ListsFilter } from './filter';
import { OverflowMenu } from './overflow-menu';
import styles from './styles';

const {
  Wrapper,
  TitleText,
  ColTitle,
  IconMapper,
  IconOptions,
  IconTextWrapper,
  MaskedColumnIcon,
  RightIconSection,
} = styles;

interface Props {
  colName: string;
  displayName: string;
  isUniqueColumn: boolean;
  isSubColumnOfUniqueCol: boolean;
  columnType: QueryTableColumnDataType;
  dateFormat?: string;
  api: GridApi;
  hideOptions?: boolean;
  formula: {
    formulaText: string | undefined;
    query: string | undefined;
  };
}

export const ColumnHeader: FC<React.PropsWithChildren<Props>> = ({
  colName,
  displayName,
  isUniqueColumn,
  isSubColumnOfUniqueCol,
  columnType,
  dateFormat,
  api,
  hideOptions,
  formula,
}) => {
  const config = useListStoreContext((s) => s.config);
  const isReadOnly = useListStoreContext((s) => s.isReadOnly);
  const accessAction = useListStoreContext((s) => s.accessAction);

  const showColumnOverflowMenu = hasListAccess(
    accessAction,
    isReadOnly,
    config.backedByBQ,
  ).columnOverflowMenu;

  const columnWrapperDomRef = useRef<HTMLDivElement>(null);

  const isMaskedColumn = Object.keys(config?.maskedColumns || {}).includes(colName);

  return (
    <Wrapper ref={columnWrapperDomRef}>
      <IconTextWrapper>
        <TitleText
          color="iconDefault"
          icon={isUniqueColumn ? KeyIcon : IconMapper?.[columnType] ?? FormulaIcon}
          size="md"
          text={<ColTitle title={displayName}>{displayName}</ColTitle>}
        />

        {config.sortedColumn === colName && !isNil(config.sortOrder) && (
          <IconShell
            color="iconDefault"
            icon={config.sortOrder === ListSortOrder.Asc ? AscendingIcon : DescendingIcon}
          />
        )}
      </IconTextWrapper>

      <RightIconSection>
        {isMaskedColumn && (
          <MaskedColumnIcon>
            <IconShell color="iconDefault" icon={EyeBlockIcon} />
          </MaskedColumnIcon>
        )}

        {!hideOptions && (
          <IconOptions>
            {!isMaskedColumn && <ListsFilter api={api} colName={colName} type={columnType} />}

            {showColumnOverflowMenu && (
              <OverflowMenu
                colName={colName}
                columnType={columnType}
                columnWrapperDomRef={columnWrapperDomRef}
                dateFormat={dateFormat}
                formula={formula}
                isSubColumnOfUniqueCol={isSubColumnOfUniqueCol}
                isUniqueColumn={isUniqueColumn}
              />
            )}
          </IconOptions>
        )}
      </RightIconSection>
    </Wrapper>
  );
};
