import { type QueryClient } from '@tanstack/react-query';
import { type ID } from 'data';
import { type List } from 'data/modelling/lists';

interface Props {
  queryClient: QueryClient;
  listId: ID;
  columnName: string;
}

export const removeColumnFromCache = ({ queryClient, listId, columnName }: Props): void => {
  queryClient.setQueryData(['lists'], (existingLists?: List[]) => {
    return existingLists?.map((list) => {
      if (list.id === listId) {
        return {
          ...list,
          config: {
            ...list.config,
            columnOrder: list.config.columnOrder.filter((col) => col !== columnName),
            frozenColumns: list.config.frozenColumns?.filter((col) => col !== columnName),
            columnFormulae: list.config.columnFormulae?.filter(
              (formula) => formula.colName !== columnName,
            ),
          },
        };
      }

      return list;
    });
  });
};
