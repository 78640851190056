import { MoreActionsDropdown } from 'components/ui/atomic-components';
import { ResourceUsageModal, useResourceUsageWidget } from 'components/ui/resource-usage-widget';
import { useState, type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { useListMenuOptions } from '../use-list-menu-options';
import { DeleteListsModal } from './delete-list-modal';
import { EditAccessControlModal } from './edit-access-control-modal';
import { RemoveAccessControlModal } from './remove-access-control-modal';
import { UniqueKeyModal } from './unique-key-modal';
import { UpdateFromDataModal } from './update-from-data-modal';

export const MoreActions = (): ReactElement => {
  const [uniqueKeyModalVisible, setUniqueKeyModalVisible] = useState(false);
  const [updateFromDataModalVisible, setUpdateFromDataModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editAccessModalVisible, setIsEditAccessModalVisible] = useState(false);
  const [removeAccessModalVisible, setIsRemoveAccessModalVisible] = useState(false);

  const listId = useListStoreContext((s) => s.id);
  const listName = useListStoreContext((s) => s.name);
  const listDisplayName = useListStoreContext((s) => s.displayName);
  const accessControlled = useListStoreContext((s) => s.accessControlled);

  const onClickAccessControl = () => {
    if (accessControlled) {
      setIsRemoveAccessModalVisible(true);
    } else {
      setIsEditAccessModalVisible(true);
    }
  };

  const [isMoreActionsMenuOpen, setIsMoreActionsMenuOpen] = useState(false);
  const { usageWidgetMenuItem, usageModalProps, isTryingToCloseWhileUsageFetch, closeUsageModal } =
    useResourceUsageWidget({
      name: listName,
      displayName: listDisplayName || '',
      type: 'LISTS',
      closeMenu: () => setIsMoreActionsMenuOpen(false),
    });

  const listMenuOptions = useListMenuOptions({
    usageWidgetMenuItem,
    accessControlled,
    onClickAccessControl,
    onClickUniqueKeyOption: () => setUniqueKeyModalVisible(true),
    onClickUpdateFromDataOption: () => setUpdateFromDataModalVisible(true),
    onClickDelete: () => setDeleteModalVisible(true),
  });

  return (
    <>
      <MoreActionsDropdown
        menu={{
          items: listMenuOptions,
        }}
        open={isMoreActionsMenuOpen}
        size="small"
        onOpenChange={(toOpen) => {
          queueMacroTask(() => {
            if (isTryingToCloseWhileUsageFetch(toOpen)) {
              return;
            }

            setIsMoreActionsMenuOpen(toOpen);
          });
        }}
      />

      <UniqueKeyModal open={uniqueKeyModalVisible} setOpen={setUniqueKeyModalVisible} />

      <UpdateFromDataModal
        open={updateFromDataModalVisible}
        setOpen={setUpdateFromDataModalVisible}
      />

      <DeleteListsModal
        listId={listId}
        listName={listDisplayName || ''}
        open={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
      />

      <EditAccessControlModal
        open={editAccessModalVisible}
        onClose={() => setIsEditAccessModalVisible(false)}
      />

      <RemoveAccessControlModal
        visible={removeAccessModalVisible}
        onCancel={() => setIsRemoveAccessModalVisible(false)}
      />

      <ResourceUsageModal usageProps={usageModalProps} onClose={closeUsageModal} />
    </>
  );
};
