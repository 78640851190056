import { currencyLocaleMap } from 'data/currencies';
import { DataFormattingType } from 'utils/data-formatter/types';
import { displayUnitFactorMap, displayUnitSymbolMap } from '../constants';
import { type FormatterProps } from '../types';

export const otherFormattersForMetricType: {
  [key in DataFormattingType | 'default']: (props: FormatterProps) => string;
} = {
  [DataFormattingType.Currency]: ({
    valAsInt,
    currency,
    displayUnit,
    defaultOptions,
  }: FormatterProps): string => {
    const currencyFormatter = Intl.NumberFormat(currencyLocaleMap[currency], {
      currency,
      style: 'currency',
      ...defaultOptions,
    });

    return `${currencyFormatter.format(valAsInt / displayUnitFactorMap[displayUnit])}${
      displayUnitSymbolMap[displayUnit]
    }`;
  },

  [DataFormattingType.Percentage]: ({
    valAsInt,
    currency,
    defaultOptions,
  }: FormatterProps): string => {
    const percentFormatter = Intl.NumberFormat(currencyLocaleMap[currency], {
      style: 'percent',
      ...defaultOptions,
    });

    return percentFormatter.format(valAsInt);
  },

  [DataFormattingType.Number]: (props: FormatterProps): string => {
    return otherFormattersForMetricType.default(props);
  },

  default: ({ valAsInt, currency, displayUnit, defaultOptions }: FormatterProps): string => {
    const defaultFormatter = Intl.NumberFormat(currencyLocaleMap[currency], {
      ...defaultOptions,
    });

    return `${defaultFormatter.format(valAsInt / displayUnitFactorMap[displayUnit])}${
      displayUnitSymbolMap[displayUnit]
    }`;
  },
};
