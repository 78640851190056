import { ReactComponent as TickIcon } from 'assets/v2/tick.svg';
import { type ReactElement, type ReactText } from 'react';
import { components, type GroupBase, type OptionProps } from 'react-select';
import styled from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';
import { IconShell } from '../icon-shell';

export type OptionBaseOld<
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  Props extends unknown = unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  Value extends any = any,
> = {
  isDisabled?: boolean;
  label?: ReactText | JSX.Element;
  value?: Value;
} & Props;

export type SelectOptionProps<T = OptionBaseOld, IsMulti extends boolean = boolean> = OptionProps<
  T,
  IsMulti,
  GroupBase<T>
> & { $isCustom?: boolean };

const Text = styled.span<{ isSelected: boolean }>`
  ${SingleLineEllipsis};

  display: inline-block;
  flex: 1;

  .dropdown-item-label-icon {
    display: ${({ isSelected }) => isSelected && 'block'};
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  white-space: initial;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
`;

const Title = styled.div`
  ${SingleLineEllipsis};
`;

const SelectedIcon = styled(TickIcon).attrs({
  className: 'dropdown-item-label-icon',
})`
  display: none;
`;

export const SelectOption = <T extends OptionBaseOld, IsMulti extends boolean>({
  $isCustom,
  ...props
}: SelectOptionProps<T, IsMulti>): ReactElement => {
  return (
    <components.Option {...props} className={$isCustom ? '' : 'select-option'}>
      <Text isSelected={props.isSelected}>
        <OptionWrapper>
          <Title>{props.children}</Title>
          <IconShell className="select-option-check-icon" color="primary500" icon={SelectedIcon} />
        </OptionWrapper>
      </Text>
    </components.Option>
  );
};
