import { type OktaAuthOptions } from '@okta/okta-auth-js';
import { type WidgetOptions } from '@okta/okta-signin-widget';
import { isEmpty } from 'lodash';
import { getAssetUrl } from 'utils';
import { FAREYE_TENANT_DOMAINS, XERO_ENABLED_TENANT_DOMAINS } from './constants';
import { customStorageManager } from './storage-manager';

const DOMAIN = 'https://auth.drivetrain.ai';
const CLIENT_ID = '0oa5uh834DbXET6IB5d6';

export const oktaClientConfig: OktaAuthOptions = {
  issuer: `${DOMAIN}/oauth2/default`,
  clientId: CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['email', 'offline_access', 'profile', 'openid'],
  pkce: true,
  services: {
    autoRenew: true,
  },
  tokenManager: {
    storage: customStorageManager,
  },
};

const generateIdps = (customOktaWidgetIdps?: { type: string; id: string; text?: string }[]) => {
  const idps = [];

  if (!isEmpty(customOktaWidgetIdps)) {
    return customOktaWidgetIdps;
  }

  if (FAREYE_TENANT_DOMAINS.includes(window.location.origin)) {
    idps.push({ type: 'ONELOGIN', id: '0oag30uy67ENzite05d7', text: 'SSO' });
  }

  if (XERO_ENABLED_TENANT_DOMAINS.includes(window.location.origin)) {
    idps.push({ type: 'XERO', id: '0oaibgr5swcPMsOwa5d7', text: 'SSO' });
  }

  idps.push(
    { type: 'GOOGLE', id: '0oawq8dldpvG9EejO5d6' },
    { type: 'MICROSOFT', id: '0oag0g2vroig2gHb05d7' },
  );

  return idps;
};

export const oktaSignInConfig = (
  customOktaWidgetIdps?: { type: string; id: string; text?: string }[],
): WidgetOptions => {
  return {
    baseUrl: DOMAIN,
    clientId: CLIENT_ID,
    logo: getAssetUrl('drivetrain-logo-text.svg'),
    idps: generateIdps(customOktaWidgetIdps),
    redirectUri: `${window.location.origin}/login/callback`,
    authParams: {
      issuer: `${DOMAIN}/oauth2/default`,
      scopes: ['openid', 'profile', 'email'],
    },
    helpLinks: {
      help: `${window.location.origin}/login/help`,
    },
    i18n: {
      en: {
        'primaryauth.username.placeholder': 'Email',
        'error.username.required': 'Please enter an email.',
      },
    },
    // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
  };
};
