import { ReactComponent as XIcon } from 'assets/v2/x.svg';
import { Button, IconShell, Tooltip } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';

const BorderLeft = styled.div`
  height: calc(100% + 12px);
  border-left: 1px solid ${({ theme }) => theme.colors.outlineDefault};
`;

export const CloseAction = (): ReactElement => {
  const setSelectedRowIds = useListStoreContext((s) => s.setSelectedRowIds);
  const setLastSelectedRow = useListStoreContext((s) => s.setLastSelectedRow);
  const gridApi = useListStoreContext((s) => s.gridApi);

  const clearSelection = () => {
    setSelectedRowIds([]);
    setLastSelectedRow();
    gridApi?.deselectAll();
  };

  return (
    <>
      <BorderLeft />

      <Tooltip title={<FormattedMessage id="planning.lists.float_actions.clear" />}>
        <Button
          icon={<IconShell color="iconDefault" icon={XIcon} />}
          size="extraSmall"
          type="text"
          onClick={clearSelection}
        />
      </Tooltip>
    </>
  );
};
