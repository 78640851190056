import { type ColDef } from 'ag-grid-community';
import { QueryTableColumnDataType } from 'data/big-query';
import { formattedDate } from 'data/date-format/utils/formatted-date';
import { type ListsStoreApi } from 'store/lists/store';
import { numberFormatter } from 'utils/data-formatter';
import {
  BlankValueType,
  DataFormattingType,
  DisplayUnitType,
  ZeroFormatType,
} from 'utils/data-formatter/types';
import { type StoreApi } from 'zustand';
import {
  NumberCellEditor,
  TextCellEditor,
  GridEditorDatePicker,
  DateCell,
} from '../../../cell-components';
import {
  dateGetter,
  dateSetter,
  numericGetter,
  numericSetter,
  stringGetter,
  stringSetter,
} from './list-cell-utils';
import { cellRendererSelectorFuncton } from './list-cell-utils/cell-renderer-selector';

export const listGridComponentsMap = ({
  key,
  store,
}: {
  key: string;
  store: StoreApi<ListsStoreApi>;
}): {
  [key: string]: ColDef;
} => ({
  [QueryTableColumnDataType.String]: {
    cellEditor: TextCellEditor,
    cellRendererSelector: cellRendererSelectorFuncton({ key, store }),
    valueGetter: (params) => stringGetter({ params, key }),
    valueSetter: (params) => stringSetter({ params, key }),
  },

  [QueryTableColumnDataType.Numeric]: {
    cellEditor: NumberCellEditor,
    valueGetter: ({ data }) => numericGetter(data, key),
    valueSetter: (params) => numericSetter({ params, key }),
    valueFormatter: ({ value }) =>
      numberFormatter({
        value,
        type: DataFormattingType.Number,
        displayUnit: DisplayUnitType.Full,
        digitsAfterDecimal: value % 1 !== 0 ? 2 : 0,
        zeroFormat: ZeroFormatType.Number,
        blankValueFormat: BlankValueType.Blank,
      }),
  },

  [QueryTableColumnDataType.Date]: {
    cellEditor: GridEditorDatePicker,
    cellRenderer: DateCell,
    valueGetter: ({ data }) => dateGetter(data, key),
    valueSetter: (params) => dateSetter({ params, key }),
    valueFormatter: ({ value, colDef }) => {
      const dateFormat = colDef?.headerComponentParams?.dateFormat;

      if (dateFormat && value) {
        return formattedDate(dateFormat, value);
      }

      return value;
    },
  },
});
