import { type UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components';
import { type List } from 'data/modelling/lists';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { DimensionGroupApi } from '../api';
import { type DimensionGroup } from '../types';
import { constructListObjFromDimGroup } from '../utils';

export const useDimensionGroupMutations = (): {
  addDimensionGroup: UseMutationResult<
    DimensionGroup,
    AxiosError,
    Partial<DimensionGroup>,
    unknown
  >;
  updateDimensionGroup: UseMutationResult<
    DimensionGroup,
    AxiosError,
    Partial<DimensionGroup>,
    unknown
  >;
  deleteDimensionGroup: UseMutationResult<
    {
      [key: string]: string;
    },
    AxiosError,
    DimensionGroup,
    unknown
  >;
} => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const addDimensionGroup = useMutation(
    (dimensionGroup: Partial<DimensionGroup>) => DimensionGroupApi.createRecord(dimensionGroup),
    {
      onSuccess: (dimGroup) => {
        if (dimGroup.uniquenessCheckFailed) {
          return;
        }

        queryClient.setQueryData(['lists'], (existingLists?: (List | DimensionGroup)[]) => [
          ...(existingLists || []),
          dimGroup,
        ]);

        queryClient.setQueryData(
          ['dimension-groups', dimGroup.id],
          constructListObjFromDimGroup(dimGroup),
        );

        notification.success({
          message: intl.formatMessage({
            id: 'lists.toasts.create.success',
          }),
        });

        navigate(`/lists/${DIM_GROUP_ID_PREFIX}${dimGroup.id}`);
      },
      onError: defaultApiErrorHandler,
    },
  );

  const updateDimensionGroup = useMutation(
    (dimensionGroup: Partial<DimensionGroup>) => DimensionGroupApi.updateRecord(dimensionGroup),
    {
      onSuccess: async (dimGroup: DimensionGroup) => {
        if (dimGroup.uniquenessCheckFailed) {
          return;
        }

        queryClient.setQueriesData(['dimension-groups', dimGroup.id], (prev?: List) => {
          const updatedList = constructListObjFromDimGroup(dimGroup);

          if (!dimGroup.rows && prev?.rows) {
            updatedList.rows = prev?.rows;
          }

          return updatedList;
        });

        queryClient.setQueryData<(List | DimensionGroup)[]>(['lists'], (old) => {
          return (old || []).map((dim: List | DimensionGroup) => {
            if (dim?.id === dimGroup.id && (dim as DimensionGroup)?.uniqueKeyColumns) {
              return dimGroup as DimensionGroup;
            }

            return dim;
          });
        });

        notification.success({
          message: intl.formatMessage({
            id: 'lists.toasts.update_success',
          }),
        });

        navigate(`/lists/${DIM_GROUP_ID_PREFIX}${dimGroup.id}`);
      },
      onError: defaultApiErrorHandler,
    },
  );

  const deleteDimensionGroup = useMutation(
    (dimensionGroup: DimensionGroup) => DimensionGroupApi.deleteRecord(dimensionGroup.id),
    {
      onSuccess: (_, dimensionGroup) => {
        queryClient.setQueryData(['lists'], (existingLists?: (List | DimensionGroup)[]) =>
          existingLists?.filter((g) => {
            if (g.id === dimensionGroup.id && (g as DimensionGroup)?.uniqueKeyColumns) {
              return false;
            }

            return true;
          }),
        );

        queryClient.removeQueries(['dimension-groups', dimensionGroup.id]);

        notification.success({
          message: intl.formatMessage({
            id: 'lists.toasts.delete_success',
          }),
        });

        navigate('/lists');
      },
      onError: defaultApiErrorHandler,
    },
  );

  return {
    addDimensionGroup,
    updateDimensionGroup,
    deleteDimensionGroup,
  };
};
