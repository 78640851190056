import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ListRow } from 'data/modelling/lists';
import { isNil, keyBy } from 'lodash';
import { useRef, useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { FontS } from 'styles/typography';
import { queueMacroTask } from 'utils/queue-macro-task';
import { useListUpdates } from '../hooks/use-list-updates';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonsSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const Title = styled.div`
  ${FontS};

  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

interface Props {
  onClose: () => void;
}

export const TitleSection = ({ onClose }: Props): ReactElement => {
  const { data: listUpdates = [] } = useListUpdates();

  const gridApi = useListStoreContext((s) => s.gridApi);
  const listRows = useListStoreContext((s) => s.rows);
  const reconConfig = useListStoreContext((s) => s.reconciliationConfig);
  const updateListRows = useListStoreContext((s) => s.updateRows);

  const [showingMappedRows, setShowingMappedRows] = useState(false);

  const unfilteredRowsRef = useRef<ListRow[]>(listRows);

  const handleShowMappedRows = () => {
    setShowingMappedRows(true);

    const listUpdateRowIds = new Set(listUpdates.map((listUpdate) => listUpdate.listRowId));

    const filteredRows = listRows.filter((row) => {
      return (
        listUpdateRowIds.has(row.id) &&
        reconConfig?.fieldsToUpdate?.some((col) => isNil(row.data[col]) || row.data[col] === '')
      );
    });

    updateListRows(filteredRows);
    queueMacroTask(() => gridApi?.refreshCells({ columns: ['row-index'] }));
  };

  const handleShowAllRows = () => {
    setShowingMappedRows(false);

    const allRows = Object.values(
      Object.assign(keyBy(unfilteredRowsRef.current, 'id'), keyBy(listRows, 'id')),
    );

    updateListRows(allRows);
    queueMacroTask(() => gridApi?.refreshCells({ columns: ['row-index'] }));

    unfilteredRowsRef.current = allRows;
  };
  const isMappingUiEnabled = reconConfig?.enableMappingUi;

  return (
    <Wrapper>
      <Title>
        {isMappingUiEnabled ? (
          <FormattedMessage id="lists.data_updates.unmapped.title" />
        ) : (
          <FormattedMessage id="lists.data_updates.title" />
        )}
      </Title>

      <ButtonsSection>
        {isMappingUiEnabled &&
          (showingMappedRows ? (
            <Button size="extraSmall" onClick={handleShowAllRows}>
              <FormattedMessage id="lists.data_updates.button_text.show_all_rows" />
            </Button>
          ) : (
            <Button size="extraSmall" onClick={handleShowMappedRows}>
              <FormattedMessage id="lists.data_updates.button_text.show_unmapped_rows" />
            </Button>
          ))}

        <Button
          icon={<IconShell color="iconDefault" icon={CloseIcon} />}
          size="extraSmall"
          type="text"
          onClick={onClose}
        />
      </ButtonsSection>
    </Wrapper>
  );
};
