import Bugsnag from '@bugsnag/js';
import { notification } from 'components/ui/atomic-components';
import { ModelVersionApi } from 'data/modelling/model/versions/api';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useVersionStore } from 'store/editable-version';
import {
  VERSION_MODE_COOKIE_KEY,
  VERSION_ACCESS_COOKIE_KEY,
  type VersionAccessModes,
} from '../constants';

export const useVersionMode = (): {
  isInModelPage: boolean;
  versionId?: string;
  accessMode?: VersionAccessModes;
  exitCurrentMode: (discard?: boolean) => Promise<void>;
  setVersionMode: (id: number, mode: VersionAccessModes) => Promise<void>;
  isLoading: boolean;
} => {
  const isLoading = useVersionStore((state) => state.isLoading);
  const setIsLoading = useVersionStore((state) => state.setIsLoading);

  const intl = useIntl();

  const location = useLocation();

  const isInModelPage = ['/models', '/lists'].some((path) => location.pathname.startsWith(path));

  const versionId = Cookies.get(VERSION_MODE_COOKIE_KEY);
  const accessMode = Cookies.get(VERSION_ACCESS_COOKIE_KEY) as VersionAccessModes;

  const setVersionMode = async (id: number, mode: VersionAccessModes) => {
    setIsLoading(true);
    Cookies.set(VERSION_MODE_COOKIE_KEY, `${id}`);
    Cookies.set(VERSION_ACCESS_COOKIE_KEY, mode);

    try {
      await ModelVersionApi.exitVersionMode({ discard: true });
      window.location.href = '/models';
    } catch {
      setIsLoading(false);
      Cookies.remove(VERSION_MODE_COOKIE_KEY);
      Cookies.remove(VERSION_ACCESS_COOKIE_KEY);
      notification.error({
        message: intl.formatMessage({
          id: 'model.versions.view.launching_version_error',
        }),
      });
    }
  };

  const exitCurrentMode = async (discard = true) => {
    try {
      await ModelVersionApi.exitVersionMode({ discard });
    } catch {
      Bugsnag.notify('Version exit failed');
    }

    Cookies.remove(VERSION_MODE_COOKIE_KEY);
    Cookies.remove(VERSION_ACCESS_COOKIE_KEY);
    if (isInModelPage) {
      window.location.href = '/models';
    } else {
      window.location.reload();
    }
  };

  return {
    isInModelPage,
    versionId,
    accessMode,
    setVersionMode,
    exitCurrentMode,
    isLoading,
  };
};
