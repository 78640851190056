import { AgGridReact, type AgGridReactProps } from 'ag-grid-react';
import styled from 'styled-components';
import { FontS } from 'styles/typography';
import { getAssetUrl } from 'utils';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export const gridIcons = {
  groupExpanded: `<img src="${getAssetUrl('v2/chevron-down.svg')}" />`,
  groupContracted: `<img src="${getAssetUrl('v2/chevron-right.svg')}" />`,
  columnDrag: `<img src="${getAssetUrl('v2/drag-grip-vertical')}" />`,
  rowDrag: `<img src="${getAssetUrl('v2/drag-grip-vertical.svg')}" />`,
  columnMoveMove: `<img src="${getAssetUrl('v2/drag-grip-vertical.svg')}" />`,
};

export const AgGridV2 = styled(AgGridReact).attrs<AgGridReactProps>((props) => ({
  className: 'ag-theme-alpine',

  animateRows: false,
  headerHeight: 32,
  rowHeight: props.rowHeight ?? 32,

  icons: gridIcons,

  allowContextMenuWithControlKey: true,
  suppressGroupRowsSticky: true,

  ...props,
}))`
  font-family: inherit;

  --ag-alpine-active-color: ${({ theme: { colors } }) => colors.primary500};
  --ag-background-color: ${({ theme: { colors } }) => colors.gray00};
  --ag-header-background-color: ${({ theme: { colors } }) => colors.gray00};
  --ag-subheader-background-color: ${({ theme: { colors } }) => colors.gray00};
  --ag-odd-row-background-color: ${({ theme: { colors } }) => colors.gray00};
  --ag-data-color: ${({ theme: { colors } }) => colors.textBody2};
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-color: ${({ theme: { colors } }) => colors.outlineDefault};
  --ag-cell-horizontal-padding: ${({ theme }) => theme.spacing[8]};
  --ag-header-foreground-color: ${({ theme }) => theme.colors.textBody1};
  --ag-foreground-color: ${({ theme }) => theme.colors.textBody1};
  --ag-secondary-foreground-color: ${({ theme }) => theme.colors.textBody2};
  --ag-control-panel-background-color: ${({ theme: { colors } }) => colors.gray00};
  --ag-border-color: ${({ theme: { colors } }) => colors.gray200};
  --ag-range-selection-background-color: ${({ theme: { colors } }) => colors.gray00};
  --ag-range-selection-border-color: ${({ theme: { colors } }) => colors.primary500};
  --ag-border-radius: ${({ theme: { borderRadius } }) => borderRadius.xs};
  --ag-range-selection-highlight-color: ${({ theme: { colors } }) => colors.bgLight};
  --ag-cell-widget-spacing: ${({ theme: { spacing } }) => spacing[8]};
  --ag-cell-horizontal-border: 1px solid ${({ theme: { colors } }) => colors.gray200};
  --ag-row-hover-color: ${({ theme: { colors } }) => `${colors.bgLight}`};
  --ag-header-column-resize-handle-height: calc(100% - 8px);
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-color: ${({ theme }) => theme.colors.primary500};

  .ag-overlay {
    z-index: ${({ theme }) => theme.zIndex[10]};
  }

  .ag-root-wrapper {
    border: none;
    border-radius: 0;
    font-feature-settings: 'tnum' 1;
  }

  .ag-cell {
    .ag-fill-handle {
      cursor: crosshair;
    }

    &.ag-selection-fill-top,
    &.ag-selection-fill-bottom,
    &.ag-selection-fill-right,
    &.ag-selection-fill-left {
      cursor: crosshair;
    }

    &.ag-cell-inline-editing {
      border-radius: 0;
      box-shadow: none;
    }
  }

  .ag-group-value,
  .ag-cell-value {
    width: 100%;
    height: 100%;
  }

  .ag-row-group-expanded {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .ag-header-container {
    white-space: normal;
  }

  .ag-header {
    border-bottom: 0;
  }

  .ag-header-cell-resize {
    ::after {
      visibility: hidden;
    }

    :hover {
      ::after {
        visibility: visible;
      }
    }
  }

  .ag-header-row {
    background: ${({ theme }) => theme.colors.bgMedium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.textBody2};
  }

  [col-id='rowNum'] {
    color: ${({ theme }) => theme.colors.gray600};
  }

  .ag-header-group-cell {
    border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  }

  && .ag-header-cell,
  .ag-header-group-cell {
    --ag-cell-horizontal-padding: ${({ theme }) => theme.spacing[12]};

    &.ag-header-group-cell-with-group {
      --ag-cell-horizontal-padding: 0;
    }
  }

  .ag-header-row:not(:first-child) {
    .ag-header-group-cell.ag-header-group-cell-with-group,
    .ag-header-cell.ag-right-aligned-header {
      border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    }
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container {
    box-shadow: var(--ag-left-pinned-shadow, 'none');
  }

  .ag-pinned-left-header,
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell):not(
      .ag-cell-focus
    ),
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
    border-right-color: ${({ theme }) => theme.colors.outlineDefault};
  }

  .ag-grid-editable {
    cursor: cell;
  }

  .ag-row-hover {
    .drilldown-icon-wrapper {
      display: flex;
    }
  }

  .ag-popup {
    z-index: ${({ theme }) => theme.zIndex[9999]};
  }

  /** Context menu UI */
  & .ag-menu {
    background: ${({ theme }) => theme.colors.gray00};
    width: 240px;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    box-shadow: ${({ theme }) => theme.shadow.softMicro};

    .ag-menu-list {
      padding: ${({ theme: { spacing } }) => spacing[4]} 0;
      gap: ${({ theme: { spacing } }) => spacing[4]};
      display: flex;
      flex-direction: column;

      .ag-menu-separator {
        display: table;

        .ag-menu-separator-part::after {
          border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
        }
      }

      .ag-menu-option {
        ${FontS}

        cursor: pointer;
        font-family: ${({ theme }) => theme.font.text};
        gap: ${({ theme }) => theme.spacing[8]};
        display: flex;
        padding: ${({ theme }) => theme.spacing[6]};
        height: 28px;

        &.ag-menu-option-disabled {
          cursor: default;
        }

        .ag-menu-option-text,
        .ag-compact-menu-option-text {
          padding: 0 0 0 ${({ theme }) => theme.spacing[4]};
        }

        .ag-menu-option-part.ag-menu-option-icon {
          display: block;
          color: ${({ theme }) => theme.colors.iconDefault};
          padding: 0;
        }

        .ag-menu-option-part.ag-menu-option-shortcut,
        .ag-menu-option-part.ag-menu-option-popup-pointer {
          padding: 0;
        }
      }
    }
  }
` as unknown as typeof AgGridReact;
