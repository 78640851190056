import { type Dimension } from 'data/dimension';
import { useIntl } from 'react-intl';
import { SCENARIO } from '../constants';

export const useScenarioDimension = (): Dimension => {
  const intl = useIntl();

  return {
    name: SCENARIO,
    displayName: intl.formatMessage({ id: 'scenarios.name_in_singular' }),
    allowed: true,
  } as Dimension;
};
