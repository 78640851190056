import { DefaultColoringOption } from 'components/modules/modelling/_commons/dimension-chip-coloring/utils';
import { type OptionBaseOld } from 'components/ui/atomic-components/select';
import { AsyncSelect } from 'components/ui/atomic-components/select/async';
import { KEYBOARD_DEBOUNCE_INTERVAL } from 'config/constants';
import { SearchApi } from 'data/search';
import debounce from 'debounce-promise';
import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type ActionMeta, type GroupBase, type MultiValue } from 'react-select';
import { type AsyncProps } from 'react-select/async';
import { defaultTheme } from 'styles/theme';
import { MultiLabelComponent } from './multi-label-component';
import { OptionComponent } from './option-component';
import { SingleValueComponent } from './single-value-component';
import { createDimensionOptions, dimensionSearchRequest } from './utils';

export interface DimensionSelectOption extends OptionBaseOld {
  parentSourceDisplayName?: string;
  columnType?: string;
  name?: string;
  tableDisplayName?: string;
  formula?: string | null;
  title?: string;
  options?: DimensionSelectOption[];
}

interface Props
  extends Partial<AsyncProps<DimensionSelectOption, true, GroupBase<DimensionSelectOption>>> {
  value?: DimensionSelectOption[];
  searchAllTables?: boolean;
  groupRelatedResults?: boolean;
  preventDimensionGroupSearch?: boolean;
  size?: 'large' | 'small' | 'medium';
  onChange?: (
    newValue: MultiValue<DimensionSelectOption>,
    actionMeta: ActionMeta<DimensionSelectOption>,
  ) => void;
  chipColoringScheme?: (dimName: string) => {
    background: string;
    border: string;
  };
}

export const AsyncDimensionsSelect = ({
  value,
  onChange,
  searchAllTables,
  groupRelatedResults,
  preventDimensionGroupSearch,
  chipColoringScheme,
  isMulti,
  size = 'large',
  ...rest
}: Props): ReactElement => {
  const intl = useIntl();

  const val = isMulti ? value : [value as DimensionSelectOption];

  const promiseOptions = (inputValue: string) => {
    return SearchApi.advancedSearch(
      dimensionSearchRequest({
        value: searchAllTables ? [] : val,
        query: inputValue,
        preventDimensionGroupSearch,
      }),
    ).then((items) => createDimensionOptions({ value: val, items, groupRelatedResults }));
  };
  const loadOptions = debounce(promiseOptions, KEYBOARD_DEBOUNCE_INTERVAL, { leading: true });

  return (
    <AsyncSelect<DimensionSelectOption, true>
      classNamePrefix={'column-select'}
      components={{
        Option: OptionComponent,
        MultiValueLabel: MultiLabelComponent,
        SingleValue: SingleValueComponent,
      }}
      isClearable={false}
      isMulti={isMulti}
      isSearchable
      loadOptions={loadOptions}
      menuPortalTarget={document.body}
      noOptionsMessage={() => intl.formatMessage({ id: 'module.label.no_options' })}
      placeholder={<FormattedMessage id="module.label.dimension.placeholder" />}
      size={size}
      styles={{
        multiValue: (base, props) => {
          const { background, border } =
            chipColoringScheme?.(props.data.value) || DefaultColoringOption;

          return {
            ...base,
            backgroundColor: background,
            border: `1px solid ${border}`,
            borderRadius: defaultTheme.borderRadius.xs,
          };
        },
      }}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};
