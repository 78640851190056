import { type FC, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button, type ButtonProps } from '../button';

const ButtonSectionWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
`;

export const ModalFooter: FC<
  React.PropsWithChildren<{
    cancelButtonProps?: ButtonProps;
    cancelText?: ReactNode;
    confirmLoading?: boolean;
    okButtonProps?: ButtonProps;
    okText?: ReactNode;
    okDisabled?: boolean;
    footerExtra?: ReactNode;
    hideCancelButton?: boolean;
    onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onOk?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }>
> = ({
  cancelText,
  cancelButtonProps,
  confirmLoading,
  okButtonProps,
  okText,
  okDisabled,
  footerExtra,
  hideCancelButton,
  onCancel,
  onOk,
}) => {
  return (
    <>
      {footerExtra}
      <ButtonSectionWrapper>
        {onCancel && !hideCancelButton && (
          <Button
            disabled={confirmLoading}
            size="small"
            onClick={(e) => onCancel(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)}
            {...cancelButtonProps}
          >
            {cancelText || <FormattedMessage id="cancel" />}
          </Button>
        )}
        {onOk && (
          <Button
            disabled={okDisabled}
            loading={confirmLoading}
            size="small"
            type="primary"
            onClick={(e) => onOk(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)}
            {...okButtonProps}
          >
            {okText || <FormattedMessage id="ok" />}
          </Button>
        )}
      </ButtonSectionWrapper>
    </>
  );
};
