/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { isEmpty } from 'lodash';

export const SystemApi = {
  async postAction({
    classification,
    operation,
    queryParams,
  }: {
    classification: string;
    operation: string;
    queryParams?: Record<string, string>;
  }): Promise<any> {
    const queryArray: string[] = [];

    if (queryParams) {
      Object.keys(queryParams).forEach((k) => {
        queryArray.push(`${k}=${queryParams[k]}`);
      });
    }

    const { data } = await axios.post<any>(
      isEmpty(queryArray)
        ? `/v1/system/${classification}/${operation}`
        : `/v1/system/${classification}/${operation}?${queryArray.join('&')}`,
    );

    return data;
  },
};
