type DimensionList = {
  name: string;
  displayName: string;
  properties?: DimensionList;
  children?: DimensionList;
}[];

export const formatName = (name?: string): string => {
  if (!name) {
    return name || '';
  }

  return name?.replaceAll('_', ' ');
};

export const formatDimNames = (dims?: DimensionList): void => {
  if (!dims) {
    return;
  }
  dims?.forEach((dim) => {
    dim.displayName = formatName(dim.name);

    dim?.properties?.forEach((col) => {
      col.displayName = formatName(col.name);
    });

    formatDimNames(dim?.children);
  });
};
