import { type EditorView } from '@codemirror/view';
import { PeriodOptions } from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { type ItemType } from 'components/ui/atomic-components/menu';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { generateCustomOption } from './custom-option/generate-custom-option';
import { MenuLabel } from './menu-label';
import { type PeriodOptionType } from './types';

const getOptions = (
  options: PeriodOptionType[],
  selectedValue?: string,
  onItemClick?: (option: PeriodOptionType) => void,
): ItemType[] => {
  const periodValue = selectedValue?.split('=')[1];

  return options.map((option) => ({
    key: option?.value || option.id,
    label: (
      <MenuLabel
        isSelected={option.value === periodValue}
        option={option}
        selectedValue={selectedValue}
      />
    ),
    children:
      option.children && !!option.children?.length
        ? getOptions(option.children, selectedValue, onItemClick)
        : undefined,

    onClick: () => onItemClick?.(option),
  }));
};

interface Props {
  selectedValue?: string;
  isCustomOptionSelected?: boolean;
  onItemClick?: (option: PeriodOptionType) => void;
  onClickCustomOption?: () => void;
  onCustomOptionEnter?: (value: string) => void;
  setEditorView?: (editorView: EditorView | null) => void;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
}

export const constructPeriodOptions = ({
  selectedValue,
  isCustomOptionSelected,
  onItemClick,
  onClickCustomOption,
  onCustomOptionEnter,
  setEditorView,
  customTimeAutoCompletionOptions,
}: Props): ItemType[] => {
  const periodOptions = getOptions(PeriodOptions, selectedValue, onItemClick);
  const customOption = generateCustomOption({
    isSelected: isCustomOptionSelected,
    selectedValue,
    onSelect: onClickCustomOption,
    onPressEnter: onCustomOptionEnter,
    customTimeAutoCompletionOptions,
    setEditorView,
  });

  return [...periodOptions, { key: 'divider', type: 'divider' }, customOption];
};
