import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { constructSubscript } from '../utils';
import { DimensionSubscriptTag } from './dimension-subscript-tag';
import { TimeSubscriptTag } from './time-subscript-tag';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[2]};
`;

interface Props {
  metricName: string;
  dimensionsSubscript: Record<string, string[]>;
  timeSubscript: string | null;
  updateEditorView: (val: string) => void;
  readOnly?: boolean;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
}

export const SubscriptTags = ({
  metricName,
  dimensionsSubscript,
  timeSubscript,
  updateEditorView,
  readOnly,
  customTimeAutoCompletionOptions,
}: Props): ReactElement => {
  const subscriptList = Object.entries(dimensionsSubscript);

  const handleApply = (dimensionName: string, dimensionValues: string[]) => {
    const updatedDimensionValueSubscript = {
      ...dimensionsSubscript,
      [dimensionName]: dimensionValues,
    };

    const updatedSubcripts = constructSubscript(updatedDimensionValueSubscript, timeSubscript);

    updateEditorView(updatedSubcripts);
  };

  const handleTimeSelect = (timeValueSubscript: string) => {
    const updatedSubcripts = constructSubscript(dimensionsSubscript, timeValueSubscript);

    updateEditorView(updatedSubcripts);
  };

  return (
    <Wrapper>
      {subscriptList.map(([dimensionName, dimensionValues]) => {
        return (
          <DimensionSubscriptTag
            key={dimensionName}
            dimensionName={dimensionName}
            dimensionValues={dimensionValues}
            metricName={metricName}
            readOnly={readOnly}
            onApply={handleApply}
          />
        );
      })}

      {timeSubscript && (
        <TimeSubscriptTag
          customTimeAutoCompletionOptions={customTimeAutoCompletionOptions}
          readOnly={readOnly}
          timeValueSubscript={timeSubscript}
          onSelect={handleTimeSelect}
        />
      )}
    </Wrapper>
  );
};
