import { Alert as AntdAlert, type AlertProps } from 'antd';
import { ReactComponent as WarningIcon } from 'assets/v2/alert-triangle.svg';
import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { ReactComponent as ErrorIcon } from 'assets/v2/exclamation-circle.svg';
import { ReactComponent as InfoIcon } from 'assets/v2/info-circle.svg';
import { type FC } from 'react';
import styled from 'styled-components';
import { StyledMargins, type StyledMarginsProps } from 'styles/styled-atoms';
import { FontXs, FontXxs } from 'styles/typography';
import { IconShell } from '../icon-shell';
import { BgColor, BorderColor } from './constants';

const StyledAlert = styled(AntdAlert).attrs<AlertProps>(({ type }) => type)`
  ${StyledMargins};

  background: ${({ type }) => (type ? BgColor[type] : 'inherit')};
  border: 1px solid ${({ type }) => (type ? BorderColor[type] : 'inherit')};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  padding: ${({ theme }) => theme.spacing[8]};

  .ant-alert-message {
    ${FontXs};

    color: ${({ theme }) => theme.colors.textBody2};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .ant-alert-description {
    ${FontXxs};

    color: ${({ theme }) => theme.colors.textBody2};
  }

  &.ant-alert-error .ant-alert-icon {
    color: ${({ theme }) => theme.colors.red600};
  }
`;

export const Alert: FC<React.PropsWithChildren<AlertProps & StyledMarginsProps>> = ({
  type,
  ...rest
}) => {
  if (type) {
    switch (type) {
      case 'success':
        return (
          <StyledAlert
            closeIcon={<IconShell color="gray600" icon={CloseIcon} />}
            type="success"
            {...rest}
          />
        );
      case 'info':
        return (
          <StyledAlert
            closeIcon={<IconShell color="gray600" icon={CloseIcon} />}
            icon={<IconShell color="gray600" icon={InfoIcon} />}
            showIcon
            type="info"
            {...rest}
          />
        );
      case 'warning':
        return (
          <StyledAlert
            closeIcon={<IconShell color="gray600" icon={CloseIcon} />}
            icon={<IconShell color="yellow600" icon={WarningIcon} />}
            showIcon
            type="warning"
            {...rest}
          />
        );
      case 'error':
        return (
          <StyledAlert
            closeIcon={<IconShell color="gray600" icon={CloseIcon} />}
            icon={<IconShell color="red600" icon={ErrorIcon} />}
            showIcon
            type="error"
            {...rest}
          />
        );
    }
  }

  return <StyledAlert closeIcon={<IconShell color="gray600" icon={CloseIcon} />} {...rest} />;
};
