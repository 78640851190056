import { type ListRow } from 'data/modelling/lists';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useListStoreContext } from 'store/lists';
import { checkIfListRowMatchesFilter } from '../../../../hooks/use-grid-row-data/utils';

export type ListFilterOption = {
  id: string;
  key: string;
  title: string;
  value: string;
};

const FilterBlankLabel = '(Blank)';

const generateOptions = ({
  listRows,
  colName,
  columnFilters,
  columnNamesSet,
  checkedValues,
}: {
  listRows: ListRow[];
  colName: string;
  checkedValues?: Set<string>;
  columnFilters: Record<string, Set<string>>;
  columnNamesSet: Set<string>;
}): { allUniqueOptions: ListFilterOption[]; allUniqueValuesMap: Set<string | number> } => {
  const allUniqueValuesMap = new Set<string | number>();
  const checkedResults: ListFilterOption[] = [];
  const unCheckedResults: ListFilterOption[] = [];

  const columnFiltersClone = { ...columnFilters };

  // don't consider current column filter while showing value options
  delete columnFiltersClone[colName];

  listRows.forEach((row) => {
    if (
      checkIfListRowMatchesFilter({
        row,
        columnFilters: columnFiltersClone,
        columnNamesSet,
      })
    ) {
      const value = row.data[colName] || '';

      if (!allUniqueValuesMap.has(value)) {
        const valueString = String(value ?? '');

        if (checkedValues?.has(valueString)) {
          checkedResults.push({
            id: valueString,
            key: valueString,
            title: valueString || FilterBlankLabel,
            value: valueString,
          });
        } else {
          unCheckedResults.push({
            id: valueString,
            key: valueString,
            title: valueString || FilterBlankLabel,
            value: valueString,
          });
        }
        allUniqueValuesMap.add(value);
      }
    }
  });

  return {
    allUniqueOptions: [...sortBy(checkedResults, 'title'), ...sortBy(unCheckedResults, 'title')],
    allUniqueValuesMap,
  };
};

interface Props {
  colName: string;
}

export const useGetColAllUniqueValues = ({
  colName,
}: Props): { allUniqueOptions: ListFilterOption[]; allUniqueValuesMap: Set<string | number> } => {
  const rows = useListStoreContext((s) => s.rows);
  const columnFilters = useListStoreContext((s) => s.columnFilters);
  const config = useListStoreContext((s) => s.config);

  return useMemo(() => {
    return generateOptions({
      listRows: rows,
      colName,
      checkedValues: columnFilters?.[colName],
      columnNamesSet: new Set(config.columnOrder || []),
      columnFilters: columnFilters || {},
    });
  }, [colName, columnFilters, config.columnOrder, rows]);
};
