import axios from 'axios';
import { type ID } from 'data';
import { type DimensionGroup } from './types';

const API_PATH = '/v1/dimension-groups';

export const DimensionGroupApi = {
  async findAll(): Promise<DimensionGroup[]> {
    const { data } = await axios.get(API_PATH);

    return data;
  },

  async createRecord(dimensionGroup: Partial<DimensionGroup>): Promise<DimensionGroup> {
    const { data } = await axios.post(API_PATH, dimensionGroup);

    return data;
  },

  async findRecord(dimensionGroupId: ID): Promise<DimensionGroup> {
    const { data } = await axios.get(`${API_PATH}/${dimensionGroupId}`);

    return data;
  },

  async updateRecord(dimensionGroup: Partial<DimensionGroup>): Promise<DimensionGroup> {
    const { id, ...rest } = dimensionGroup;
    const { data } = await axios.patch(`${API_PATH}/${id}`, rest);

    return data;
  },

  async deleteRecord(dimensionGroupId: ID): Promise<{ [key: string]: string }> {
    return axios.delete(`${API_PATH}/${dimensionGroupId}`);
  },
};
