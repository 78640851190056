import { FormulaBarTypes } from 'components/ui/codemirror-v2/formula-bar/constants';
import styled, {
  type DefaultTheme,
  css,
  type ThemeProps,
  type FlattenInterpolation,
} from 'styled-components';
import { FontS, FontXs, FontXxs, SingleLineEllipsis } from 'styles/typography';
import { getAssetUrl } from 'utils';
import { DataFormattingType } from 'utils/data-formatter/types';

const ButtonPanel = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing[8]};
  right: ${({ theme }) => theme.spacing[8]};
  opacity: 0;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: ${({ theme }) => theme.spacing[6]};
`;

const Container = styled.div`
  position: relative;

  &:hover ${ButtonPanel} {
    opacity: 1;
  }
`;

const GrayOut = css`
  ${({ theme, grayOut }: { theme: DefaultTheme; grayOut?: boolean }) => {
    if (grayOut) {
      return css`
        color: ${theme.colors.gray400};

        span {
          color: ${theme.colors.gray400};
        }

        span[contenteditable='false'] {
          color: ${theme.colors.gray400};

          div {
            background-color: ${theme.colors.gray100};
            color: ${theme.colors.gray400};

            svg {
              color: ${theme.colors.gray400};
            }
          }
        }
      `;
    }
  }}
`;

const FormulaTooltip = css`
  .formula-tooltip-wrapper {
    display: flex;
    flex-direction: column;
    width: 264px;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    border: 1px solid ${({ theme }) => theme.colors.magenta200};
    background: ${({ theme }) => theme.colors.gray00};
    box-shadow: ${({ theme }) => theme.shadow.softMini};
    overflow: hidden;
  }
`;

const CustomCompletionOptionIcons = css`
  .cm-completionIcon-${FormulaBarTypes.MetricExpression}-${DataFormattingType.Currency} {
    background-image: url(${getAssetUrl('v2/dollar.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.MetricExpression}-${DataFormattingType.Percentage} {
    background-image: url(${getAssetUrl('v2/percentage.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.MetricExpression}-${DataFormattingType.Number} {
    background-image: url(${getAssetUrl('v2/hash.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.DatasetExpression} {
    background-image: url(${getAssetUrl('v2/database.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.DatasetColumnExpression} {
    background-image: url(${getAssetUrl('v2/database.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.DimensionExpression} {
    background-image: url(${getAssetUrl('v2/cube.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.ListColumnExpression} {
    background-image: url(${getAssetUrl('v2/menu-2.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.Math} {
    background-image: url(${getAssetUrl('v2/math-function.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.Text} {
    background-image: url(${getAssetUrl('v2/calendar-event.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.Period} {
    background-image: url(${getAssetUrl('v2/calendar-event.svg')});
  }

  .cm-completionIcon-${FormulaBarTypes.LastActualsDate} {
    background-image: url(${getAssetUrl('v2/calendar-event.svg')});
  }
`;

export const autoCompletionTooltipStyles = (
  title?: string,
): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
  .cm-tooltip.cm-tooltip-autocomplete {
    ::before {
      content: '${title}';
      ${FontXxs};

      padding: ${({ theme }) => theme.spacing[4]} 0;
      color: ${({ theme }) => theme.colors.textLabel};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }

    position: static !important;
    top: 0 !important;
    left: 0 !important;
    border: none;
    margin-top: ${({ theme }) => theme.spacing[8]};
    background-color: white;

    ul {
      font-family: ${({ theme }) => theme.font.text};
      margin-top: ${({ theme }) => theme.spacing[8]};
      max-height: 224px;
      max-width: none;

      li {
        display: flex;
        align-items: center;
        padding: ${({ theme }) => theme.spacing[4]};
        margin-bottom: ${({ theme }) => theme.spacing[4]};

        .cm-completionLabel {
          ${FontS};

          flex: 1;
          color: ${({ theme }) => theme.colors.textTitle1};

          .cm-completionMatchedText {
            background-color: ${({ theme }) => theme.colors.yellow400};
            text-decoration: none;
          }
        }

        .cm-completionDetail {
          ${SingleLineEllipsis};
          ${FontXs};

          color: ${({ theme }) => theme.colors.gray400};
          align-self: flex-end;
          font-style: normal;
          margin-bottom: ${({ theme }) => theme.spacing[2]};
        }

        .cm-completionIcon {
          margin-right: ${({ theme }) => theme.spacing[8]};
          background-size: cover;
          height: 16px;
        }

        ${CustomCompletionOptionIcons};
      }

      li.autocomplete-option-${FormulaBarTypes.Math} {
        .cm-completionLabel {
          color: ${({ theme }) => theme.colors.magenta500};
          text-transform: uppercase;
        }
      }

      li[aria-selected='true'] {
        background-color: ${({ theme }) => theme.colors.primary50};
        border-radius: ${({ theme }) => theme.borderRadius.xs};
      }
    }
  }
`;

export default {
  Container,
  StyledEditor: styled.div<{ grayOut?: boolean; isFormulaBeautified?: boolean }>`
    .cm-editor.cm-focused {
      outline: 1px solid ${({ theme }) => theme.colors.primary400};
      border-radius: ${({ theme }) => theme.borderRadius.xs};
    }

    .cm-content {
      max-width: 100%;
      padding: ${({ theme, isFormulaBeautified }) => {
        return isFormulaBeautified ? `${theme.spacing[8]} 0` : theme.spacing[0];
      }};
    }

    .cm-line {
      ${FontXs};

      white-space: break-spaces;
      overflow-wrap: break-word;
      color: ${({ theme }) => theme.colors.textBody2};
      padding: ${({ theme, isFormulaBeautified }) => {
        return isFormulaBeautified ? theme.spacing[2] : theme.spacing[8];
      }};

      ${GrayOut};

      .cm-widgetBuffer {
        display: none;
      }

      .cm-placeholder {
        margin-top: ${({ theme }) => theme.spacing[2]};
      }

      .cm-matchingBracket {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.colors.yellow300};
        color: ${({ theme }) => theme.colors.yellow700};
        width: 7px;
        height: 18px;
      }

      .cm-nonmatchingBracket {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.colors.red100};
        border: 1px solid ${({ theme }) => theme.colors.red200};
        color: ${({ theme }) => theme.colors.red500};
        width: 7px;
        height: 18px;
      }
    }

    .cm-activeLine {
      background-color: ${({ theme }) => theme.colors.bgMedium};
    }

    .cm-indent-markers::before {
      z-index: 1;
    }

    .cm-gutters {
      border-right: none;

      .cm-lineNumbers {
        background-color: ${({ theme }) => theme.colors.gray00};
        width: 40px;

        .cm-gutterElement {
          ${FontXs};

          display: flex;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme.colors.textPlaceholder};
          font-family: 'IBM Plex Mono', monospace;
        }

        .cm-activeLineGutter {
          background-color: ${({ theme }) => theme.colors.bgMedium};
          color: ${({ theme }) => theme.colors.textTitle1};
        }
      }
    }
  `,

  TooltipWrapper: styled.div`
    ${FormulaTooltip};

    ${autoCompletionTooltipStyles('Insert variables, functions, and more')};
  `,

  ButtonPanel,
};
