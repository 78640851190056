import { type MonitoringMetric } from 'data/metric-builder';
import { useMonitoringMetrics } from 'data/metric-builder/hooks/use-get-monitoring-metrics';
import { type PlanMetric } from 'data/modelling/metric';
import { useGetPlanMetrics } from 'data/modelling/model/use-get-plan-metrics';

export const useFormulaEditorMetrics = (
  domain: 'planning' | 'monitoring',
): { data: PlanMetric[] | MonitoringMetric[]; isLoading: boolean } => {
  const { metrics: planMetrics = [], isLoading: isLoadingPlanMetrics } = useGetPlanMetrics({
    enabled: domain === 'planning',
  });

  const { data: monitoringMetrics = [], isLoading: isLoadingMonitoringMetrics } =
    useMonitoringMetrics({
      options: { enabled: domain === 'monitoring' },
    });

  if (domain === 'monitoring') {
    return { data: monitoringMetrics, isLoading: isLoadingMonitoringMetrics };
  }

  return { data: planMetrics, isLoading: isLoadingPlanMetrics };
};
