import { css } from 'styled-components';

export const DatePickerStyle = css`
  .ant-picker {
    border-radius: ${({ theme }) => theme.borderRadius.s};
    line-height: ${({ theme }) => theme.lineHeight.xs};
    width: 100%;

    .ant-picker-clear:hover {
      color: ${({ theme }) => theme.colors.iconNegative};
    }
  }

  .ant-picker-focused {
    border-color: ${({ theme }) => theme.colors.primary500};
    box-shadow: none;
  }

  .ant-picker-panel {
    .ant-picker-header-super-prev-btn {
      display: flex;
      align-items: center;
      position: relative;
    }

    .ant-picker-header-super-next-btn {
      display: flex;
      align-items: center;
      position: relative;
    }

    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn {
      display: flex;
      align-items: center;
    }

    .ant-picker-cell {
      ::after {
        display: none;
      }

      &.ant-picker-cell-in-range::before {
        background: ${({ theme }) => theme.colors.primary100};
      }

      :not(
          .ant-picker-cell-in-range,
          .ant-picker-cell-selected,
          .ant-picker-cell-range-start,
          .ant-picker-cell-range-end
        ) {
        :hover {
          .ant-picker-cell-inner {
            background: ${({ theme }) => theme.colors.gray50};
          }
        }
      }
    }

    .ant-picker-cell-range-hover-start .ant-picker-cell-inner,
    .ant-picker-cell-range-hover-end .ant-picker-cell-inner {
      border-radius: ${({ theme }) => theme.borderRadius.xs};
    }
  }
`;
