import { type ID } from 'data';
import { type MetricDomain } from 'data/metrics';

export interface SearchItem {
  id: ID;
  name: string;
  metricName: string;
  displayName: string;
  type: SearchItemType;
  nameLength: number;
  redirectUrl: string;
  metricCategory: string;
  description?: string;
  fullDimensionName: string;
  tableDisplayName: string;
  parentDisplayName: string;
  columnType: string;
  domain: string;
  moduleDisplayName: string;
  unique: boolean;
}

export interface SearchRequest {
  query: string;
  filters: Filter[];
  size?: number;
  fieldsToBeSearched?: FieldToSearch[];
  sendUniqueColumns?: boolean;
}

export interface Filter {
  field: Field;
  value: SearchItemType | MetricDomain | string | boolean;
  operator?: Operator;
}

export interface FieldToSearch {
  attribute: FieldToSearchAttribute;
  weight?: number;
}

export enum FieldToSearchAttribute {
  DisplayName = 'DISPLAY_NAME',
  TableName = 'TABLE_NAME',
  TableDisplayName = 'TABLE_DISPLAY_NAME',
}

export enum SearchItemType {
  Metric = 'METRIC',
  Board = 'BOARD',
  Module = 'MODULE',
  Dimension = 'DIMENSION',
  DatasetColumn = 'COLUMN',
  DimensionGroup = 'DIMENSION_GROUP',
  List = 'LIST',
}

export enum Field {
  Type = 'TYPE',
  Domain = 'DOMAIN',
  DatasetSchema = 'DATASET_SCHEMA',
  TableName = 'TABLE_NAME',
  ColumnSource = 'COLUMN_SOURCE',
  UniqueColumn = 'COLUMN_UNIQUE',
}

export enum Operator {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
}
