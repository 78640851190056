import { css } from 'styled-components';

export const gridBorderLessStyles = css`
  && .ag-cell {
    border-right: 1px solid ${({ theme }) => theme.border.color};
  }

  .ag-row {
    border-width: 0;
  }

  .ag-row-last {
    border-width: 1px;
  }

  [row-id='create-new-metric'] {
    border-top: 1px solid ${({ theme }) => theme.border.color};
  }
`;
