import { AsyncSwitcher } from 'components/ui/async-switcher';
import { ConfirmationModal, type ModalProps } from 'components/ui/atomic-components';
import { useLoadResourceUsage } from 'data/resource-usage/hooks';
import { type ResourceUsageResp, type ResourceUsageType } from 'data/resource-usage/types';
import { PermissionList } from 'data/roles/permissions';
import { type ReactNode, type ReactElement } from 'react';
import { useHasScope } from 'utils/hooks';
import { hasNoUsageForResource } from '../utils';
import { DeleteUsageMessage } from './delete-usage-message';

export interface Props extends ModalProps {
  name: string;
  displayName: string;
  type: ResourceUsageType;
  noUsagesMessage: ReactNode;
}

export const DeleteConfirmWithUsage = ({
  name,
  displayName,
  type,
  open,
  title,
  confirmLoading,
  noUsagesMessage,
  onCancel,
  onOk,
}: Props): ReactElement => {
  const hasScope = useHasScope();
  const isAdmin = hasScope(PermissionList.All);

  const usageQuery = useLoadResourceUsage({
    name,
    type,
    options: {
      enabled: open && isAdmin,
      refetchOnWindowFocus: true,
    },
  });

  return (
    <ConfirmationModal
      confirmLoading={confirmLoading}
      open={open}
      title={title}
      type="delete"
      onCancel={onCancel}
      onOk={onOk}
    >
      <AsyncSwitcher {...usageQuery}>
        {(data: ResourceUsageResp) => {
          if (!isAdmin || hasNoUsageForResource(data.dependencies)) {
            return noUsagesMessage;
          }

          return <DeleteUsageMessage data={data} displayName={displayName} />;
        }}
      </AsyncSwitcher>
    </ConfirmationModal>
  );
};
