import { type ReactElement, type ReactNode } from 'react';
import styled from 'styled-components';
import { FontS, FontXl } from 'styles/typography';
import { HeaderStyles } from './styles';

const Desc = styled.div<{ size?: 'small' | 'middle' | 'large' }>`
  ${FontS}

  color: ${({ theme }) => theme.colors.gray700};
  margin-top: ${({ size, theme }) => (size === 'small' ? 0 : theme.spacing[4])};
  display: flex;
  align-items: center;

  > svg {
    margin-right: ${({ theme: { spacing } }) => spacing[8]};
  }
`;

interface PageHeaderProps {
  children: ReactNode;
  className?: string;
  bordered?: boolean;
  highlighted?: boolean;
  shadowOnScroll?: boolean;
}

const PageHeader = ({
  children,
  className,
  bordered,
  shadowOnScroll,
  highlighted,
}: PageHeaderProps): ReactElement => {
  return (
    <>
      {shadowOnScroll && <HeaderStyles.Background />}

      <HeaderStyles.Wrapper highlighted={highlighted}>
        <HeaderStyles.Container
          $bordered={bordered}
          $highlighted={highlighted}
          $shadowOnScroll={shadowOnScroll}
          className={className}
        >
          {children}
        </HeaderStyles.Container>
      </HeaderStyles.Wrapper>
    </>
  );
};

const Title = styled.span`
  ${FontXl}

  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const HeaderTitle = ({ className, children }: { className?: string; children: ReactNode }) => (
  <Title className={className}>{children}</Title>
);
const HeaderDesc = ({
  className,
  children,
  size,
}: {
  className?: string;
  children: ReactNode;
  size?: 'small' | 'middle' | 'large';
}) => (
  <Desc className={className} size={size}>
    {children}
  </Desc>
);

PageHeader.Primary = HeaderStyles.Container.Primary;
PageHeader.Secondary = HeaderStyles.Container.Secondary;
PageHeader.Title = HeaderTitle;
PageHeader.Description = HeaderDesc;

export { PageHeader };
