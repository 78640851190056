import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import styled from 'styled-components';
import { type ListGridRow } from '../grid/types';

const Text = styled.span`
  color: ${({ theme }) => theme.colors.gray00};
`;

const StyledRedButton = styled(Button)`
  background: ${({ theme }) => theme.colors.red500};

  &&:not([disabled]):hover {
    background: ${({ theme }) => theme.colors.red400};
  }
`;

interface Props {
  onDeleteListRows: (rowNode: ListGridRow[]) => void;
}

export const DeleteAction = ({ onDeleteListRows }: Props): ReactElement => {
  const selectedRowIds = useListStoreContext((s) => s.selectedRowIds);
  const setSelectedRowIds = useListStoreContext((s) => s.setSelectedRowIds);
  const store = useListStoreInstance();

  const onDelete = () => {
    const rowNodeToDelete: ListGridRow[] = [];

    store.getState().rows.forEach((row) => {
      if (selectedRowIds.includes(row.id)) {
        rowNodeToDelete.push(row);
      }
    });

    onDeleteListRows(rowNodeToDelete);
    setSelectedRowIds([]);
  };

  return (
    <StyledRedButton
      icon={<IconShell color="gray00" icon={DeleteIcon} />}
      size="extraSmall"
      onClick={onDelete}
    >
      <Text>
        <FormattedMessage id="lists.floating.delete_rows" />
      </Text>
    </StyledRedButton>
  );
};
