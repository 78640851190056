import { type ReactNode, type ReactElement } from 'react';
import styled from 'styled-components';
import { Space } from '../space';

const SecondaryWrapper = styled(Space)`
  flex-grow: 1;
  justify-content: flex-end;
`;

export const Secondary = ({ children }: { children?: ReactNode }): ReactElement => (
  <SecondaryWrapper>{children}</SecondaryWrapper>
);
