import { Modal } from 'components/ui/atomic-components/modal';
import { Space } from 'components/ui/atomic-components/space';
import { Spinner } from 'components/ui/atomic-components/spinner';
import { type ReactNode, type ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModal = styled(Modal)`
  &&& .ant-modal-footer {
    display: none;
  }
`;

interface Props {
  open: boolean;
  text?: string | ReactNode;
}

export const ActionInProgressOverlay = ({ open, text }: Props): ReactElement => {
  return (
    <StyledModal centered closable={false} open={open} width={240}>
      <Wrapper>
        <Space>
          <div>{text}</div>
          <Spinner size="small" />
        </Space>
      </Wrapper>
    </StyledModal>
  );
};
