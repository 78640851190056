import { Popover as AntPopover } from 'antd';
import { type TooltipRef } from 'antd/es/tooltip';
import { useDismissOnEsc } from 'components/ui/common/hooks/use-dismiss-on-esc';
import { forwardRef } from 'react';
import { ModalFooter } from '../modal/footer';
import { PopoverFooter } from './styles';
import { type PopoverProps } from './types';

export const Popover = forwardRef<TooltipRef, PopoverProps>(
  (
    {
      cancelText = '',
      confirmLoading,
      confirmDisabled,
      confirmText = '',
      footerExtra,
      $footerPx,
      $footerPy,
      okButtonProps,
      onConfirm,
      onCancel,
      onEsc,
      ...props
    },
    ref,
  ): React.ReactElement => {
    useDismissOnEsc({ enable: !!props.open && !!onEsc, dismiss: onEsc });

    return (
      <AntPopover
        trigger="click"
        {...props}
        ref={ref}
        arrow={false}
        content={
          <>
            {props.content}
            {(cancelText || confirmText) && (
              <PopoverFooter $px={$footerPx || 12} $py={$footerPy || 16}>
                {footerExtra}
                <div>
                  <ModalFooter
                    cancelText={cancelText}
                    confirmLoading={confirmLoading}
                    okButtonProps={okButtonProps}
                    okDisabled={confirmDisabled}
                    okText={confirmText}
                    onCancel={onCancel}
                    onOk={onConfirm}
                  />
                </div>
              </PopoverFooter>
            )}
          </>
        }
      />
    );
  },
);

Popover.displayName = 'Popover';
