import { Banner } from 'components/ui/atomic-components';
import { useModelVersion } from 'data/modelling/model/versions';
import { isEmpty, keyBy } from 'lodash';
import { useEffect, useMemo, useState, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { VersionAccessModes } from './constants';
import { useVersionMode } from './hooks/use-version-mode';
import { LaunchingVersionAlert } from './launching-version-alert';
import { PreSavePromptModal } from './presave-prompt-modal';

export const VersionViewBanner = (): ReactElement => {
  const [isPreSavePromptOpen, setIsPreSavePromptOpen] = useState(false);
  const { data } = useModelVersion({ includeArchived: true });
  const intl = useIntl();

  const { isInModelPage, isLoading, versionId, accessMode, exitCurrentMode } = useVersionMode();

  useEffect(() => {
    if (!isInModelPage && versionId) {
      exitCurrentMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInModelPage, versionId]);

  const { label, versionName } = useMemo(() => {
    if (versionId && !isEmpty(data)) {
      const lookup = keyBy(data, 'id');

      if (lookup[versionId] && accessMode) {
        const versionName = lookup[versionId]?.displayName;

        return {
          label: intl.formatMessage(
            {
              id: `model.versions.${
                accessMode === VersionAccessModes.View ? 'view' : 'edit'
              }.page_ribbon_label`,
            },
            { versionName },
          ),
          versionName,
        };
      }
    }

    return { label: '' };
  }, [versionId, accessMode, data, intl]);

  if (isLoading) {
    return <LaunchingVersionAlert isOpen={isLoading} />;
  }

  if (!versionId) {
    return <></>;
  }

  return (
    <>
      <Banner
        title={<>{label}</>}
        onClose={() => {
          if (accessMode === VersionAccessModes.Edit) {
            setIsPreSavePromptOpen(true);
          } else {
            exitCurrentMode();
          }
        }}
      />
      <PreSavePromptModal
        isPreSavePromptOpen={isPreSavePromptOpen}
        setIsPreSavePromptOpen={setIsPreSavePromptOpen}
        versionName={versionName}
      />
    </>
  );
};
