import { DisplayUnitType } from './types';

const THOUSAND = 1000;
const LAKH = 100000;
const MILLION = 1000000;
const CRORE = 10000000;

export const displayUnitSymbolMap: { [key: string]: string } = {
  [DisplayUnitType.K]: 'K',
  [DisplayUnitType.M]: 'M',
  [DisplayUnitType.Lac]: 'L',
  [DisplayUnitType.Cr]: 'Cr',
};

export const displayUnitFactorMap: { [key: string]: number } = {
  [DisplayUnitType.K]: THOUSAND,
  [DisplayUnitType.M]: MILLION,
  [DisplayUnitType.Lac]: LAKH,
  [DisplayUnitType.Cr]: CRORE,
};
