import { Popover } from 'components/ui/atomic-components/popover';
import { type Dimension } from 'data/dimension';
import { useGetMetricSpaces } from 'data/metrics/hooks';
import { SCENARIO, useScenarios } from 'data/scenarios';
import { useScenarioDimension } from 'data/scenarios/hooks/use-scenario-dimension';
import { isEmpty } from 'lodash';
import { useState, type ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatName } from 'utils/data-formatter';
import { FormulaBarPopoverOverlayClass } from '../../constants';
import { DimensionValueSelector } from '../dimensions-selector-menu/dimension-value-selector';
import { DimensionValueSingleSelect } from '../dimensions-selector-menu/dimension-value-single-select';
import { SubscriptTag } from './subscript-tag';
import { getSelectedValue } from './utils';

interface Props {
  metricName: string;
  dimensionValues: string[];
  dimensionName: string;
  onApply: (name: string, value: string[]) => void;
  readOnly?: boolean;
}

// temporarily adding the flag until tess supports it
const disableFormulaMultiselect = true;

export const DimensionSubscriptTag = ({
  metricName,
  dimensionName,
  dimensionValues,
  onApply,
  readOnly,
}: Props): ReactElement => {
  const intl = useIntl();

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedValues, setSelectedValues] = useState(dimensionValues);
  const [open, setOpen] = useState(false);

  const scenarioDimension = useScenarioDimension();

  const { data: { dimensions = [] } = {} } = useGetMetricSpaces(open ? metricName : undefined);
  const { scenarios } = useScenarios();

  const dimension = useMemo(
    () => [scenarioDimension, ...dimensions].find((d) => d.name === dimensionName) as Dimension,
    [dimensionName, dimensions, scenarioDimension],
  );

  const dimensionDisplayValues = dimensionValues.map((dimensionValue) => {
    if (dimensionValue === '') {
      return intl.formatMessage({ id: 'dimensions.blank' });
    }

    if (dimensionName === SCENARIO) {
      return scenarios.find((sc) => sc.name === dimensionValue)?.displayName;
    }

    return formatName(dimensionValue);
  });

  const handleItemSelect = (val: string) => {
    if (selectedValues.includes(val)) {
      const selectedValuesState = [...selectedValues];

      setSelectedValues(selectedValuesState.filter((v) => v !== val));
    } else {
      setSelectedValues([...selectedValues, val]);
    }
  };

  const handleSelectAllToggle = () => {
    setIsAllSelected(!isAllSelected);
  };

  if (isEmpty(dimensionDisplayValues)) {
    return <></>;
  }

  const handleClickApply = () => {
    onApply(dimension.name, selectedValues);
    setOpen(false);
  };

  const handleSingleSelect = (val: string) => {
    setSelectedValues([val]);
    onApply(dimension.name, [val]);
    setOpen(false);
  };

  if (readOnly) {
    return (
      <div>
        <SubscriptTag label={dimensionDisplayValues.join(', ')} />
      </div>
    );
  }

  return (
    <Popover
      content={
        <>
          {disableFormulaMultiselect && dimension && (
            <DimensionValueSingleSelect
              dimensionName={dimension.name}
              metricName={metricName}
              selectedValue={getSelectedValue(selectedValues[0])}
              title={formatName(dimension.name)}
              onMenuItemClick={handleSingleSelect}
            />
          )}
          {!disableFormulaMultiselect && dimension && (
            <DimensionValueSelector
              isAllSelected={isAllSelected}
              metricName={metricName}
              selectedDimension={dimension}
              selectedValues={selectedValues}
              onClickApply={handleClickApply}
              onItemSelect={handleItemSelect}
              onSelectAllToggle={handleSelectAllToggle}
            />
          )}
        </>
      }
      destroyTooltipOnHide
      open={open}
      overlayClassName={FormulaBarPopoverOverlayClass}
      placement="bottomLeft"
      onOpenChange={setOpen}
    >
      <div>
        <SubscriptTag label={dimensionDisplayValues.join(', ')} />
      </div>
    </Popover>
  );
};
