import { useQueryClient } from '@tanstack/react-query';
import { ReactComponent as DatabasePlusIcon } from 'assets/v2/database-plus.svg';
import { ReactComponent as DefaultSortIcon } from 'assets/v2/default_sort.svg';
import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { ReactComponent as DotsVerticalIcon } from 'assets/v2/dots-vertical.svg';
import { ReactComponent as EyeBlockIcon } from 'assets/v2/eye-block.svg';
import { ReactComponent as EditIcon } from 'assets/v2/pencil.svg';
import { ReactComponent as SortAscendingIcon } from 'assets/v2/sort-ascending.svg';
import { ReactComponent as SortDescendingIcon } from 'assets/v2/sort-descending.svg';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { Button, Dropdown, IconShell } from 'components/ui/atomic-components';
import { DropdownItemLabel } from 'components/ui/atomic-components/dropdown/dropdown-item-label';
import { ResourceUsageModal, useResourceUsageWidget } from 'components/ui/resource-usage-widget';
import { QueryTableColumnDataType } from 'data/big-query';
import { ListSortOrder } from 'data/modelling/lists';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState, type ReactElement, type RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import { queueMacroTask } from 'utils/queue-macro-task';
import { useColumnMutations } from '../../../hooks/use-grid-crud/use-column-mutations';
import { DataMasking } from '../data-masking';
import { DataValidation } from '../data-validation';
import { EditColumn } from '../edit-column';
import { removeCacheForAllListsWithFormulaColumns } from '../utils';
import { useFrozenColumn } from './hooks';
import { getActiveKey, sortGridRows } from './utils';
import { removeColumnFromCache } from './utils/remove-column-from-cache';

interface Props {
  colName: string;
  isUniqueColumn: boolean;
  isSubColumnOfUniqueCol: boolean;
  columnType: QueryTableColumnDataType | 'FORMULA';
  dateFormat?: string;
  columnWrapperDomRef: RefObject<HTMLDivElement>;
  formula: {
    formulaText: string | undefined;
    query: string | undefined;
  };
}

export const OverflowMenu = ({
  colName,
  isUniqueColumn,
  isSubColumnOfUniqueCol,
  columnType,
  dateFormat,
  columnWrapperDomRef,
  formula,
}: Props): ReactElement => {
  const queryClient = useQueryClient();
  const { dataMasking } = useFlags();

  const { onDeleteColumn } = useColumnMutations();
  const { updateListMutation } = useListMutations();

  const removeColumn = useListStoreContext((s) => s.removeColumn);
  const listId = useListStoreContext((s) => s.id);
  const listName = useListStoreContext((s) => s.name);
  const config = useListStoreContext((s) => s.config);
  const gridApi = useListStoreContext((s) => s.gridApi);
  const embedPlace = useListStoreContext((s) => s.embedPlace);
  const sortRows = useListStoreContext((s) => s.sortRows);
  const pushToUndoRedoStack = useListStoreContext((s) => s.pushToUndoRedoStack);
  const resetUndoRedoStack = useListStoreContext((s) => s.resetUndoRedoStack);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditColumnOpen, setIsEditColumnOpen] = useState(false);
  const [isDataValidationOpen, setIsDataValidationOpen] = useState(false);
  const [isDataMaskingOpen, setIsDataMaskingOpen] = useState(false);

  const { menuItem: frozenMenuItem } = useFrozenColumn(colName);

  const { usageWidgetMenuItem, usageModalProps, isTryingToCloseWhileUsageFetch, closeUsageModal } =
    useResourceUsageWidget({
      name: `${listName}.${colName}`,
      displayName: formatName(colName) || '',
      type: 'LISTS',
      closeMenu: () => setIsMenuOpen(false),
    });

  const deleteColumnClick = async () => {
    const requestBody = { columnName: colName, type: columnType } as {
      columnName: string;
      type?: QueryTableColumnDataType;
    };

    if (columnType === 'FORMULA') {
      delete requestBody.type;
    }

    await onDeleteColumn.mutateAsync(requestBody);

    removeColumn(colName);
    removeCacheForAllListsWithFormulaColumns(queryClient, listId);
    removeColumnFromCache({ queryClient, listId, columnName: colName });
    resetUndoRedoStack();
  };

  const showEditOption = !isUniqueColumn && embedPlace === 'global-list';

  const hideDeleteOption = isUniqueColumn || isSubColumnOfUniqueCol || embedPlace !== 'global-list';

  const showDataValidationOption =
    !isUniqueColumn && columnType === QueryTableColumnDataType.String;

  const showDataMaskingOption =
    dataMasking && !isUniqueColumn && columnType === QueryTableColumnDataType.Numeric;

  const menuItems = [
    ...(showEditOption
      ? [
          {
            key: 'edit',
            label: <FormattedMessage id="lists.edit_column" />,
            icon: <IconShell icon={EditIcon} />,
            onClick: () => setIsEditColumnOpen(true),
          },
        ]
      : []),
    frozenMenuItem,
    ...(showDataValidationOption
      ? [
          {
            key: 'data-validation',
            label: <FormattedMessage id="lists.data_validation" />,
            icon: <IconShell icon={DatabasePlusIcon} />,
            onClick: () => setIsDataValidationOpen(true),
          },
        ]
      : []),
    ...(showDataMaskingOption
      ? [
          {
            key: 'data-masking',
            label: <FormattedMessage id="lists.data_masking" />,
            icon: <IconShell icon={EyeBlockIcon} />,
            onClick: () => setIsDataMaskingOpen(true),
          },
        ]
      : []),
    {
      type: 'divider',
      key: 'divider1',
    },
    {
      key: 'sort',
      type: 'group',
      label: <FormattedMessage id="sort" />,
      children: [
        {
          key: 'default',
          label: <FormattedMessage id="default" />,
          icon: <IconShell color="iconDefault" icon={DefaultSortIcon} />,
          onClick: () => {
            if (!colName) {
              return;
            }

            sortGridRows({
              gridApi,
              currentSort: { column: colName, order: null },
              previousSort: { column: config.sortedColumn, order: config?.sortOrder },
              sortRows,
              pushToUndoRedoStack,
            });

            updateListMutation.mutate({
              id: listId,
              list: { config: { ...config, sortOrder: null, sortedColumn: colName } },
            });
          },
        },
        {
          key: 'sort-asc',
          label: <FormattedMessage id="sort_ascending" />,
          icon: <IconShell color="iconDefault" icon={SortAscendingIcon} />,
          onClick: () => {
            if (!colName) {
              return;
            }

            sortGridRows({
              gridApi,
              currentSort: { column: colName, order: ListSortOrder.Asc },
              previousSort: { column: config.sortedColumn, order: config?.sortOrder },
              sortRows,
              pushToUndoRedoStack,
            });

            updateListMutation.mutate({
              id: listId,
              list: { config: { ...config, sortOrder: ListSortOrder.Asc, sortedColumn: colName } },
            });
          },
        },
        {
          key: 'sort-desc',
          label: <FormattedMessage id="sort_descending" />,
          icon: <IconShell color="iconDefault" icon={SortDescendingIcon} />,
          onClick: () => {
            if (!colName) {
              return;
            }

            sortGridRows({
              gridApi,
              currentSort: { column: colName, order: ListSortOrder.Desc },
              previousSort: { column: config.sortedColumn, order: config?.sortOrder },
              sortRows,
              pushToUndoRedoStack,
            });

            updateListMutation.mutate({
              id: listId,
              list: { config: { ...config, sortOrder: ListSortOrder.Desc, sortedColumn: colName } },
            });
          },
        },
      ],
    },
    usageWidgetMenuItem,
    ...(!hideDeleteOption
      ? [
          {
            type: 'divider',
            key: 'divider2',
          },
          {
            key: 'delete',
            danger: true,
            icon: <IconShell icon={DeleteIcon} />,
            label: <DropdownItemLabel title={<FormattedMessage id="lists.delete_column" />} />,
            onClick: deleteColumnClick,
          },
        ]
      : []),
  ];

  return (
    <>
      <Dropdown
        menu={{ items: menuItems, activeKey: getActiveKey(config, colName) }}
        open={isMenuOpen}
        trigger={['click']}
        onOpenChange={(open) => {
          queueMacroTask(() => {
            if (isTryingToCloseWhileUsageFetch(open)) {
              return;
            }

            setIsMenuOpen(open);
          });
        }}
      >
        <Button
          icon={<IconShell icon={DotsVerticalIcon} size="sm" />}
          loading={onDeleteColumn.isLoading}
          size="extraSmall"
          type="text"
        />
      </Dropdown>

      <EditColumn
        colName={colName}
        columnType={columnType}
        columnWrapperDomRef={columnWrapperDomRef}
        dateFormat={dateFormat}
        formula={formula}
        isOpen={isEditColumnOpen}
        setIsOpen={setIsEditColumnOpen}
      />

      <DataValidation
        closeOpenPopup={(val) => setIsDataValidationOpen(val)}
        columnName={colName}
        columnWrapperDomRef={columnWrapperDomRef}
        isOpen={isDataValidationOpen}
      />

      <DataMasking
        closeOpenPopup={(val) => setIsDataMaskingOpen(val)}
        columnName={colName}
        columnWrapperDomRef={columnWrapperDomRef}
        isOpen={isDataMaskingOpen}
      />

      <ResourceUsageModal usageProps={usageModalProps} onClose={closeUsageModal} />
    </>
  );
};
