import { ReactComponent as DataIcon } from 'assets/v2/database-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { ReactComponent as DownloadIcon } from 'assets/v2/download.svg';
import { ReactComponent as KeyIcon } from 'assets/v2/key.svg';
import { ReactComponent as LockIcon } from 'assets/v2/lock.svg';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { type ItemType } from 'components/ui/atomic-components/menu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { exportListAsCsv } from './utils';

export const useListMenuOptions = ({
  usageWidgetMenuItem,
  accessControlled,
  onClickAccessControl,
  onClickUniqueKeyOption,
  onClickUpdateFromDataOption,
  onClickDelete,
}: {
  usageWidgetMenuItem: ItemType;
  accessControlled: boolean;
  onClickAccessControl: () => void;
  onClickUniqueKeyOption: () => void;
  onClickUpdateFromDataOption: () => void;
  onClickDelete: () => void;
}): ItemType[] => {
  const { tempDerivedListRecon, tempSccl } = useFlags();

  const uniqueKeyColumns = useListStoreContext((s) => s.config.uniqueKeyColumns);
  const gridApi = useListStoreContext((s) => s.gridApi);
  const listName = useListStoreContext((s) => s.name);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const config = useListStoreContext((s) => s.config);

  const showOverflowMenu = hasListAccess(accessAction, false, config.backedByBQ).topOverflowMenu;
  const showUniqueKeyConfigure = hasListAccess(
    accessAction,
    false,
    config.backedByBQ,
  ).uniqueKeyConfigure;
  const isInVersion = isInVersionMode([VersionAccessModes.View, VersionAccessModes.Edit]);

  const isUniqueKeySet = !isEmpty(uniqueKeyColumns);

  return [
    {
      key: 'unique-key',
      icon: <IconShell icon={KeyIcon} />,
      label: isUniqueKeySet ? (
        <FormattedMessage id="lists.edit_unique_key" />
      ) : (
        <FormattedMessage id="lists.set_unique_key" />
      ),
      onClick: onClickUniqueKeyOption,
      hidden: !tempSccl || !showUniqueKeyConfigure || isInVersion,
    },
    {
      key: 'update-from-data',
      icon: <IconShell icon={DataIcon} size="sm" />,
      label: <FormattedMessage id="lists.reconciliation.modal.title" />,
      onClick: onClickUpdateFromDataOption,
      hidden: !tempDerivedListRecon,
    },
    {
      key: 'download-csv',
      icon: <IconShell icon={DownloadIcon} />,
      label: <FormattedMessage id="lists.export_csv" />,
      onClick: () => exportListAsCsv({ gridApi, listName }),
    },
    {
      key: 'access-controlled',
      label: accessControlled ? (
        <FormattedMessage id="dimensions.remove_access" />
      ) : (
        <FormattedMessage id="dimensions.set_access_control" />
      ),
      icon: <IconShell icon={LockIcon} />,
      hidden: !showOverflowMenu || isInVersion,
      onClick: onClickAccessControl,
    },

    ...(!isInVersion ? [usageWidgetMenuItem] : []),

    ...(showOverflowMenu && !isInVersion ? [{ key: 'divider', type: 'divider' } as ItemType] : []),

    {
      key: 'delete-list',
      danger: true,
      icon: <IconShell icon={DeleteIcon} />,
      label: <FormattedMessage id="lists.delete_list" />,
      hidden: !showOverflowMenu || isInVersion,
      onClick: onClickDelete,
    },
  ];
};
