import { IconShell, type IconType } from 'components/ui/atomic-components/icon-shell';
import { type FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontXxs } from 'styles/typography';

export const LeftNavWrapper = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.iconDefault};
  }

  span {
    ${FontXxs};

    color: ${({ theme: { colors } }) => colors.textBody2};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  :has(.active),
  :hover {
    background: ${({ theme }) => theme.colors.bgMedium};

    span {
      color: ${({ theme: { colors } }) => colors.textTitle1};
    }

    svg {
      color: ${({ theme }) => theme.colors.textTitle1};
    }
  }
`;

const LinkTag = styled(NavLink)`
  width: 56px;
  height: 56px;
  padding: ${({ theme }) => theme.spacing[8]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface ILeftNavItemProps {
  label: string;
  navlink: string;
  icon: IconType;
}

export const LeftNavItem: FC<React.PropsWithChildren<ILeftNavItemProps>> = ({
  label,
  navlink,
  icon,
}) => {
  return (
    <LeftNavWrapper>
      <LinkTag to={navlink}>
        <IconShell icon={icon} size="md" />
        <span>{label}</span>
      </LinkTag>
    </LeftNavWrapper>
  );
};
