import { type CellClassParams, type ColDef } from 'ag-grid-community';
import { QueryTableColumnDataType } from 'data/big-query';
import { type ListConfig } from 'data/modelling/lists';
import { type ListsStoreApi } from 'store/lists/store';
import { formatName } from 'utils/data-formatter';
import { maskedColumnDef } from 'utils/grid';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { type StoreApi } from 'zustand';
import { ColumnHeader } from '../../../cell-components/column-header';
import { searchHighlightCellClassRule } from './list-cell-utils';
import { listGridComponentsMap } from './list-grid-components';

export const createColDef = ({
  config,
  colName,
  store,
  isReadOnly,
  embedPlace,
  defaultDateFormat,
}: {
  config: ListConfig;
  colName: string;
  store: StoreApi<ListsStoreApi>;
  isReadOnly: boolean;
  embedPlace: 'global-list' | 'report';
  defaultDateFormat: string;
}): ColDef => {
  const { accessAction, maskedColumns } = store.getState();

  const {
    uniqueKeyColumns,
    columnTypeMap,
    frozenColumns,
    columnFormulae,
    dateFormatMap,
    editableColumns,
    backedByBQ,
  } = config;
  const columnFormula = columnFormulae?.find((col) => col.colName === colName);

  const type = columnTypeMap[colName];
  const dateFormat = dateFormatMap?.[colName] || defaultDateFormat;

  const uniqueKeyColumnName = config.uniqueKey;
  const isUniqueColumn = colName === uniqueKeyColumnName;
  const isSubColumnOfUniqueCol = uniqueKeyColumns?.includes(colName);
  const isCompositeUniqueColumn = isUniqueColumn && (uniqueKeyColumns || [])?.length > 1;
  const hasGridEditAccess = hasListAccess(accessAction, isReadOnly, backedByBQ).editGrid;
  const canReorderColumns = hasListAccess(accessAction, isReadOnly, backedByBQ).reorderColumns;
  const isColumnInEditableList = editableColumns ? editableColumns.includes(colName) : true;

  const isMaskedColumn = maskedColumns.includes(colName);

  return {
    colId: colName,
    editable:
      !isCompositeUniqueColumn && hasGridEditAccess && isColumnInEditableList && !columnFormula,
    headerName: formatName(colName),
    lockPinned: true,
    pinned: frozenColumns?.includes(colName) ? 'left' : false,
    minWidth: 140,
    hide: isCompositeUniqueColumn,
    headerComponent: ColumnHeader,
    suppressFillHandle:
      !hasGridEditAccess || !isColumnInEditableList || !!columnFormula || isMaskedColumn,
    suppressMovable: !canReorderColumns,
    headerComponentParams: {
      isUniqueColumn,
      isSubColumnOfUniqueCol,
      colName,
      columnType: columnFormula ? 'FORMULA' : type,
      dateFormat,
      formula: {
        formulaText: columnFormula?.formula,
        query: columnFormula?.query,
      },
    },
    cellEditorPopup: true,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'search-cell-highlight': ({ value }: CellClassParams) => {
        if (
          embedPlace === 'report' &&
          hasGridEditAccess &&
          editableColumns &&
          isColumnInEditableList
        ) {
          return true;
        }

        return searchHighlightCellClassRule({
          type,
          store,
          value,
        });
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'ag-right-aligned-cell': () =>
        [QueryTableColumnDataType.Numeric, QueryTableColumnDataType.Date].includes(type),
    },
    ...listGridComponentsMap({ key: colName, store })[type],
    ...(isMaskedColumn ? maskedColumnDef : {}),
  };
};
