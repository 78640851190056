import { type ColumnGroup } from 'ag-grid-community';
import { Typography } from 'antd';
import { ReactComponent as ColumnCollapseIcon } from 'assets/ic_column_collapse.svg';
import { ReactComponent as ColumnExpandIcon } from 'assets/ic_column_expand.svg';
import { Granularity } from 'data';
import { type CSSProperties, type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Caption } from 'styles/typography';
import { IconShell } from '../../icon-shell';

const { Paragraph } = Typography;

type WrapperProps = {
  $isExpandable: boolean;
};

const HeaderDisplayNameWrapper = styled(Paragraph).attrs({
  ellipsis: { rows: 2 },
})`
  padding-left: ${({ theme }) => theme.spacing[8]};

  &.ant-typography {
    margin-bottom: ${({ theme }) => theme.spacing[0]};
  }
`;

export const CustomColumnGroupHeaderWrapper = styled.div<WrapperProps>`
  ${Caption};

  height: 100%;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing[12]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  cursor: ${({ $isExpandable }) => ($isExpandable ? 'pointer' : 'initial')};

  > div {
    flex: 1;
    text-align: right;
  }
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  flex-flow: column;
`;

const PeriodText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.footnote};
  color: ${({ theme }) => theme.colors.textTertiary};
`;

export const CustomColumnGroupHeader: FC<
  React.PropsWithChildren<{
    setExpanded: (no: boolean) => boolean;
    expandable?: boolean;
    displayName: string;
    columnGroup: ColumnGroup;
    styles?: CSSProperties;
  }>
> = ({ displayName, setExpanded, columnGroup, styles, expandable = true }) => {
  const [isExpanded, setIsExpanded] = useState(columnGroup.isExpanded());

  const isExpandable =
    Boolean(displayName) && (columnGroup.getChildren()?.length || 0) > 2 && expandable;

  const periodInfo = columnGroup.getColGroupDef()?.headerGroupComponentParams?.period;

  const onHeaderClick = () => {
    if (isExpandable) {
      setExpanded(!isExpanded);
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <CustomColumnGroupHeaderWrapper
      $isExpandable={isExpandable}
      style={styles}
      onClick={onHeaderClick}
    >
      {isExpandable &&
        (isExpanded ? (
          <IconShell icon={ColumnCollapseIcon} />
        ) : (
          <IconShell icon={ColumnExpandIcon} />
        ))}
      <PeriodWrapper>
        {periodInfo && periodInfo.type === Granularity.Weekly && (
          <PeriodText>
            <FormattedMessage id="week.ending_date" />
          </PeriodText>
        )}
        <HeaderDisplayNameWrapper>{displayName}</HeaderDisplayNameWrapper>
      </PeriodWrapper>
    </CustomColumnGroupHeaderWrapper>
  );
};
