import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { SearchBox } from 'components/ui/atomic-components/searchbox';
import { KEYBOARD_DEBOUNCE_INTERVAL } from 'config/constants';
import { debounce } from 'lodash';
import { useRef, type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { GridTopBar } from '../../grid-top-bar';
import { MoreActions } from './more-actions';
import { UploadCSV } from './upload-csv';

interface Props {
  toggleShowLeftNav: () => void;
  showLeftNav: boolean;
}

export const SecondaryTopBar = ({ toggleShowLeftNav, showLeftNav }: Props): ReactElement => {
  const listDisplayName = useListStoreContext((s) => s.displayName);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const { selectiveAccessColumns, backedByBQ } = useListStoreContext((s) => s.config);
  const setSearchQuery = useListStoreContext((s) => s.setSearchQuery);
  const isReadOnlyTopBar = isInVersionMode([VersionAccessModes.View, VersionAccessModes.Edit]);

  const showUploadCsv =
    selectiveAccessColumns?.length === 0 &&
    hasListAccess(accessAction, false, backedByBQ).uploadCsv &&
    !isReadOnlyTopBar;

  const handleSearchQueryChange = useRef(
    debounce((e) => setSearchQuery(e.target.value), KEYBOARD_DEBOUNCE_INTERVAL),
  ).current;

  return (
    <GridTopBar
      isReadOnly={isReadOnlyTopBar}
      listName={listDisplayName || ''}
      rightItems={
        <>
          <SearchBox onChange={handleSearchQueryChange} />
          {showUploadCsv && <UploadCSV />}

          <MoreActions />
        </>
      }
      showLeftNav={showLeftNav}
      toggleShowLeftNav={toggleShowLeftNav}
    />
  );
};
