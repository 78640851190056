import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { ReactComponent as KeyIcon } from 'assets/v2/key.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type ItemType } from 'components/ui/atomic-components/menu';
import { FormattedMessage } from 'react-intl';

export const getMoreActionsOptions = ({
  usageWidgetMenuItem,
  onEdit,
  onDelete,
  onDownload,
  isReadOnly,
}: {
  usageWidgetMenuItem: ItemType;
  onDelete: () => void;
  onEdit: () => void;
  onDownload?: () => void;
  isReadOnly?: boolean;
}): ItemType[] => {
  return [
    {
      key: 'edit',
      label: <FormattedMessage id="lists.edit" />,
      icon: <IconShell icon={KeyIcon} />,
      onClick: onEdit,
      hidden: isReadOnly,
    },
    {
      key: 'download',
      icon: <IconShell icon={DownloadIcon} />,
      onClick: onDownload,
      label: <FormattedMessage id="download_as_excel" />,
      hidden: !onDownload,
    },

    ...(isReadOnly ? [] : [usageWidgetMenuItem]),

    { key: 'divider', type: 'divider', hidden: isReadOnly },
    {
      key: 'delete',
      danger: true,
      icon: <IconShell icon={DeleteIcon} />,
      label: <FormattedMessage id="lists.delete_list" />,
      onClick: onDelete,
      hidden: isReadOnly,
    },
  ];
};
