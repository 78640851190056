import { type GridApi, type ColDef } from 'ag-grid-community';
import { AgGridV2 } from 'components/ui/atomic-components';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { constructColDefs } from './utils/construct-col-defs';

const StyledGrid = styled(AgGridV2)`
  /* overriding custom properties */
  --ag-cell-horizontal-padding: ${({ theme }) => theme.spacing[12]};
  --ag-font-size: ${({ theme }) => theme.fontSize.xs};
  --ag-data-color: ${({ theme: { colors } }) => colors.textTitle2};
  --ag-range-selection-background-color: ${({ theme: { colors } }) => colors.green100};
  --ag-range-selection-border-color: ${({ theme: { colors } }) => colors.green500};
  --ag-range-selection-highlight-color: ${({ theme: { colors } }) => colors.green200};
  --ag-header-column-resize-handle-color: ${({ theme }) => theme.colors.green500};
  --ag-input-focus-border-color: ${({ theme }) => theme.colors.green500};
  --ag-cell-widget-spacing: ${({ theme }) => theme.spacing[4]};
  --ag-selected-row-background-color: ${({ theme }) => theme.colors.bgLight};

  width: 100%;

  .ag-header-cell.ag-column-first {
    border-left: solid 1px ${({ theme: { border } }) => border.color};
  }

  .ag-header-row {
    background-color: ${({ theme }) => theme.colors.gray100};
  }

  .ag-row,
  .ag-header-viewport {
    border-left: solid 1px ${({ theme: { border } }) => border.color};
  }

  .ag-header-cell-text {
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
    font-size: ${({ theme: { fontSize } }) => fontSize.xs};
  }

  .ag-header-container {
    background: ${({ theme }) => theme.colors.bgMedium};
  }

  .ag-header-cell {
    border-top: 1px solid ${({ theme }) => theme.colors.gray200};
    border-bottom: 1.5px solid transparent;
  }
`;

interface Props {
  dimensionGroup: DimensionGroup;
  setGridApi?: (api: GridApi) => void;
}

export const DimensionGroupGrid = ({ dimensionGroup, setGridApi }: Props): ReactElement => {
  const colDefs: ColDef[] = constructColDefs(dimensionGroup);

  return (
    <StyledGrid
      columnDefs={colDefs}
      headerHeight={32}
      rowData={dimensionGroup.rows}
      rowHeight={32}
      suppressContextMenu
      onGridReady={({ api }) => {
        setGridApi?.(api);
      }}
    />
  );
};
