import { Currency, currencyLocaleMap, CurrencySymbolMapping } from 'data/currencies';
import { DataFormattingType } from 'utils/data-formatter/types';
import { DisplayUnitType, type FormatterProps } from '../types';

export const defaultFormattersForMetricType: {
  [key in DataFormattingType | 'default']: (props: FormatterProps) => string;
} = {
  [DataFormattingType.Currency]: ({
    valAsInt,
    currency,
    displayUnit,
    isCompact,
    defaultOptions,
  }: FormatterProps): string => {
    if (displayUnit === DisplayUnitType.Full) {
      const fullValue = Intl.NumberFormat(currencyLocaleMap[currency], {
        ...defaultOptions,
      }).format(valAsInt);

      return `${CurrencySymbolMapping[currency]}${fullValue}`;
    }
    const defaultAutoOptions: Intl.NumberFormatOptions = {
      compactDisplay: isCompact ? 'short' : undefined,
      notation: isCompact ? 'compact' : undefined,
      ...defaultOptions,
    };

    const formattedValue = Intl.NumberFormat(
      [Currency.INR, Currency.AED].includes(currency) ? 'en-US' : currencyLocaleMap[currency],
      {
        currency,
        style: 'currency',
        ...defaultAutoOptions,
      },
    ).format(valAsInt);

    if (currency === Currency.SGD) {
      return `S${formattedValue}`;
    }

    return formattedValue;
  },

  [DataFormattingType.Percentage]: ({
    valAsInt,
    currency,
    displayUnit,
    isCompact,
    defaultOptions,
  }: FormatterProps): string => {
    if (displayUnit === DisplayUnitType.Full) {
      return Intl.NumberFormat(currencyLocaleMap[currency], {
        style: 'percent',
        ...defaultOptions,
      }).format(valAsInt);
    }
    const defaultAutoOptions: Intl.NumberFormatOptions = {
      compactDisplay: isCompact ? 'short' : undefined,
      notation: isCompact ? 'compact' : undefined,
      ...defaultOptions,
    };

    return Intl.NumberFormat('en-US', {
      style: 'percent',
      ...defaultAutoOptions,
    }).format(valAsInt);
  },

  [DataFormattingType.Number]: (props: FormatterProps): string =>
    defaultFormattersForMetricType.default(props),

  default: ({
    valAsInt,
    currency,
    displayUnit,
    isCompact,
    defaultOptions,
  }: FormatterProps): string => {
    if (displayUnit === DisplayUnitType.Full) {
      const fullValue = Intl.NumberFormat(currencyLocaleMap[currency], {
        ...defaultOptions,
      }).format(valAsInt);

      return fullValue;
    }
    const defaultAutoOptions: Intl.NumberFormatOptions = {
      compactDisplay: isCompact ? 'short' : undefined,
      notation: isCompact ? 'compact' : undefined,
      ...defaultOptions,
    };

    return Intl.NumberFormat('en-US', {
      ...defaultAutoOptions,
    }).format(valAsInt);
  },
};
