import { extractValueFromState } from 'components/modules/settings/fivetran/common/util';
import { Spinner } from 'components/ui/atomic-components';
import { type FC, useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

export const IntegrationCallback: FC<React.PropsWithChildren<unknown>> = () => {
  const [{ code, state, realmId, scope }] = useQueryParams({
    code: StringParam,
    state: StringParam,
    realmId: StringParam,
    scope: StringParam,
  });

  useEffect(() => {
    const service = extractValueFromState('service', state);
    const tenantOrigin = extractValueFromState('tenantOrigin', state);
    const reAuthData = extractValueFromState('re-auth', state);
    const region = extractValueFromState('region', state);
    const codeVerifier = extractValueFromState('codeVerifier', state);

    if (reAuthData) {
      const instanceId = Number(reAuthData);

      const gotoUrl = new URL(`${tenantOrigin}/data/connected/${instanceId}`);

      code && gotoUrl.searchParams.append('code', code);
      service && gotoUrl.searchParams.append('service', service);
      realmId && gotoUrl.searchParams.append('realmId', realmId);

      window.open(gotoUrl.href, '_self');
    } else {
      const gotoUrl = new URL(`${tenantOrigin}/data/integrations/`);

      code && gotoUrl.searchParams.append('code', code);
      service && gotoUrl.searchParams.append('service', service);
      realmId && gotoUrl.searchParams.append('realmId', realmId);
      region && gotoUrl.searchParams.append('region', region);
      scope && gotoUrl.searchParams.append('scope', scope);
      codeVerifier && gotoUrl.searchParams.append('codeVerifier', codeVerifier);

      window.open(gotoUrl.href, '_self');
    }
  }, [code, realmId, state, scope]);

  return <Spinner />;
};
