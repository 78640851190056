import { type InputRef } from 'antd';
import { Tree } from 'components/ui/atomic-components';
import { FilterPopupSkeleton } from 'components/ui/filter-popup-skeleton';
import { QueryTableColumnDataType } from 'data/big-query';
import { type ReactElement, useMemo, useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { useGetColAllUniqueValues } from './hooks';
import { WrapperStyles } from './styles';

const { TopText } = WrapperStyles;

interface Props {
  colName: string;
  type: QueryTableColumnDataType;
  onApply: (values: string[]) => void;
}

export const Content = ({ colName, type, onApply }: Props): ReactElement => {
  const inputRef = useRef<InputRef>(null);

  const columnFilters = useListStoreContext((s) => s.columnFilters);

  const { allUniqueOptions, allUniqueValuesMap } = useGetColAllUniqueValues({ colName });

  const [selectedFilterValues, setSelectedFilterValues] = useState(
    [...(columnFilters?.[colName] || [])].filter((val) => allUniqueValuesMap.has(val)),
  );

  const [searchQuery, setSearchQuery] = useState('');

  const filteredOptions = useMemo(
    () =>
      allUniqueOptions.filter((option) => {
        if (type === QueryTableColumnDataType.Numeric && searchQuery !== '') {
          return String(option.title).toLowerCase().startsWith(searchQuery?.toLowerCase());
        }

        return String(option.title)?.toLowerCase().includes(searchQuery?.toLowerCase());
      }),
    [type, allUniqueOptions, searchQuery],
  );

  const isAllItemsChecked = selectedFilterValues.length === allUniqueOptions.length;

  const onSelectAll = () => {
    const updatedFilterValues = new Set(selectedFilterValues);

    filteredOptions.forEach((option) => {
      updatedFilterValues.add(option.value);
    });

    setSelectedFilterValues([...updatedFilterValues]);
  };

  const onClear = () => {
    const updatedFilterValues = new Set(selectedFilterValues);

    filteredOptions.forEach((option) => {
      updatedFilterValues.delete(option.value);
    });

    setSelectedFilterValues([...updatedFilterValues]);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <FilterPopupSkeleton
      searchQuery={searchQuery}
      title={
        <TopText>
          <FormattedMessage id="filter_by_all" />
        </TopText>
      }
      onApply={() => onApply(isAllItemsChecked ? [] : selectedFilterValues)}
      onClear={onClear}
      onSearchChange={(val) => setSearchQuery(val)}
      onSelectAll={onSelectAll}
    >
      <Tree
        checkable
        checkedKeys={selectedFilterValues}
        checkStrictly
        height={200}
        treeData={filteredOptions}
        onCheck={(checked) => {
          if (Array.isArray(checked)) {
            setSelectedFilterValues(checked as string[]);
          } else {
            setSelectedFilterValues(checked.checked as string[]);
          }
        }}
        onSelect={(_, info) => {
          const nodeValue = info.node.key as string;

          if (!selectedFilterValues.includes(nodeValue)) {
            setSelectedFilterValues((prev) => [...prev, nodeValue]);
          } else {
            setSelectedFilterValues((prev) => prev.filter((value) => value !== nodeValue));
          }
        }}
      />
    </FilterPopupSkeleton>
  );
};
