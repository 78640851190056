// eslint-disable-next-line filenames/match-regex
import type OktaAuth from '@okta/okta-auth-js';
import { type QueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { signout, SIGN_OUT_URL, FETCH_CURRENT_USER_URL } from 'data/users';
import { type LDClient } from 'launchdarkly-react-client-sdk';

let isProcessingLast401Response = false;

export const handle401Response = ({
  oktaAuth,
  error,
  queryClient,
  ldClient,
}: {
  error: AxiosError;
  oktaAuth: OktaAuth;
  queryClient: QueryClient;
  ldClient?: LDClient;
}): void => {
  const isSamlLoginWidget = ldClient?.variation('samlLoginWidget');

  if (isSamlLoginWidget) {
    window.location.replace('/login');

    return;
  }

  if (error?.response?.config.url === FETCH_CURRENT_USER_URL) {
    signout(oktaAuth);

    return;
  }

  if (isProcessingLast401Response || error?.response?.config.url === SIGN_OUT_URL) {
    return;
  }

  isProcessingLast401Response = true;

  oktaAuth.tokenManager
    .renew('accessToken')
    .then((token) => {
      if (!token) {
        throw new Error('Unable to fetch auth token');
      }
      oktaAuth.tokenManager.add('accessToken', token);

      isProcessingLast401Response = false;

      return queryClient.refetchQueries({
        type: 'active',
      });
    })
    .catch(() => {
      signout(oktaAuth).finally(() => (isProcessingLast401Response = false));
    });
};
