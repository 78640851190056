import { type VisualQueryFilterRule } from 'data/big-query';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { RuleDisplay } from './rule-display';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
  margin: ${({ theme: { spacing } }) => `0 ${spacing[16]} ${spacing[16]}`};
`;

interface Props {
  selectedDimensions: string[];
  filterRules: VisualQueryFilterRule[];
  setFilterRules?: (rules: VisualQueryFilterRule[]) => void;
  isReadOnly?: boolean;
}

export const RuleList = ({
  selectedDimensions,
  filterRules,
  setFilterRules,
  isReadOnly,
}: Props): ReactElement => {
  const onDeleteRule = (ruleIdx: number) => {
    setFilterRules?.(filterRules.filter((_, idx) => idx !== ruleIdx));
  };

  const onUpdateRule = (ruleIdx: number, rule: VisualQueryFilterRule) => {
    setFilterRules?.(
      filterRules.map((r, idx) => {
        if (idx === ruleIdx) {
          return rule;
        }

        return r;
      }),
    );
  };

  return (
    <Wrapper>
      {filterRules.map((rule, idx) => (
        <RuleDisplay
          key={`${idx}${rule.column}${rule.operator}${JSON.stringify(rule.data)}`}
          isReadOnly={isReadOnly}
          rule={rule}
          selectedDimensions={selectedDimensions}
          onDelete={() => {
            onDeleteRule(idx);
          }}
          onUpdate={(rule: VisualQueryFilterRule) => {
            onUpdateRule(idx, rule);
          }}
        />
      ))}
    </Wrapper>
  );
};
