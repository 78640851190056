const BASE_QUERY_KEY = 'metrics';

export const MetricStoreKeys = {
  QueryKey: BASE_QUERY_KEY,
  AllRecords: (): string[] => [MetricStoreKeys.QueryKey],
};

export enum Method {
  DTQL = 'DTQL',
  AutoCompute = 'AUTO_COMPUTE',
  Manual = 'MANUAL_UPLOAD',
  DTQL2 = 'DTQL_V2',
}
