import { type ListDataTypes } from 'data/modelling/lists';

export interface ListHeaderItem {
  type: ListDataTypes;
  name: string;
  key: React.Key;
}

export enum DimensionPropertyType {
  Number = 'NUMBER',
  Currency = 'CURRENCY',
  Date = 'TIME_PERIOD',
  String = 'STRING',
  Percentage = 'PERCENTAGE',
}

export interface CSVPreviewRowItem {
  values: {
    [key: string]: string;
  };
  errors?: {
    [key: string]: {
      errorMessages: string[];
    };
  };
  rowId?: number;
}

export interface CSVListPreviewData {
  rows?: CSVPreviewRowItem[];
  columnTypeMap: { [key: string]: ListDataTypes };
  dateFormatMap: { [key: string]: string };
  columnOrder: string[];
  csvErrors?: {
    missingColHeaders?: string[];
    newColHeaders?: string[];
  };
}

export interface DimensionProperty {
  displayName: string;
  name: string;
  type: DimensionPropertyType;
  value?: string;
  displayValue?: string;
  isValid?: boolean;
  usedInSubDimension?: boolean;
  fqName?: string;
}

export enum CSVUploadWorkflowStage {
  Default = 'DEFAULT',
  ImportError = 'IMPORT_ERROR',
  ColumnMapping = 'COLUMN_MAPPING',
  Preview = 'PREVIEW',
  PreSave = 'PRESAVE',
}

export type CSVColumnSelection = {
  [key: string]: number;
};

export type CSVColumnMappingItem = {
  propertyName: string;
  csvColumnIndex: number;
};

export type Validatable = {
  isValid: boolean;
  value: string | number | undefined;
  label?: string;
};

export interface ImportErrorDetails {
  missingCols: string[];
  newCols: string[];
}
