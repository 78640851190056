import { type ProcessDataFromClipboardParams } from 'ag-grid-community';
import { notification } from 'components/ui/atomic-components';
import { last } from 'lodash';

// copying on windows excel adds an extra empty string array at the end
// https://github.com/ag-grid/ag-grid/blob/553cc15c45f14c4fad4a53a50392588335cd4e33/enterprise-modules/clipboard/src/clipboard/clipboardService.ts#L212
const removeLastLineIfBlank = (data: string[][]) => {
  const lastLine = last(data);
  const lastLineIsBlank = lastLine && lastLine.length === 1 && lastLine[0] === '';

  if (!lastLineIsBlank) {
    return data;
  }

  // do not remove the last empty line when that is the only line pasted
  if (data.length === 1) {
    return data;
  }

  // remove last empty [''] item
  data.splice(data.length - 1, 1);

  return data;
};

// remove once we have support for bulk copy paste in lists
export const processListDataFromClipboard = ({
  data,
}: ProcessDataFromClipboardParams): string[][] | null => {
  const processedData = removeLastLineIfBlank(data);

  if (processedData.length > 1) {
    notification.info({ message: 'Pasting only supported for a single row.' });

    return null;
  }

  return processedData;
};
