import { ReactComponent as ListIcon } from 'assets/bullet-list.svg';
import { ReactComponent as DatasetIcon } from 'assets/table.svg';
import { IconShell, IconText, Tooltip } from 'components/ui/atomic-components';
import { DatasetNamePrefix } from 'data/integrations/fivetran/constants/constants';
import {
  Field,
  FieldToSearchAttribute,
  type Filter,
  Operator,
  type SearchItem,
  SearchItemType,
  type SearchRequest,
} from 'data/search';
import { isEmpty } from 'lodash';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontS, FontXxs, FontXs, SingleLineEllipsis } from 'styles/typography';
import { formatName } from 'utils/data-formatter';
import { getColumnNameFromRawDimensionName, getDetailedTableName } from 'utils/dimensions';
import { type DimensionSelectOption } from '.';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  width: 100%;
`;

const ColumnName = styled.div`
  ${SingleLineEllipsis};
  ${FontXs};

  max-width: 234px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
  margin: ${({ theme }) => theme.spacing[8]} 0;
  width: 100%;
`;

const OptionTitle = styled.div`
  ${SingleLineEllipsis};
  ${FontS};

  color: ${({ theme }) => theme.colors.textTitle1};
  max-width: 234px;
`;

const OptionSubTitle = styled.div`
  ${SingleLineEllipsis};

  ${FontXs}

  color: ${({ theme }) => theme.colors.gray400};
  max-width: 234px;
`;

const GroupOptionTitle = styled.div`
  ${FontXxs}

  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-transform: none;
  color: ${({ theme }) => theme.colors.textLabel};
  margin-left: ${({ theme }) => `-${theme.spacing[4]}`};
`;

const getMetaInfoFromDimension = (dimension: {
  label: string;
  value: string;
  parentSourceDisplayName: string;
}) => {
  const dimensionName = dimension?.label || '';
  const isListColumn = !dimension?.value?.startsWith(DatasetNamePrefix);
  const columnName = getColumnNameFromRawDimensionName(dimensionName);
  const tooltipText = `${formatName(dimension.parentSourceDisplayName)} > ${columnName}`;
  const icon = isListColumn ? ListIcon : DatasetIcon;
  const tooltip = <IconText color="gray500" icon={icon} text={tooltipText} />;

  return {
    columnName,
    tooltip,
    icon,
  };
};

export const renderDimensionValue = (dimension: {
  label: string;
  value: string;
  parentSourceDisplayName: string;
}): ReactNode => {
  const { tooltip, icon, columnName } = getMetaInfoFromDimension(dimension);

  return (
    <Tooltip spacing="compact" title={tooltip}>
      <Wrapper>
        <IconShell color="gray500" icon={icon} size="xs" />
        <ColumnName>{columnName}</ColumnName>
      </Wrapper>
    </Tooltip>
  );
};

export const renderDimensionOption = (dimension: {
  label: string;
  value: string;
  parentSourceDisplayName: string;
}): ReactNode => {
  const { tooltip, columnName } = getMetaInfoFromDimension(dimension);

  return (
    <Tooltip placement="right" title={tooltip}>
      <OptionWrapper>
        <OptionTitle>{columnName}</OptionTitle>
        <OptionSubTitle>{dimension?.parentSourceDisplayName || ''}</OptionSubTitle>
      </OptionWrapper>
    </Tooltip>
  );
};

export const dimensionSearchRequest = ({
  value,
  query,
  preventDimensionGroupSearch,
}: {
  value?: DimensionSelectOption[];
  query?: string;
  preventDimensionGroupSearch?: boolean;
}): Partial<SearchRequest> => {
  const filters: Filter[] = [
    {
      field: Field.Type,
      value: SearchItemType.DatasetColumn,
    },
  ];

  if (value?.[0]) {
    const tableName = value?.[0]?.value?.split('.').at(-2).split('-').at(-1) || '';

    if (tableName) {
      filters.push({
        field: Field.TableName,
        value: tableName,
      });
    }
  }

  if (preventDimensionGroupSearch) {
    filters.push({
      field: Field.ColumnSource,
      value: SearchItemType.DimensionGroup,
      operator: Operator.NOT,
    });
  }

  return {
    query,
    filters,
    fieldsToBeSearched: [
      { attribute: FieldToSearchAttribute.DisplayName, weight: 1.0 },
      { attribute: FieldToSearchAttribute.TableDisplayName, weight: 0.1 },
    ],
  };
};

export const dimensionOptionsFromSearchItems = (
  searchItems: SearchItem[],
): DimensionSelectOption[] => {
  return searchItems.map((item) => ({
    value: item.fullDimensionName,
    label: formatName(item.name),
    parentSourceDisplayName: item.tableDisplayName || item.parentDisplayName,
    columnType: item.columnType,
    name: item.name,
    tableDisplayName: item.tableDisplayName,
  }));
};

export const createDimensionOptions = ({
  value,
  items,
  groupRelatedResults,
}: {
  value?: DimensionSelectOption[];
  items: SearchItem[];
  groupRelatedResults?: boolean;
}): DimensionSelectOption[] => {
  const relatedTables = !isEmpty(value)
    ? new Set(value?.map((v) => getDetailedTableName(v.value)))
    : undefined;

  const options: DimensionSelectOption[] = [];

  if (!groupRelatedResults || !relatedTables) {
    options.push(...dimensionOptionsFromSearchItems(items));
  } else {
    const relatedItems: SearchItem[] = [];
    const otherItems: SearchItem[] = [];

    items.forEach((item) => {
      if (relatedTables.has(getDetailedTableName(item.fullDimensionName))) {
        relatedItems.push(item);
      } else {
        otherItems.push(item);
      }
    });

    if (!isEmpty(relatedItems)) {
      options.push({
        label: (
          <GroupOptionTitle>
            <FormattedMessage id="related" />
          </GroupOptionTitle>
        ),
        title: 'related',
        options: dimensionOptionsFromSearchItems(relatedItems),
      });
    }

    if (!isEmpty(otherItems)) {
      options.push({
        label: (
          <GroupOptionTitle>
            <FormattedMessage id="others" />
          </GroupOptionTitle>
        ),
        title: 'others',
        options: dimensionOptionsFromSearchItems(otherItems),
      });
    }
  }

  return options;
};
