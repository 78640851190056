import { AsyncSwitcher } from 'components/ui/async-switcher';
import { useLists } from 'data/modelling/lists';
import { useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { Directory } from './directory';
import { ListsEmpty } from './lists-empty';
import { SearchSection } from './search-section';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${({ theme: { spacing } }) => `${spacing[16]} ${spacing[8]}`};
  border-right: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  gap: ${({ theme }) => theme.spacing[12]};
`;

export const LeftNav = (): ReactElement => {
  const listsQuery = useLists({ includeDimensionGroups: true });

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Wrapper>
      <SearchSection searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      <AsyncSwitcher {...listsQuery} components={{ Empty: ListsEmpty }}>
        {(data) => <Directory lists={data} searchQuery={searchQuery} />}
      </AsyncSwitcher>
    </Wrapper>
  );
};
