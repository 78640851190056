import { type TextAreaProps } from 'antd/es/input';
import {
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
  css,
} from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { StyledMargins } from 'styles/styled-atoms';
import { FontM, FontS, FontXl, FontXs } from 'styles/typography';
import { type InputProps } from './index';

export const inputStyles = ({
  theme,
}: InputProps & { theme: DefaultTheme }): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    ${StyledMargins};

    /* stylelint-disable no-duplicate-selectors */
    &.ant-input-status-error:not(.ant-input-disabled):focus,
    &.ant-input-status-error:not(.ant-input-disabled):focus-within,
    :focus,
    :focus-within {
      box-shadow: none;
    }

    .ant-input-clear-icon {
      display: flex;
    }

    ${switchProp(prop('size', 'medium'), {
      extraSmall: css`
        ${FontXs};

        height: 24px;
        padding: ${theme.spacing[2]} ${theme.spacing[8]};
        border-radius: ${theme.borderRadius.s};
        font-size: ${theme.fontSize.xs};
      `,
      small: css`
        ${FontXs};

        height: 28px;
        padding: ${theme.spacing[4]} ${theme.spacing[8]};
        border-radius: ${theme.borderRadius.s};
        font-size: ${theme.fontSize.xs};
      `,
      medium: css`
        ${FontS};

        height: 32px;
        padding: ${theme.spacing[6]} ${theme.spacing[8]};
        border-radius: ${theme.borderRadius.m};
        font-size: ${theme.fontSize.s};
      `,
    })};

    ${switchProp(prop('$inputType', 'outline'), {
      outline: css`
        border: 1px solid ${theme.colors.outlineDefault};

        :hover:not(:focus):not(.ant-input-disabled) {
          border: 1px solid ${theme.colors.gray400};
        }

        &.ant-input-disabled {
          background: ${theme.colors.bgMedium};
        }
      `,
      ghost: css`
        border-color: transparent;
        background: ${theme.colors.bgMedium};

        :hover:not(:focus):not(.ant-input-disabled) {
          border-color: transparent;
          background: ${theme.colors.bgMedium};
        }

        :focus:not(.ant-input-disabled),
        :focus-within:not(.ant-input-disabled) {
          background: ${theme.colors.bgMedium};
          border-color: transparent;
        }

        &.ant-input-disabled {
          border-color: transparent;
          background: ${theme.colors.bgMedium};
        }
      `,
      ghostText: css`
        color: ${theme.colors.textTitle1};
        padding: ${theme.spacing[2]} ${theme.spacing[4]};
        border-color: transparent;

        :hover:not(:focus):not(.ant-input-disabled) {
          border-color: transparent;
          background: ${theme.colors.bgMedium};
        }

        :focus:not(.ant-input-disabled),
        :focus-within:not(.ant-input-disabled) {
          border-color: transparent;
          background: ${theme.colors.bgMedium};
        }

        ${switchProp(prop('size', 'medium'), {
          extraSmall: css`
            ${FontS};

            height: 24px;
            border-radius: ${({ theme }) => theme.borderRadius.xs};
          `,
          small: css`
            ${FontM};

            height: 28px;
            font-weight: ${theme.fontWeight.medium};
            border-radius: ${({ theme }) => theme.borderRadius.s};
          `,
          medium: css`
            ${FontXl};

            height: 36px;
            font-weight: ${theme.fontWeight.medium};
            border-radius: ${({ theme }) => theme.borderRadius.m};
          `,
        })};
      `,
    })};
  `;
};

export const textAreaStyles = ({
  theme,
  ...props
}: TextAreaProps & { theme: DefaultTheme }): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  const { bordered = true } = props;

  return css`
    ${StyledMargins};

    &:focus,
    &:hover {
      background-color: ${bordered ? theme.colors.gray00 : theme.colors.gray100};
    }

    padding: ${theme.spacing[4]} ${theme.spacing[8]};
    border-radius: ${theme.borderRadius.s};
    border: 1px solid ${bordered ? theme.colors.gray200 : 'none'};
    box-shadow: ${bordered ? theme.shadow.softMicro : 'none'};
    height: ${bordered ? '30px' : '28px'};
    font-size: ${bordered ? theme.fontSize.s : theme.fontSize.m};
    line-height: ${bordered ? theme.lineHeight.s : theme.lineHeight.m};
    font-weight: ${bordered ? theme.fontWeight.regular : theme.fontWeight.medium};
  `;
};
