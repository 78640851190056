import axios, { type AxiosRequestConfig } from 'axios';
import { type IntegrationConnectionItem } from 'data/integrations/fivetran';
import { type MetricDefinitionRequest } from 'data/metrics';
import { stringify } from 'query-string';
import {
  type MetricBuilder,
  type MetricDTQLQuery,
  type MetricPreview,
  type MonitoringMetric,
} from './types';

export const MetricBuilderApi = {
  getAllMonitoringMetrics: (): Promise<MonitoringMetric[]> =>
    axios.get('v1/monitoring-metrics').then(({ data }) => data),

  searchMonitoringMetrics: (query?: string): Promise<MonitoringMetric[]> =>
    axios
      .get('v1/monitoring-metrics/search', {
        params: { q: query?.trim() || '' },
        paramsSerializer: (params) => stringify(params),
      })
      .then(({ data }) => data),

  getMetric: (metricName: string): Promise<MetricBuilder> =>
    axios.get(`v1/monitoring-metrics/${metricName}`).then(({ data }) => data),

  createMetric: (options: {
    metric: Partial<MetricDefinitionRequest>;
    queryConfig: MetricDTQLQuery | null;
  }): Promise<MetricBuilder> =>
    axios.post('v1/monitoring-metrics', options).then(({ data }) => data),

  createMetricBySql: (options: {
    metric: Partial<MetricDefinitionRequest>;
    query: string;
    queryConfig: Partial<MetricDTQLQuery>;
  }): Promise<MetricBuilder> =>
    axios.post('v1/monitoring-metrics/sql', options).then(({ data }) => data),

  updateMetric: (options: {
    metric: Partial<MetricDefinitionRequest>;
    queryConfig: MetricDTQLQuery | null;
  }): Promise<MetricBuilder> =>
    axios.post(`v1/monitoring-metrics/${options.metric.name}`, options).then(({ data }) => data),

  updateMetricBySql: (options: {
    metric: Partial<MetricDefinitionRequest>;
    query: string;
    queryConfig: Partial<MetricDTQLQuery>;
  }): Promise<MetricBuilder> =>
    axios
      .post(`v1/monitoring-metrics/${options.metric.name}/sql`, options)
      .then(({ data }) => data),

  getMetricPreview: (options: {
    startDate: string;
    endDate: string;
    granularity: string;
    metric: Partial<MetricDefinitionRequest>;
    queryConfig: MetricDTQLQuery | null;
  }): Promise<MetricPreview> =>
    axios.post('v1/monitoring-metrics/preview', options).then(({ data }) => data),

  getMetricSqlPreview: (options: { query: string }): Promise<MetricPreview> =>
    axios.post('v1/monitoring-metrics/sql/preview', options).then(({ data }) => data),

  linkDual: (metric: Partial<MetricDefinitionRequest>): Promise<MonitoringMetric> =>
    axios.post(`v1/monitoring-metrics/${metric.name}/link`, metric).then(({ data }) => data),

  deleteDual: (metricName: string): Promise<void> =>
    axios.delete(`v1/monitoring-metrics/${metricName}/link`).then(({ data }) => data),

  getConnectedIntegrations: (config?: AxiosRequestConfig): Promise<IntegrationConnectionItem[]> =>
    axios.get('v1/monitoring-metrics/connection-instances', config).then(({ data }) => data.data),
};
