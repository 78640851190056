import { Button, Divider, Input } from 'components/ui/atomic-components';
import styled from 'styled-components';
import { FontS, FontXs, FontXxs } from 'styles/typography';

export default {
  StyledInputWrapper: styled.div`
    display: flex;
    gap: ${({ theme: { spacing } }) => spacing[8]};
    flex-flow: column;
    padding: ${({ theme: { spacing } }) => spacing[8]};
  `,
  Title: styled.span`
    ${FontXs};

    color: ${({ theme }) => theme.colors.textTitle2};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  StyledDivider: styled(Divider)`
    margin: ${({ theme }) => `${theme.spacing[4]} 0`};
  `,
  SelectAllWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) => `0 ${theme.spacing[8]}`};
    margin: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[2]}`};
  `,
  Footer: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: ${({ theme: { spacing } }) => `${spacing[8]}`};
  `,
  ListWrapper: styled.div`
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing[4]};

    .ant-tree-treenode .ant-tree-node-content-wrapper {
      margin-top: -3px;
    }
  `,
  Wrapper: styled.div`
    display: flex;
    flex-flow: column;
    width: 220px;
  `,
  StyledInput: styled(Input)`
    ${FontS};

    padding: ${({ theme }) => theme.spacing[8]};
  `,
  StyledButton: styled(Button)`
    ${FontXs};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding: ${({ theme: { spacing } }) =>
      `${spacing[4]} ${spacing[12]} ${spacing[4]} ${spacing[12]}`};
  `,

  EmptyWrapper: styled.div`
    ${FontXxs};

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 160px;
    gap: ${({ theme }) => theme.spacing[8]};
    color: ${({ theme }) => theme.colors.textPlaceholder};
  `,
};
