import { TimeDimensionName } from 'data/dimension';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { isUndefined } from 'lodash';
import { type RawData } from '../types';
import { constructTimeDimKey } from './generate-data-lookup-key';

const IGNORE_KEY_LIST = ['period', 'result', 'metric'];

export const generateKeyPath = (dataEntry: RawData, dimensionOrder: string[]): string | null => {
  const keyPath: string[] = [];

  for (const dimensionName of dimensionOrder) {
    if (dimensionName === PIVOT_DIMENSION_METRIC_ROW_NAME) {
      dataEntry.metric && keyPath.push(`${PIVOT_DIMENSION_METRIC_ROW_NAME}:${dataEntry.metric}`);
    } else if (dimensionName === TimeDimensionName) {
      if (!dataEntry[dimensionName]) {
        continue;
      }

      const timeKey = `${dataEntry[dimensionName]}`;

      // invalid data entry as the time-period combination does not match with any of the entries in periodRanges
      if (!timeKey) {
        return null;
      }

      const timeKeyWithGranularity = constructTimeDimKey(timeKey, dataEntry?.period as string);

      keyPath.push(`${TimeDimensionName}:${timeKeyWithGranularity}`);
    } else {
      if (!isUndefined(dataEntry[dimensionName])) {
        keyPath.push(`${dimensionName}:${dataEntry[dimensionName]}`);
      }
    }
  }

  const dimOrderSet = new Set(dimensionOrder);

  // In order to make a unique key for the data record item,
  // we attach the remaining key value pairs in the end of it
  // Otherwise there will be duplicate keys, won't be able to map correctly
  Object.keys(dataEntry).forEach((key) => {
    if (!dimOrderSet.has(key) && !IGNORE_KEY_LIST.includes(key)) {
      keyPath.push(`${key}:${dataEntry[key]}`);
    }
  });

  return keyPath.join('~');
};

export const generateDataLookupMap = ({
  data,
  rowsOrder,
  columnsOrder,
}: {
  data: RawData[];
  rowsOrder: string[];
  columnsOrder: string[];
}): Record<string, number | null> => {
  const allDimensions = [...rowsOrder, ...columnsOrder];

  const entry: Record<string, number | null> = {};

  for (const dataPoint of data) {
    const key = generateKeyPath(dataPoint, allDimensions);

    // generateKeyPath returns null if the data entry is invalid
    if (key !== null) {
      entry[key] = dataPoint.result as number | null;
    }
  }

  return entry;
};
