import { useQueryClient } from '@tanstack/react-query';
import { ConfirmationModal } from 'components/ui/atomic-components';
import { type List } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import { useAccessControlMutation } from '../hooks/mutation';

export const RemoveAccessControlModal = ({
  visible,
  onCancel,
}: {
  visible: boolean;
  onCancel: () => void;
}): ReactElement => {
  const queryClient = useQueryClient();

  const listId = useListStoreContext((s) => s.id);
  const listName = useListStoreContext((s) => s.name);

  const { isLoading: isRemovingAccessControl, mutateAsync: setAccessControl } =
    useAccessControlMutation();

  const onOk = () => {
    setAccessControl({
      listId,
      listName,
      isAccessControlled: false,
      roleIds: [],
    }).then(() => {
      onCancel();

      queryClient.setQueryData<List>(['lists', listId], (oldList) => {
        if (oldList) {
          return {
            ...oldList,
            accessControlled: false,
          };
        }
      });

      queryClient.setQueryData<List[]>(['lists'], (existingLists) =>
        (existingLists || []).map((list) => {
          if (list.id === listId) {
            return {
              ...list,
              accessControlled: false,
            };
          }

          return list;
        }),
      );
    });
  };

  return (
    <ConfirmationModal
      confirmLoading={isRemovingAccessControl}
      okText={<FormattedMessage id="dimensions.remove_access_modal.ok_text" />}
      open={visible}
      title={<FormattedMessage id="dimensions.remove_access_modal.title" />}
      type="delete"
      onCancel={onCancel}
      onOk={onOk}
    >
      <FormattedMessage
        id="dimensions.remove_access_modal.message"
        values={{ dimension: formatName(listName) }}
      />
    </ConfirmationModal>
  );
};
