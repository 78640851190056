import { type ReactNode, type PropsWithChildren, type ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { type defaultTheme } from 'styles/theme';
import { FontS } from 'styles/typography';

type ThemeColors = keyof typeof defaultTheme.colors;

const Wrapper = styled.div<{
  bgColor: ThemeColors;
  borderColor: ThemeColors;
  textColor: ThemeColors;
  size: 'small' | 'medium';
}>`
  ${FontS};

  display: flex;
  align-items: center;
  background: ${({ theme, bgColor }) => theme.colors[bgColor]};
  border: 1px solid ${({ theme, borderColor }) => theme.colors[borderColor]};
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  border-radius: ${({ theme }) => theme.borderRadius.xs};

  ${(props) =>
    props.size === 'small' &&
    css`
      padding: ${({ theme: { spacing } }) => `${spacing[0]} ${spacing[4]}`};
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      padding: ${({ theme: { spacing } }) => `${spacing[2]} ${spacing[4]}`};
    `}
`;

const IconWrapper = styled.div<{ size: 'small' | 'medium' }>`
  display: flex;
  margin-right: ${({ theme: { spacing }, size }) => (size === 'medium' ? spacing[2] : spacing[4])};
`;

interface ChipProps {
  bgColor: ThemeColors;
  textColor: ThemeColors;
  borderColor: ThemeColors;
  icon?: ReactNode;
  size?: 'small' | 'medium';
}

export const Chips = ({
  bgColor,
  borderColor,
  textColor,
  size = 'medium',
  icon,
  children,
}: PropsWithChildren<ChipProps>): ReactElement => {
  return (
    <Wrapper bgColor={bgColor} borderColor={borderColor} size={size} textColor={textColor}>
      {icon && <IconWrapper size={size}>{icon}</IconWrapper>}

      {children}
    </Wrapper>
  );
};
