import { AgGridReact, type AgGridReactProps } from 'ag-grid-react';
import styled from 'styled-components';
import { Caption } from 'styles/typography';
import { getAssetUrl } from 'utils';
import { CustomColumnGroupHeader } from './custom-renderers/column-group-header';
import { getCustomThemeStyles } from './custom-themes';

/**
 * @deprecated non porsche component
 */
export const AgGrid = styled(AgGridReact).attrs<AgGridReactProps>((props) => ({
  className: 'ag-theme-alpine',

  animateRows: false,
  components: {
    agColumnGroupHeader: CustomColumnGroupHeader,
  },
  groupHeaderHeight: 32,
  headerHeight: 48,
  rowHeight: 40,

  icons: {
    groupExpanded: `<img src="${getAssetUrl('v2/chevron-down.svg')}" />`,
    groupContracted: `<img src="${getAssetUrl('v2/chevron-right.svg')}" />`,
    columnDrag: `<img src="${getAssetUrl('ic_drag_handle.svg')}" />`,
    rowDrag: `<img src="${getAssetUrl('ic_drag_handle.svg')}" />`,
    columnMoveMove: `<img src="${getAssetUrl('ic_drag_handle.svg')}" />`,
  },

  allowContextMenuWithControlKey: true,
  suppressGroupRowsSticky: true,

  ...props,
}))`
  font-family: inherit;

  --ag-alpine-active-color: ${({ theme: { colors } }) => colors.blue60};
  --ag-background-color: ${({ theme: { colors } }) => colors.white};
  --ag-header-background-color: ${({ theme: { colors } }) => colors.white};
  --ag-subheader-background-color: ${({ theme: { colors } }) => colors.white};
  --ag-odd-row-background-color: ${({ theme: { colors } }) => colors.white};
  --ag-data-color: ${({ theme: { colors } }) => colors.black900};
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-color: ${({ theme: { border } }) => border.color};
  --ag-row-hover-color: ${({ theme: { colors } }) => colors.black10};
  --ag-popup-shadow: ${({ theme: { boxShadow } }) => boxShadow[12]};
  --ag-cell-horizontal-padding: ${({ theme }) => theme.spacing[8]};
  --ag-header-foreground-color: ${({ theme }) => theme.colors.black900};
  --ag-foreground-color: ${({ theme }) => theme.colors.black900};
  --ag-secondary-foreground-color: ${({ theme }) => theme.colors.black900};
  --ag-control-panel-background-color: ${({ theme: { colors } }) => colors.white};
  --ag-border-color: ${({ theme: { colors } }) => colors.black40};
  --ag-range-selection-background-color: ${({ theme: { colors } }) => colors.black40};
  --ag-range-selection-border-color: ${({ theme: { colors } }) => colors.blue80};
  --ag-border-radius: ${({ theme: { border } }) => border.radius.md};
  --ag-header-cell-hover-background-color: inherit;
  --ag-range-selection-highlight-color: ${({ theme: { colors } }) => colors.blue30};
  --ag-cell-widget-spacing: ${({ theme: { spacing } }) => spacing[8]};

  .ag-overlay {
    z-index: ${({ theme }) => theme.zIndex[10]};
  }

  .ag-root-wrapper {
    border: none;
    font-feature-settings: 'tnum' 1;
  }

  .ag-grid-read-only {
    background: ${({ theme }) => theme.colors.black20};
    cursor: not-allowed;
    font-weight: normal;

    .ag-fill-handle {
      display: none;
    }
  }

  .ag-row-hover {
    .info-icon-popover-wrapper {
      display: flex;
    }
  }

  .ag-cell {
    &-red {
      color: ${({ theme }) => theme.colors.red70};
    }

    &-green {
      color: ${({ theme }) => theme.colors.green70};
    }

    &-orange {
      color: ${({ theme }) => theme.colors.orange70};
    }

    &-yellow {
      color: ${({ theme }) => theme.colors.yellow70};
    }

    &-indigo {
      color: ${({ theme }) => theme.colors.indigo80};
    }

    &.ag-cell-focus {
      .ag-fill-handle {
        cursor: crosshair;
      }
    }

    &.ag-selection-fill-top,
    &.ag-selection-fill-bottom,
    &.ag-selection-fill-right,
    &.ag-selection-fill-left {
      cursor: crosshair;
    }
  }

  &&& {
    .ag-cell.ag-cell-range-selected {
      border-width: 1.5px;
    }
  }

  .ag-group-value,
  .ag-cell-value {
    width: 100%;
    height: 100%;
  }

  .ag-row-group-expanded {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }

  .ag-header-container {
    white-space: normal;
  }

  .ag-header {
    border-bottom: 0;
  }

  .ag-header-row {
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.border.color};
  }

  .ag-header-cell-text {
    ${Caption};

    font-weight: ${({ theme }) => theme.fontWeight.normal};
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  [col-id='rowNum'] {
    color: ${({ theme }) => theme.colors.black60};
  }

  .ag-header-group-cell {
    border-top: solid 1px ${({ theme }) => theme.border.color};
  }

  && .ag-header-cell,
  .ag-header-group-cell {
    --ag-cell-horizontal-padding: ${({ theme }) => theme.spacing[12]};

    &.ag-header-group-cell-with-group {
      --ag-cell-horizontal-padding: 0;
    }
  }

  &&& .ag-header-cell {
    border-top: none;
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container {
    box-shadow: var(--ag-left-pinned-shadow, 'none');
    z-index: 1;
  }

  .ag-pinned-left-header,
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell),
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
    border-right-color: ${({ theme }) => theme.border.color};
  }

  && {
    .ag-layout-auto-height .ag-center-cols-clipper {
      min-height: 50px;
    }

    .ag-cell.ag-selection-fill-top,
    .ag-cell.ag-selection-fill-bottom,
    .ag-cell.ag-selection-fill-left,
    .ag-cell.ag-selection-fill-right {
      --ag-range-selection-border-color: ${({ theme }) => theme.colors.blue40};

      border-style: solid;
    }
  }

  && .ag-cell-focus {
    &,
    &.ag-cell,
    &.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
      border: 1.5px solid var(--ag-border-active-color, ${({ theme }) => theme.colors.blue80});
    }

    &.ag-has-open-conversations {
      --ag-border-active-color: ${({ theme: { colors } }) => colors.yellow70};
    }

    &.ag-all-resolved-conversations {
      --ag-border-active-color: ${({ theme: { colors } }) => colors.black60};
    }
  }

  .ag-header-group-cell[aria-expanded='true'] {
    --ag-header-cell-hover-background-color: ${({ theme }) => theme.colors.blue10};

    background: ${({ theme }) => theme.colors.blue10};
    border-top-color: ${({ theme }) => theme.colors.blue60};
  }

  &&& .ag-cell-inline-editing {
    border-color: ${({ theme }) => theme.colors.blue60} !important;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    padding: 0;
    background: ${({ theme }) => theme.colors.white};

    &:focus {
      padding: 0 ${({ theme: { spacing } }) => spacing[16]};
    }

    input {
      border: none;
      box-shadow: none;
      padding: 0 ${({ theme }) => theme.spacing[16]};

      &:focus {
        border: transparent;
      }
    }

    .ant-picker {
      border: none;
      width: 100%;

      input {
        padding: 0;
        text-align: left;
      }
    }
  }

  .ag-grid-editable {
    cursor: cell;
  }

  .ag-grid-col-group-rollup {
    background: ${({ theme: { colors } }) => colors.black10};

    &.ag-header-cell {
      border-bottom: 1px solid ${({ theme: { border } }) => border.color};
    }

    &.ag-cell.ag-selection-fill-top,
    &.ag-cell.ag-selection-fill-bottom,
    &.ag-cell.ag-selection-fill-right,
    &.ag-cell.ag-selection-fill-left {
      --ag-range-selection-border-color: transparent;
    }
  }

  .ag-full-width-row.ag-row-last {
    z-index: ${({ theme }) => theme.zIndex[10]};
  }

  .ag-popup {
    z-index: ${({ theme }) => theme.zIndex[9999]};
  }

  /** Context menu UI */
  & .ag-menu {
    box-shadow: ${({ theme: { boxShadow } }) => boxShadow[12]};
    font-family: ${({ theme }) => theme.font.text};
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme: { colors } }) => colors.black40};

    .ag-menu-list {
      padding: ${({ theme: { spacing } }) => spacing[4]} 0;

      .ag-menu-option {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: ${({ theme: { spacing } }) => spacing[8]};
        padding: ${({ theme: { spacing } }) => spacing[8]};

        &-disabled {
          cursor: default;
        }

        .ag-menu-option-part {
          padding: 0;
        }

        .ag-menu-option-icon {
          height: ${({ theme: { iconSize } }) => iconSize[16]};
          width: ${({ theme: { iconSize } }) => iconSize[16]};

          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  ${({ className }) => getCustomThemeStyles(className)}
` as unknown as typeof AgGridReact;
