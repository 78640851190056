import { ReactComponent as FilterIcon } from 'assets/v2/filter.svg';
import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { Button, IconShell, Popover } from 'components/ui/atomic-components';
import { type VisualQueryFilterRule } from 'data/big-query';
import { isEmpty } from 'lodash';
import { type ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { isFilterDateSelectorOpen } from '../filter-rule-value-selector-v2/utils';
import { RuleList } from './filter-rule-list';
import { FilterRuleSelector } from './filter-rule-selector';

const Wrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme: { spacing } }) => `0 ${spacing[16]}`};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const Title = styled.span`
  ${FontXs};

  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

interface Props {
  selectedDimensions: string[];
  value?: VisualQueryFilterRule[];
  onChange?: (rules?: VisualQueryFilterRule[]) => void;
  className?: string;
  isReadOnly?: boolean;
}

export const ColumnBasedFilters = ({
  selectedDimensions,
  value,
  onChange,
  className,
  isReadOnly,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState<VisualQueryFilterRule | undefined>();

  const onAddFilter = useCallback(() => {
    if (selectedRule) {
      // add new filter rule
      onChange?.([...(value || []), { ...selectedRule }]);
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectedRule]);

  return (
    <div className={className}>
      <Wrapper className="column-based-filters-header-item">
        <TitleWrapper>
          <IconShell color="textBody2" icon={FilterIcon} />
          <Title>
            <FormattedMessage id="filters" />
          </Title>
        </TitleWrapper>

        {!isReadOnly && (
          <Popover
            afterOpenChange={(open) => {
              if (!open) {
                setSelectedRule(undefined);
              }
            }}
            confirmDisabled={!selectedRule}
            confirmText={<FormattedMessage id="add" />}
            content={
              <FilterRuleSelector
                selectedDimensions={selectedDimensions}
                setSelectedRule={(rule) => setSelectedRule(rule)}
              />
            }
            destroyTooltipOnHide
            open={isOpen}
            placement="topRight"
            zIndex={1030}
            onConfirm={onAddFilter}
            onOpenChange={(v) => {
              if (isFilterDateSelectorOpen()) {
                return;
              }
              setIsOpen(v);
            }}
          >
            <Button
              disabled={isEmpty(selectedDimensions)}
              icon={<IconShell color="iconDefault" icon={PlusIcon} />}
              size="small"
              type="text"
            />
          </Popover>
        )}
      </Wrapper>

      {!!value?.length && (
        <RuleList
          filterRules={value}
          isReadOnly={isReadOnly}
          selectedDimensions={selectedDimensions}
          setFilterRules={onChange}
        />
      )}
    </div>
  );
};
