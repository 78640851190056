import { type GridApi } from 'ag-grid-community';
import { type ListConfig } from 'data/modelling/lists';
import { generateRandomNumber } from 'utils';
import { type ListGridRow } from '../../types';

interface Props {
  api?: GridApi;
  addNewRow: (row: ListGridRow) => void;
  config: ListConfig;
  rows: ListGridRow[];
}

export const addRowToGrid = ({ api, addNewRow, config, rows }: Props): void => {
  api?.clearRangeSelection();

  const randomId = generateRandomNumber();

  const row = {
    id: randomId,
    data: {},
    position: (rows.at(-1)?.position ?? 0) + 1,
  } as ListGridRow;

  const lastRow = rows.at(-1);
  let lastRowIndex = rows.length - 1;

  if (!lastRow || lastRow?.backendId) {
    addNewRow(row);
    lastRowIndex += 1;
  }

  setTimeout(() => {
    api?.ensureIndexVisible(lastRowIndex);
    api?.setFocusedCell(lastRowIndex, config.columnOrder[0]);
  }, 10);
};
