import { useQueryClient } from '@tanstack/react-query';
import { ReactComponent as KeyIcon } from 'assets/v2/key.svg';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { Form, IconText, Modal, Select } from 'components/ui/atomic-components';
import { type List } from 'data/modelling/lists';
import { isEmpty } from 'lodash';
import { type ReactElement, type Dispatch, type SetStateAction, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import styles from './styles';
import { columnOptionMapper } from './utils';

const { ModalContent, Label } = styles;

interface FormProps {
  uniqueKeyColumns: string[];
  uniqueKey: string;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const UniqueKeyModal = ({ open, setOpen }: Props): ReactElement => {
  const queryClient = useQueryClient();

  const config = useListStoreContext((s) => s.config);
  const listId = useListStoreContext((s) => s.id);

  const { updateListMutation } = useListMutations();

  const [form] = Form.useForm<FormProps>();

  const uniqueKeyColumns = Form.useWatch('uniqueKeyColumns', form) || [];

  const isUniqueKeySet = !isEmpty(config.uniqueKeyColumns);

  const handleUniqueKeySave = async () => {
    const data = await form.validateFields();

    const updatedList = {
      config: {
        ...config,
        uniqueKeyColumns: data.uniqueKeyColumns,
      },
    } as List;

    await updateListMutation.mutateAsync({ id: listId, list: updatedList });

    queryClient.invalidateQueries(['lists', listId]);

    setOpen(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      uniqueKeyColumns: config.uniqueKeyColumns || [],
    });
  }, [config, form]);

  const uniqueKeyOptions = useMemo(() => {
    const isCompositeUniqueKey = !isEmpty(config.uniqueKeyColumns);

    const listColumns = config.columnOrder.filter((col) => {
      if (isCompositeUniqueKey) {
        return col !== config.uniqueKey;
      }

      return true;
    });
    const uniqueKeyOptions = listColumns.map(columnOptionMapper);

    uniqueKeyOptions.sort((o1, o2) => o1.label.localeCompare(o2.label));

    return uniqueKeyOptions;
  }, [config.columnOrder, config.uniqueKey, config.uniqueKeyColumns]);

  return (
    <Modal
      afterClose={() => form.resetFields()}
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={updateListMutation.isLoading}
      destroyOnClose
      okText={<FormattedMessage id="apply" />}
      open={open}
      title={
        <IconText
          icon={KeyIcon}
          text={
            isUniqueKeySet ? (
              <FormattedMessage id="lists.edit_unique_key" />
            ) : (
              <FormattedMessage id="lists.set_unique_key" />
            )
          }
        />
      }
      onCancel={() => setOpen(false)}
      onOk={() => handleUniqueKeySave()}
    >
      <Form
        form={form}
        initialValues={{
          uniqueKeyColumns: config.uniqueKeyColumns || [],
          uniqueKey: formatName(config.uniqueKey),
        }}
      >
        <ModalContent>
          <div>
            <Label>
              <FormattedMessage id="lists.unique_key_modal.set_unique_key_with.label" />
            </Label>

            <Select
              isClearable={false}
              isMulti
              isSearchable
              options={uniqueKeyOptions}
              size="large"
              value={uniqueKeyColumns.map(columnOptionMapper)}
              onChange={(updatedValue) =>
                form.setFieldValue(
                  'uniqueKeyColumns',
                  updatedValue.map((value) => value.value),
                )
              }
            />
            <Form.Item hidden name="uniqueKeyColumns" />
          </div>
        </ModalContent>
      </Form>
    </Modal>
  );
};
