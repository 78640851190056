import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { type List } from 'data/modelling/lists';
import { type IntlShape } from 'react-intl';
import { formatName } from 'utils/data-formatter';

export const listNameValidator = (lists: (List | DimensionGroup)[], intl: IntlShape) => {
  return (_: unknown, value: string): Promise<string | void> => {
    if (lists?.find((l) => l.name === formatName(value))) {
      return Promise.reject(
        intl.formatMessage({
          id: 'lists.name.validation.duplicate',
        }),
      );
    }

    return Promise.resolve();
  };
};
