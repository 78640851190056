import { isEmpty } from 'lodash';
import { useRef } from 'react';
import { generateRandomNumber, promiseDelay } from 'utils';

/*
 When trying to edit rows in Lists, if a new row has been added and cells updated fast, wait for the creation to finish before making those edit save api calls
 */
export const useRunRowUpdatesAfterCreate = (): {
  onNewRowCreateStart: (rowId: number) => string;
  onNewRowCreateEnd: ({
    success,
    rowId,
    backendId,
  }: {
    success: boolean;
    rowId: number;
    backendId?: number;
  }) => void;
  checkIfRowBeingCreated: (rowId: number) => boolean;
  runAfterRowCreated: (rowId: number, action: (rowBackendId?: number) => Promise<unknown>) => void;
  onRowUpdateStart: (rowId: number) => string;
  isLastTaskOfRow: (rowId: number, taskId: string) => boolean;
} => {
  const newlyCreatedRowsMap = useRef<Record<string, { created: boolean; backendId?: number }>>({});
  const pendingToBeUpdatedRowsMap = useRef<Record<string, (id: number) => Promise<unknown>>>({});
  const isPendingActionsProcessing = useRef(false);
  const lastPendingActionInRows = useRef<Record<string, string>>({});

  const onNewRowCreateStart = (rowId: number) => {
    newlyCreatedRowsMap.current[rowId] = { created: false };

    const taskId = `${generateRandomNumber()}`;

    lastPendingActionInRows.current[rowId] = taskId;

    return taskId;
  };

  const onNewRowCreateEnd = ({
    success,
    rowId,
    backendId,
  }: {
    success: boolean;
    rowId: number;
    backendId?: number;
  }) => {
    if (success) {
      newlyCreatedRowsMap.current[rowId] = { created: true, backendId };
    } else {
      delete newlyCreatedRowsMap.current[rowId];
    }
  };

  const onRowUpdateStart = (rowId: number) => {
    const taskId = `${generateRandomNumber()}`;

    lastPendingActionInRows.current[rowId] = taskId;

    return taskId;
  };

  const isLastTaskOfRow = (rowId: number, taskId: string) => {
    return lastPendingActionInRows.current[rowId] === taskId;
  };

  const checkIfRowBeingCreated = (rowId: number) => {
    return (
      newlyCreatedRowsMap.current[rowId] && newlyCreatedRowsMap.current[rowId].created === false
    );
  };

  const runAfterRowCreated = (
    rowId: number,
    action: (rowBackendId?: number) => Promise<unknown>,
  ) => {
    if (checkIfRowBeingCreated(rowId)) {
      pendingToBeUpdatedRowsMap.current[rowId] = action;

      if (!isPendingActionsProcessing.current) {
        processPendingRowActions();
      }
    } else {
      action();
    }
  };

  const processPendingRowActions = async () => {
    isPendingActionsProcessing.current = true;

    await promiseDelay(2000);

    const pendingRowActions = pendingToBeUpdatedRowsMap.current;

    Object.keys(pendingRowActions).forEach(async (rowId) => {
      const rowCreateStateMap = newlyCreatedRowsMap.current[rowId];

      if (rowCreateStateMap.created) {
        pendingRowActions[rowId](rowCreateStateMap.backendId as number);
        await promiseDelay(500);

        delete pendingRowActions[rowId];
        delete newlyCreatedRowsMap.current[rowId];
      }
    });

    if (!isEmpty(pendingRowActions)) {
      processPendingRowActions();
    } else {
      isPendingActionsProcessing.current = false;
    }
  };

  return {
    onNewRowCreateStart,
    onNewRowCreateEnd,
    checkIfRowBeingCreated,
    runAfterRowCreated,
    onRowUpdateStart,
    isLastTaskOfRow,
  };
};
