import styled from 'styled-components';

export default {
  Wrapper: styled.div<{ bottom: number }>`
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 68px;
    bottom: ${({ bottom }) => `${bottom}px`};
    right: calc(50% - 71px);
    height: 44px;
    padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[8]}`};
    gap: ${({ theme }) => theme.spacing[8]};
    border-radius: ${({ theme }) => theme.borderRadius.s};
    border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    background-color: ${({ theme }) => theme.colors.gray00};
    box-shadow: ${({ theme }) => theme.shadow.hardLarge};
  `,
};
