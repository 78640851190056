import { type ListRow } from 'data/modelling/lists';
import { useEffect, useMemo, useState } from 'react';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { getFilteredRows } from './utils';

export const useGridRowData = (): {
  rowData: ListRow[];
  pinnedBottomRowData: { id: string; fullWidth: boolean }[];
} => {
  const pinnedBottomRowData = useMemo(() => [{ id: 'add-entry', fullWidth: true }], []);

  const rowData = useListStoreContext((s) => s.rows);
  const columnFilters = useListStoreContext((s) => s.columnFilters);
  const searchQuery = useListStoreContext((s) => s.searchQuery);
  const filteredRows = useListStoreContext((s) => s.filteredRows);
  const setFilteredRows = useListStoreContext((s) => s.setFilteredRows);
  const store = useListStoreInstance();

  const [searchedRowData, setSearchedRowData] = useState(filteredRows);

  useEffect(() => {
    setFilteredRows(
      getFilteredRows({
        rowData,
        columnFilters,
        columnNames: store.getState().config?.columnOrder || [],
        columnTypeMap: store.getState().config?.columnTypeMap || {},
        searchQuery: '',
      }),
    );
    // not to be updated when row data changes i.e. when an update is made
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters, store]);

  useEffect(() => {
    setSearchedRowData(
      getFilteredRows({
        rowData: filteredRows,
        columnNames: store.getState().config?.columnOrder || [],
        columnTypeMap: store.getState().config?.columnTypeMap || {},
        searchQuery,
      }),
    );
  }, [filteredRows, searchQuery, store]);

  useEffect(() => {
    queueMacroTask(() => store.getState().gridApi?.refreshCells()); // to update row number column and cell class rules
  }, [searchQuery, store]);

  return {
    rowData: searchedRowData,
    pinnedBottomRowData,
  };
};
