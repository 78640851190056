import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';

export const setupLocaleDate = async (): Promise<void> => {
  dayjs.extend(localeData);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(relativeTime);
  dayjs.extend(duration);
  dayjs.extend(isBetween);
  dayjs.extend(weekday);
  dayjs.extend(advancedFormat);
  dayjs.extend(isToday);
  dayjs.extend(isYesterday);
  dayjs.extend(minMax);
};
