import { type ICellEditor, type ICellEditorParams } from 'ag-grid-community';
import { Input } from 'components/ui/atomic-components';
import {
  type KeyboardEvent,
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { queueMacroTask } from 'utils/queue-macro-task';
import { checkIfLastRow } from '../utils';
import { CellEditorPopupBaseStyles } from '../utils/cell-editor-styles';
import { useCloseOnClickOutside } from '../utils/hooks/use-close-on-click-outside';
import { generateInitialValue } from './utils';

const StyledInput = styled(Input)<{ $width: number }>`
  ${CellEditorPopupBaseStyles};

  padding: ${({ theme: { spacing } }) => `0 ${spacing[12]}`};
`;

export const NumberCellEditor = forwardRef<ICellEditor, ICellEditorParams>(
  ({ value, column, api, rowIndex, eventKey, onKeyDown: defaultOnKeyDown, stopEditing }, ref) => {
    const wrapperDomRef = useRef<HTMLDivElement>(null);

    const cellWidth = column.getActualWidth();

    const [inputValue, setInputValue] = useState<string | undefined>(
      generateInitialValue({ value, eventKey }),
    );

    const onStopEditing = useCallback(() => {
      stopEditing(true);
    }, [stopEditing]);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Escape') {
        setInputValue(value);
        queueMacroTask(() => stopEditing(true));
      }

      if (e.code === 'Tab') {
        stopEditing(true);
        defaultOnKeyDown(e as unknown as globalThis.KeyboardEvent);
      }

      if (e.code === 'Enter') {
        const isLastRow = checkIfLastRow({ rowIndex: rowIndex + 1, api });

        stopEditing(isLastRow);
      }
    };

    useCloseOnClickOutside(wrapperDomRef, onStopEditing);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return inputValue;
        },
        isCancelAfterEnd: () => inputValue === value,
      };
    });

    return (
      <div ref={wrapperDomRef}>
        <StyledInput
          $width={cellWidth}
          autoFocus
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={onKeyDown}
        />
      </div>
    );
  },
);

NumberCellEditor.displayName = 'NumberCellEditor';
