import { Modal } from 'components/ui/atomic-components';
import { Form } from 'components/ui/atomic-components/form';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { UpdateFromDataForm } from './form';
import { useSetupListRecon } from './hooks/use-save-derived-list';
import { type FormProps } from './types';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    max-height: 600px;
    overflow: auto;
  }
`;

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const UpdateFromDataModal = ({ open, setOpen }: Props): ReactElement => {
  const listId = useListStoreContext((s) => s.id);
  const setupListReconMutation = useSetupListRecon();
  const [dimsForm] = Form.useForm<FormProps>();

  const resetForms = () => {
    dimsForm.resetFields();
  };

  const handleSave = async () => {
    const data = await dimsForm.validateFields();

    await setupListReconMutation.mutateAsync({
      listId,
      request: {
        columns: data.dimensions.map((dim) => ({
          source: dim.value,
          colName: dim.name || '',
        })),
        uniqueKeyColumns: data.uniqueKeyColumns.map((c) =>
          getColumnNameFromRawDimensionName(c.value),
        ),
      },
    });

    setOpen(false);
    resetForms();
  };

  return (
    <StyledModal
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={setupListReconMutation.isLoading}
      destroyOnClose
      okText={<FormattedMessage id="save" />}
      open={open}
      title={<FormattedMessage id="lists.reconciliation.modal.title" />}
      onCancel={() => {
        setOpen(false);
        resetForms();
      }}
      onOk={handleSave}
    >
      <UpdateFromDataForm form={dimsForm} />
    </StyledModal>
  );
};
