export const useLeftNavResize = (): { currentWidth?: number; onResize: (size: number) => void } => {
  const leftNavWidth = sessionStorage.getItem('foldersLeftNavWidth');
  const currentWidth = leftNavWidth ? Number(leftNavWidth) : undefined;

  const onResize = (size: number) => {
    sessionStorage.setItem('foldersLeftNavWidth', String(size));
  };

  return {
    currentWidth,
    onResize,
  };
};
