import {
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
  css,
} from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { defaultTheme } from 'styles/theme';
import { type ColorKey } from '../commons';

/**
 * @param bgColor Takes the color passed by the consumer
 * @returns A text color that is opposite side of the spectrum based on bgColor
 */
export const textColor = (bgColor?: ColorKey, disabled?: boolean): string => {
  if (disabled) {
    return defaultTheme.colors.textTertiary;
  }

  if (!bgColor) {
    return defaultTheme.colors.black900;
  }

  const colorSplit = bgColor.match(/[^\d]+|\d+/g);

  if (!colorSplit || colorSplit[0] === 'white') {
    return defaultTheme.colors.black900;
  }

  const [color, alpha] = colorSplit;

  if (Number(alpha) < 50) {
    return defaultTheme.colors[`${color}70` as ColorKey];
  }

  return defaultTheme.colors[`${color}10` as ColorKey];
};

export const controlLabelStyles = ({
  theme,
}: {
  theme: DefaultTheme;
}): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    padding: 0
      ${switchProp(prop('size', 'middle'), {
        extraSmall: theme.spacing[6],
        small: theme.spacing[6],
        middle: theme.spacing[8],
        large: theme.spacing[8],
      })};
    font-size: ${switchProp(prop('size', 'middle'), {
      extraSmall: theme.fontSize.xs,
      small: theme.fontSize.s,
      middle: theme.fontSize.m,
      large: theme.fontSize.l,
    })};
    line-height: ${switchProp(prop('size', 'middle'), {
      extraSmall: theme.lineHeight.xs,
      small: theme.lineHeight.s,
      middle: theme.lineHeight.m,
      large: theme.lineHeight.l,
    })};
    color: ${theme.colors.textTitle2};
    font-weight: ${theme.fontWeight.regular};
  `;
};
