import { QueryTableColumnDataType } from 'data/big-query';
import { type List } from 'data/modelling/lists';
import { AccessAction } from 'data/roles/permissions';
import { isNumericBigQueryType } from 'utils/big-query/is-numeric-big-query-type';
import { type DimensionGroup } from '../types';

const getColumnType = (type: QueryTableColumnDataType) => {
  if (isNumericBigQueryType(type)) {
    return QueryTableColumnDataType.Numeric;
  }

  return type;
};

export const constructListObjFromDimGroup = (data: DimensionGroup): List => {
  const columnTypeMap: Record<string, QueryTableColumnDataType> = {};

  data.columns.forEach((col) => {
    columnTypeMap[col.colName] = getColumnType(col.type);
  });

  return {
    id: data.id,
    name: data.name,
    displayName: data.displayName,
    description: data.description,
    isDerivedList: true,
    config: {
      columnOrder: data.columns.map((col) => col.colName),
      columnTypeMap,
      uniqueKey: data.uniqueKey || undefined,
      uniqueKeyColumns: data.uniqueKeyColumns,
    },
    rows:
      data.rows?.map((row, idx) => ({
        id: idx,
        position: idx,
        listId: data.id,
        data: row,
      })) || [],
    accessAction: AccessAction.ReadAccess,
    accessControlled: true,
    reconciliationEnabled: false,
    errors: data.errors,
    maskedColumns: data.maskedColumns,
  } as List;
};
