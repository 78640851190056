import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { FontXs, FontXxs } from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  height: 24px;
  padding: ${({ theme: { spacing } }) => `0 ${spacing[4]}`};
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const Count = styled.div`
  ${FontXxs};

  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ theme }) => theme.colors.primary500};
  color: ${({ theme }) => theme.colors.textNone};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Text = styled.div`
  ${FontXs};

  color: ${({ theme }) => theme.colors.textBody2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const SelectedCount = (): ReactElement => {
  const selectedRowIds = useListStoreContext((s) => s.selectedRowIds);

  return (
    <Wrapper>
      <Count>{selectedRowIds.length}</Count>
      <Text>
        <FormattedMessage id="planning.lists.float_actions.rows_selected" />
      </Text>
    </Wrapper>
  );
};
