import { InputNumber, type InputNumberProps } from 'antd';
import { ReactComponent as IcDecrementIcon } from 'assets/ic_decrement.svg';
import { ReactComponent as IcIncrementIcon } from 'assets/ic_increment.svg';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { FontXxs } from 'styles/typography';
import { Button } from '../button';
import { IconShell } from '../icon-shell';

const StyledNumberStepper = styled(InputNumber)`
  .ant-input-number {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.gray200};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
    box-shadow: none;
  }

  &.ant-input-number-group-wrapper
    .ant-input-number-wrapper-disabled
    > .ant-input-number-group-addon {
    border-color: ${({ theme }) => theme.colors.gray200};
    padding: ${({ theme: { spacing } }) => `${spacing[2]}`};
  }

  .ant-input-number-input-wrap {
    width: 18px;
    height: 24px;

    input {
      ${FontXxs};

      padding: ${({ theme }) => theme.spacing[4]};
      height: 100%;
      text-align: center;
      color: ${({ theme }) => theme.colors.gray600};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
  }

  .ant-btn-icon-only {
    height: 20px;
    width: 20px;
  }

  .ant-input-number-group-addon:first-child {
    border-radius: ${({ theme }) => `${theme.borderRadius.s} 0 0 ${theme.borderRadius.s}`};
  }

  .ant-input-number-group-addon:last-child {
    border-radius: ${({ theme }) => `0 ${theme.borderRadius.s} ${theme.borderRadius.s} 0`};
  }
`;

export const NumberStepper = ({
  max: maxVal,
  min: minVal,
  onDecrease: handleDecrease,
  onIncrease: handleIncrease,
  value = 0,
  buttonSize = 'extraSmall',
  ...rest
}: InputNumberProps & {
  onDecrease?: (updatedValue: number) => void;
  onIncrease?: (updatedValue: number) => void;
  buttonSize?: 'extraSmall' | 'small' | 'middle' | 'large' | 'extraLarge';
}): ReactElement => {
  const plusIconButton = (
    <Button
      disabled={maxVal !== undefined && (value ?? 0) >= maxVal}
      icon={<IconShell color="gray600" icon={IcIncrementIcon} size="xs" />}
      size={buttonSize}
      onClick={() => handleIncrease?.(Number(value) + 1)}
    />
  );

  const minusIconButton = (
    <Button
      disabled={minVal !== undefined && (value ?? 0) <= minVal}
      icon={<IconShell color="gray600" icon={IcDecrementIcon} size="xs" />}
      size={buttonSize}
      onClick={() => handleDecrease?.(Number(value) - 1)}
    />
  );

  return (
    <StyledNumberStepper
      addonAfter={plusIconButton}
      addonBefore={minusIconButton}
      disabled
      value={value}
      {...rest}
    />
  );
};
