import { type GridApi } from 'ag-grid-community';
import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import styled from 'styled-components';
import { addRowToGrid } from './utils';

export const GridFooterButton = styled(Button)`
  width: 100%;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
  border-left: 1px solid ${({ theme }) => theme.colors.gray200};
  display: flex;
  padding-left: ${({ theme }) => theme.spacing[28]};
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.textPrimary};

  :active {
    border-bottom: 0;
  }

  &&&&:not(:disabled) {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

interface Props {
  api: GridApi;
}

export const AddRowFooter = ({ api }: Props): ReactElement => {
  const listStore = useListStoreInstance();
  const searchQueryPresent = !!useListStoreContext((s) => s.searchQuery);

  const isAddRowDisabled = searchQueryPresent;

  const onAddRow = () => {
    const { addNewRow, config, rows } = listStore.getState();

    addRowToGrid({ api, config, rows, addNewRow });
  };

  return (
    <GridFooterButton
      disabled={isAddRowDisabled}
      icon={<IconShell icon={PlusIcon} />}
      tabIndex={0}
      type="text"
      onClick={onAddRow}
    >
      <FormattedMessage id="new_row" />
    </GridFooterButton>
  );
};
