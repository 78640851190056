import { type DefaultTheme, type FlattenInterpolation, type ThemeProps } from 'styled-components';
import { gridBorderedStyles } from './grid-bordered';
import { gridBorderLessStyles } from './grid-borderless';

export const getCustomThemeStyles = (
  themeNames?: string,
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  if (themeNames?.includes('grid-borderless')) {
    return gridBorderLessStyles;
  }

  return gridBorderedStyles;
};
