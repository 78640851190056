import { type FormInstance, type InputRef } from 'antd';
import { Input } from 'components/ui/atomic-components';
import { Form } from 'components/ui/atomic-components/form';
import { FormLabel } from 'components/ui/atomic-components/form/form-label';
import { useLists } from 'data/modelling/lists';
import { type ReactElement, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LIST_NAMING_PATTERN } from '../../constants';
import { listNameValidator } from './utils';

interface Props {
  handleCreateList: () => Promise<void>;
  form: FormInstance<{
    name: string;
  }>;
}

export const ListForm = ({ handleCreateList, form }: Props): ReactElement => {
  const intl = useIntl();
  const { data: lists = [] } = useLists({ includeDimensionGroups: true });

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 10);
  }, []);

  return (
    <Form form={form} preserve={false} onFinish={handleCreateList}>
      <Form.Item
        label={
          <FormLabel>
            <FormattedMessage id="lists.name" />
          </FormLabel>
        }
        name="name"
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'lists.name.validation.required' }),
          },
          {
            pattern: LIST_NAMING_PATTERN,
            message: intl.formatMessage({ id: 'lists.list_name.error' }),
          },
          {
            validator: listNameValidator(lists, intl),
          },
        ]}
      >
        <Input ref={inputRef} size="medium" />
      </Form.Item>
    </Form>
  );
};
