import {
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
  css,
} from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { type SegmentedProps } from '.';

export const segmentedStyles = ({
  theme,
  ...props
}: SegmentedProps & { theme: DefaultTheme }): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  const { type = 'primary' } = props;

  return css`
    &.ant-segmented {
      display: inline-flex;
      align-items: center;
      border-radius: ${type === 'primary' ? theme.borderRadius.m : theme.borderRadius.s};
      background-color: ${type === 'primary' ? theme.colors.bgNone : theme.colors.bgMedium};
      color: ${theme.colors.textLabel};
      padding: ${type === 'primary' ? theme.spacing[0] : theme.spacing[2]};
      font-size: ${switchProp(prop('size', 'middle'), {
        middle: theme.fontSize.xs,
        small: theme.fontSize.xs,
        extraSmall: theme.fontSize.xxs,
      })};
      line-height: ${switchProp(prop('size', 'middle'), {
        middle: theme.lineHeight.xs,
        small: theme.lineHeight.xs,
        extraSmall: theme.lineHeight.xxs,
      })};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      height: ${switchProp(prop('size', 'middle'), {
        middle: props.type === 'secondary' ? '25px' : '28px',
        small: props.type === 'icon' ? '24px' : '28px',
        extraSmall: props.type === 'icon' ? '20px' : '24px',
      })};

      &.ant-segmented-lg .ant-segmented-item-label {
        font-size: ${theme.fontSize.xs};
      }

      .ant-segmented-group {
        gap: ${type === 'primary' ? theme.spacing[8] : theme.spacing[2]};

        .ant-segmented-item {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          border-radius: ${type === 'primary' ? theme.borderRadius.s : theme.borderRadius.xs};
          height: ${switchProp(prop('type', 'primary'), {
            primary: switchProp(prop('size', 'middle'), {
              middle: '24px',
              small: '22px',
              extraSmall: '18px',
            }),
            icon: switchProp(prop('size', 'middle'), {
              middle: '24px',
              small: '18px',
              extraSmall: '16px',
            }),
            secondary: '21px',
          })};

          .ant-segmented-item-label {
            display: flex;
            align-items: center;
            padding: ${switchProp(prop('type', 'primary'), {
              primary: switchProp(prop('size', 'middle'), {
                middle: `0 ${theme.spacing[8]}`,
                small: `0 ${theme.spacing[6]}`,
                extraSmall: `0 ${theme.spacing[4]}`,
              }),
              icon: switchProp(prop('size', 'middle'), {
                middle: theme.spacing[4],
                small: theme.spacing[4],
                extraSmall: theme.spacing[2],
              }),
            })};
            min-height: 0;

            .ant-segmented-item-icon {
              display: flex;
              align-items: center;
              padding: ${type === 'icon' ? theme.spacing[2] : theme.spacing[0]};
              height: ${type === 'secondary' ? '12px' : '16px'};
              width: ${type === 'secondary' ? '12px' : '16px'};

              svg {
                width: ${switchProp(prop('size', 'middle'), {
                  middle: '16px',
                  small: '12px',
                  extraSmall: '12px',
                })};
                height: ${switchProp(prop('size', 'middle'), {
                  middle: '16px',
                  small: '12px',
                  extraSmall: '12px',
                })};
              }
            }
          }

          &.ant-segmented-item-disabled {
            color: ${theme.colors.gray300};
          }
        }

        .ant-segmented-item-selected {
          color: ${theme.colors.textBody1};
          background-color: ${type === 'primary' ? theme.colors.bgMedium : theme.colors.bgNone};
          box-shadow: none;
        }

        .ant-segmented-thumb {
          transition: all 1ms;
        }
      }
    }
  `;
};
