import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { DimensionGroupApi } from 'data/modelling/dimension-group/api';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { ListsApi } from 'data/modelling/lists/api';
import { type List } from 'data/modelling/lists/types';
import { PermissionList } from 'data/roles/permissions';
import { useHasScope } from 'utils/hooks';

type GeneralList = List | DimensionGroup;

export const useLists = ({
  includeDimensionGroups = false,
}: {
  includeDimensionGroups?: boolean;
} = {}): UseQueryResult<GeneralList[], unknown> => {
  const hasScope = useHasScope();
  const isAdmin = hasScope(PermissionList.All);

  const allListsQuery = useQuery(
    ['lists'],
    async () => {
      const promises: Promise<GeneralList[]>[] = [ListsApi.findAll()];

      if (includeDimensionGroups && isAdmin) {
        promises.push(DimensionGroupApi.findAll());
      }

      const [lists, dimGroups] = await Promise.all(promises);

      const combinedList = [...(lists || []), ...(dimGroups || [])];

      combinedList.sort((a: GeneralList, b: GeneralList) => a.name.localeCompare(b.name));

      return combinedList;
    },
    {
      keepPreviousData: false,
      staleTime: StaleTime.Short,
    },
  );

  return allListsQuery;
};
