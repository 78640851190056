import { PeriodOptions } from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { type Dimension } from 'data/dimension';
import { useTimeDimension } from 'data/dimension/hooks';
import { findDeep } from 'deepdash-es/standalone';
import { useMemo, type ReactElement } from 'react';
import { MenuItem } from './menu-item';

interface Props {
  selectedTimeValue: string;
  onClick?: (dimension: Dimension) => void;
}

export const TimeOption = ({ selectedTimeValue, onClick }: Props): ReactElement => {
  const timeDimension = useTimeDimension();

  const selectedTimeDisplayValue = useMemo(() => {
    if (!selectedTimeValue) {
      return null;
    }

    const rhsValue = selectedTimeValue.split('=')[1];

    return selectedTimeValue
      ? findDeep(PeriodOptions, (option) => option.value === rhsValue, {
          childrenPath: ['children'],
        })?.value?.label || rhsValue
      : null;
  }, [selectedTimeValue]);

  return (
    <MenuItem
      label={timeDimension.displayName}
      secondaryLabel={selectedTimeDisplayValue}
      onClick={() => onClick?.(timeDimension)}
    />
  );
};
