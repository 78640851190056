import { ReactComponent as ForbiddenIcon } from 'assets/empty/403.svg';
import { Result } from 'components/ui/atomic-components';
import { PermissionList } from 'data/roles/permissions';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useHasScope } from 'utils/hooks';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const InitialPageRedirect: FC<React.PropsWithChildren<unknown>> = () => {
  const hasScope = useHasScope();
  const intl = useIntl();

  if (hasScope(PermissionList.Boards)) {
    return <Navigate replace to="/reports" />;
  } else if (hasScope(PermissionList.Model)) {
    return <Navigate replace to="/models" />;
  }

  // if no permission to pages, show no permission message
  return (
    <Wrapper>
      <Result
        icon={<ForbiddenIcon />}
        subTitle={intl.formatMessage({ id: '403.sub_title' })}
        title={intl.formatMessage({ id: '403.title' })}
      />
    </Wrapper>
  );
};
