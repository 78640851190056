import { type InputRef } from 'antd';
import { ReactComponent as EmptyIcon } from 'assets/v2/empty_24.svg';
import { ReactComponent as SearchIcon } from 'assets/v2/search.svg';
import {
  type ReactNode,
  type ReactElement,
  type PropsWithChildren,
  useEffect,
  useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, IconShell } from '../atomic-components';
import styles from './styles';

const {
  SelectAllWrapper,
  StyledInputWrapper,
  Wrapper,
  ListWrapper,
  Footer,
  StyledDivider,
  StyledInput,
  StyledButton,
  EmptyWrapper,
  Title,
} = styles;

interface Props {
  title?: ReactNode;
  searchQuery: string;
  disableApply?: boolean;
  isEmpty?: boolean;
  className?: string;
  onSearchChange: (val: string) => void;
  onSelectAll?: () => void;
  onClear?: () => void;
  onApply?: () => void;
}

export const FilterPopupSkeleton = ({
  title,
  searchQuery,
  disableApply,
  isEmpty,
  className,
  onSearchChange,
  onSelectAll,
  onClear,
  onApply,
  children,
}: PropsWithChildren<Props>): ReactElement => {
  const intl = useIntl();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Wrapper className={className}>
      <StyledInputWrapper>
        {title && <Title>{title}</Title>}

        <StyledInput
          ref={inputRef}
          $inputType="ghost"
          placeholder={intl.formatMessage({ id: 'search' })}
          prefix={<IconShell color="gray400" icon={SearchIcon} />}
          size="small"
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </StyledInputWrapper>

      {isEmpty && (
        <EmptyWrapper>
          <IconShell icon={EmptyIcon} />
          {searchQuery ? (
            <FormattedMessage id="no_matches_found" />
          ) : (
            <FormattedMessage id="no_results" />
          )}
        </EmptyWrapper>
      )}

      {!isEmpty && (onSelectAll || onClear) && (
        <>
          <SelectAllWrapper>
            {onSelectAll && (
              <Button type="link" onClick={onSelectAll}>
                <FormattedMessage id="select_all" />
              </Button>
            )}

            {onClear && (
              <Button type="link" onClick={onClear}>
                <FormattedMessage id="clear" />
              </Button>
            )}
          </SelectAllWrapper>

          <StyledDivider />
        </>
      )}

      <ListWrapper>{children}</ListWrapper>

      {!isEmpty && onApply && (
        <>
          <StyledDivider />

          <Footer>
            <StyledButton
              disabled={disableApply}
              size="small"
              type="primary"
              onClick={() => onApply()}
            >
              <FormattedMessage id="apply" />
            </StyledButton>
          </Footer>
        </>
      )}
    </Wrapper>
  );
};
