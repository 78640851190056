import { Empty } from 'components/ui/atomic-components/empty';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { b } from 'styles/html-elements';
import { getAssetUrl } from 'utils';

const Image = styled.img``;

const NoMatch: FC<React.PropsWithChildren<{ searchText: string }>> = ({ searchText }) => (
  <Empty
    description={<FormattedMessage id="integrations.tables_grid.no_match_search_desc" />}
    image={<Image src={getAssetUrl('v2/empty/no-data-yet-placeholder.webp')} />}
    title={
      <FormattedMessage
        id="integrations.tables_grid.no_match_search_title"
        values={{ strong: b, searchText }}
      />
    }
  />
);

export { NoMatch };
