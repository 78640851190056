import { Switch, type SwitchProps } from 'antd';
import { type FC } from 'react';
import styled from 'styled-components';
import { toggleStyles } from './styles';

type Props = SwitchProps;

const AntSwitch = styled(Switch)`
  ${({ theme }) => toggleStyles({ theme })};
`;

const ToggleLabel = styled.span<{ $size: string; $disabled: boolean }>`
  font-size: ${({ theme: { fontSize }, $size }) => {
    return $size === 'default' ? fontSize.l : fontSize.s;
  }};
  line-height: ${({ theme: { lineHeight }, $size }) => {
    return $size === 'default' ? lineHeight.l : lineHeight.s;
  }};
  color: ${({ theme: { colors }, $disabled }) => {
    return $disabled ? colors.textPlaceholder : colors.textTitle2;
  }};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
`;

const Wrapper = styled.div<{ $size: string }>`
  display: flex;
  align-items: center;
  gap: ${({ $size }) => ($size === 'default' ? '8px' : '4px')};
`;

export const Toggle: FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => {
  return props.children ? (
    <Wrapper $size={props.size || 'default'}>
      <AntSwitch className={className} {...props} />
      <ToggleLabel $disabled={Boolean(props.disabled)} $size={props.size || 'default'}>
        {props.children}
      </ToggleLabel>
    </Wrapper>
  ) : (
    <AntSwitch className={className} {...props} />
  );
};
