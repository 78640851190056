import { type SizeType } from 'antd/lib/config-provider/SizeContext';
import { ReactComponent as BackIcon } from 'assets/v2/chevron-left.svg';
import { type FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontS } from 'styles/typography';
import { Button } from '../button';
import { IconShell } from '../icon-shell';
import { Space } from '../space';

const Wrapper = styled(Button).attrs({
  type: 'text',
})`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.iconDefault};
`;

export const BackButton: FC<
  React.PropsWithChildren<{
    size?: SizeType;
    to: Omit<string, 'from-history'> | 'from-history';
    onClick?(e: React.MouseEvent<Element, MouseEvent>): void;
  }>
> = ({ size, to, onClick }) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    if (to === 'from-history') {
      navigate(-1);
    } else if (to) {
      navigate(to as string);
    }
  };

  return (
    <Wrapper
      icon={<IconShell icon={BackIcon} size="md" />}
      size={size}
      onClick={onClick || navigateTo}
    />
  );
};

const BackButtonWithLink = styled(Space).attrs({
  size: 'small',
})`
  ${FontS};

  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const LinkItem = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const BackButtonLink: FC<React.PropsWithChildren<{ to: string }>> = ({ children, to }) => {
  return (
    <BackButtonWithLink>
      <BackButton size="small" to={to} />
      <LinkItem to={to}>{children}</LinkItem>
    </BackButtonWithLink>
  );
};
