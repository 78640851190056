import { DimensionBlankIntermValue } from 'components/ui/dimensions-bar-v2/multiselect/utils';
import { DimensionAllMarker } from 'data/dimension';
import { isUndefined } from 'lodash';

export const getSelectedValue = (value?: string): string => {
  if (isUndefined(value)) {
    return DimensionAllMarker;
  }

  if (value === '') {
    return DimensionBlankIntermValue;
  }

  return value;
};
