import { useOktaAuth } from '@okta/okta-react';
import { ReactComponent as LogoutIcon } from 'assets/v2/logout.svg';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { Analytics } from 'config/analytics';
import { signout } from 'data/users';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { LeftNavWrapper } from './left-nav-item';

export const LogoutButton = (): ReactElement => {
  const { oktaAuth } = useOktaAuth();
  const logout = () => {
    signout(oktaAuth);
    Analytics.track('Logout');
  };

  return (
    <LeftNavWrapper onClick={logout}>
      <IconShell icon={LogoutIcon} size="md" />
      <span>
        <FormattedMessage id="logout" />
      </span>
    </LeftNavWrapper>
  );
};
