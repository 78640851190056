import { type EditorView } from '@codemirror/view';
import { type ItemType } from 'components/ui/atomic-components/menu';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { CustomOption } from '../custom-option';

interface Props {
  selectedValue?: string;
  isSelected?: boolean;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
  onSelect?: () => void;
  onPressEnter?: (value: string) => void;
  setEditorView?: (editorView: EditorView | null) => void;
}

export const generateCustomOption = ({
  selectedValue,
  isSelected,
  customTimeAutoCompletionOptions,
  onSelect,
  onPressEnter,
  setEditorView,
}: Props): ItemType => {
  return {
    key: 'custom',
    label: (
      <CustomOption
        customTimeAutoCompletionOptions={customTimeAutoCompletionOptions}
        isSelected={isSelected}
        selectedValue={selectedValue}
        setEditorView={setEditorView}
        onPressEnter={onPressEnter}
      />
    ),
    onClick: onSelect,
  };
};
