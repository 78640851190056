import { Empty as AntdEmpty, type EmptyProps } from 'antd';
import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';

const StyledEmpty = styled(AntdEmpty)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[12]};
  margin-inline: 0;

  .ant-empty-image {
    margin: 0;

    img {
      object-fit: contain;
    }
  }

  .ant-empty-description {
    ${FontXs};

    color: ${({ theme }) => theme.colors.textDisabled};
  }

  .ant-empty-footer {
    margin-top: ${({ theme }) => theme.spacing[24]};
  }

  .ant-empty-image:empty {
    margin: 0;
  }
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.textBody2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const Empty: FC<React.PropsWithChildren<EmptyProps & { title?: ReactNode }>> = ({
  title,
  description,
  ...props
}) => {
  return (
    <StyledEmpty
      description={
        <>
          <Title>{title}</Title>
          {description}
        </>
      }
      {...props}
    />
  );
};
