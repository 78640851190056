import { type FormInstance } from 'antd';
import { ReactComponent as KeyIcon } from 'assets/v2/key.svg';
import { useChipColoring } from 'components/modules/modelling/_commons/dimension-chip-coloring/use-chip-coloring';
import {
  AsyncDimensionsSelect,
  type DimensionSelectOption,
} from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select';
import { MultiLabelComponent } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select/multi-label-component';
import { OptionComponent } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select/option-component';
import { Form, IconText, Select } from 'components/ui/atomic-components';
import { useMemo, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type MultiValue } from 'react-select';
import styled from 'styled-components';
import { defaultTheme } from 'styles/theme';
import { formatName } from 'utils/data-formatter';
import { type FormProps } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  overflow: auto;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.gray600};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const columnOptionMapper = (option: DimensionSelectOption) => ({
  value: option.value,
  label: formatName(option.name),
  parentSourceDisplayName: option.parentSourceDisplayName,
});

interface Props {
  form: FormInstance<FormProps>;
  initialValues?: Partial<FormProps>;
}

export const UpdateFromDataForm = ({ form, initialValues }: Props): ReactElement => {
  const intl = useIntl();
  const dimensions = Form.useWatch('dimensions', form);

  const dims = useMemo(() => dimensions?.map((d) => d.value) || [], [dimensions]);
  const chipColoringScheme = useChipColoring({ dims });

  const uniqueKeyOptions = (dimensions || []).map(columnOptionMapper);

  const onDimensionsChange = (newValue: MultiValue<DimensionSelectOption>) => {
    const dims = newValue?.map((d) => d.value) || [];

    const uniqueKeyColumns = form
      .getFieldValue('uniqueKeyColumns')
      .filter((column: { value: string }) => {
        return dims.includes(column.value);
      });

    form.setFieldsValue({ dimensions: newValue as DimensionSelectOption[], uniqueKeyColumns });
  };

  return (
    <Form form={form} initialValues={initialValues}>
      <Wrapper>
        <div>
          <Label>
            <FormattedMessage id="dimension_groups.create_edit_form.select_dimensions.label" />
          </Label>
          <Form.Item
            name="dimensions"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'lists.update_from_data.modal.columns_required',
                }),
              },
            ]}
          >
            <AsyncDimensionsSelect
              chipColoringScheme={chipColoringScheme}
              groupRelatedResults
              isMulti
              preventDimensionGroupSearch
              searchAllTables
              onChange={onDimensionsChange}
            />
          </Form.Item>
        </div>

        <div>
          <Label>
            <IconText
              gap={4}
              icon={KeyIcon}
              text={
                <FormattedMessage id="dimension_groups.create_edit_form.set_unique_key.label" />
              }
            />
          </Label>
          <Form.Item
            name="uniqueKeyColumns"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'lists.update_from_data.modal.unique_columns_required',
                }),
              },
            ]}
          >
            <Select
              components={{ Option: OptionComponent, MultiValueLabel: MultiLabelComponent }}
              isClearable={false}
              isDisabled={dimensions?.length === 0}
              isMulti
              isSearchable
              menuPortalTarget={document.body}
              options={uniqueKeyOptions}
              size="large"
              styles={{
                multiValue: (base, props) => {
                  const { background, border } = chipColoringScheme(props.data.value);

                  return {
                    ...base,
                    backgroundColor: background,
                    border: `1px solid ${border}`,
                    borderRadius: defaultTheme.borderRadius.xs,
                  };
                },
              }}
            />
          </Form.Item>
        </div>
      </Wrapper>
    </Form>
  );
};
