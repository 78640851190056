import { type UseMutationResult, useMutation } from '@tanstack/react-query';
import { ListsApi } from 'data/modelling/lists';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useAccessControlMutation = (): UseMutationResult<
  void,
  unknown,
  {
    listId: number;
    listName: string;
    roleIds: number[];
    isAccessControlled: boolean;
  },
  unknown
> => {
  return useMutation(
    ({
      listId,
      listName,
      roleIds,
      isAccessControlled,
    }: {
      listId: number;
      listName: string;
      roleIds: number[];
      isAccessControlled: boolean;
    }) =>
      ListsApi.setAccessControl({
        listId,
        listName,
        roleIds,
        isAccessControlled,
      }),
    {
      onError: defaultApiErrorHandler,
    },
  );
};
