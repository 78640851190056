import { type EditorView } from '@codemirror/view';
import { ReactComponent as ChevronLeftIcon } from 'assets/v2/chevron-left.svg';
import { PeriodOptions } from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { convertTimeValueToExpression } from 'components/ui/atomic-components/formula-editor-v2/utils/convert-time-value-to-expression';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { Menu } from 'components/ui/atomic-components/menu';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { useTimeDimension } from 'data/dimension/hooks';
import { findDeep } from 'deepdash-es/standalone';
import { useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { constructPeriodOptions } from './menu-options';
import { type PeriodOptionType } from './menu-options/types';

const Title = styled.div`
  ${FontXs};

  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[12]}`};
  padding-bottom: 0;
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const TimeValueSelector = ({
  value,
  onClickBack,
  onItemSelect,
  onCustomValueEnter,
  setCustomEditorView,
  customTimeAutoCompletionOptions,
}: {
  value?: string;
  onClickBack?: () => void;
  onItemSelect?: (item: string) => void;
  onCustomValueEnter?: (value: string) => void;
  setCustomEditorView?: (editorView: EditorView | null) => void;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
}): ReactElement => {
  const timeDimemsion = useTimeDimension();
  const [selectedValue, setSelectedValue] = useState(value || '');

  const initCustomOptionSelected = !findDeep(
    PeriodOptions,
    (option) => option.value === value?.split('=')[1],
    {
      childrenPath: ['children'],
    },
  )?.value;

  const [isCustomOptionSelected, setIsCustomOptionSelected] = useState(initCustomOptionSelected);

  const onItemClick = (option: PeriodOptionType) => {
    if (!option.value) {
      return;
    }

    const selectedValue = `${option.value[0]}=${option.value}`;

    setSelectedValue(selectedValue);
    setIsCustomOptionSelected(false);
    onItemSelect?.(selectedValue);
  };

  const onClickCustomOption = () => {
    setIsCustomOptionSelected(true);
    setSelectedValue('');
  };

  const onCustomOptionEnter = (value: string) => {
    if (!value) {
      onCustomValueEnter?.(value);

      return;
    }

    const expr = convertTimeValueToExpression(value);

    setSelectedValue(expr);
    onCustomValueEnter?.(expr);
  };

  const options = constructPeriodOptions({
    selectedValue,
    onItemClick,
    isCustomOptionSelected,
    onClickCustomOption,
    onCustomOptionEnter,
    customTimeAutoCompletionOptions,
    setEditorView: setCustomEditorView,
  });

  return (
    <>
      <Title>
        {onClickBack && <IconShell cursor="pointer" icon={ChevronLeftIcon} onClick={onClickBack} />}
        {timeDimemsion.displayName}
      </Title>
      <Menu items={options} selectedKeys={[selectedValue]} />
    </>
  );
};
