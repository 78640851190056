import { AuthorisedAccess } from 'components/modules/authorised-access';
import { AsyncSwitcher } from 'components/ui/async-switcher';
import { PageLayout } from 'components/ui/layout';
import { type IntegrationConnectedItem } from 'data/integrations/fivetran';
import { useGetConnectedIntegrations } from 'data/integrations/fivetran/hooks';
import { PermissionList } from 'data/roles/permissions';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';

export const DataRedirect: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'data' });
  const connectionsQuery = useGetConnectedIntegrations();

  return (
    <AuthorisedAccess permissionType={PermissionList.Datasets}>
      <PageLayout title={title}>
        <AsyncSwitcher
          {...connectionsQuery}
          components={{ Empty: () => <Navigate replace to="/data/integrations" /> }}
        >
          {(data: IntegrationConnectedItem[]) => {
            return data.length > 0 && <Navigate replace to={'/data/connected'} />;
          }}
        </AsyncSwitcher>
      </PageLayout>
    </AuthorisedAccess>
  );
};
