import { indentUnit } from '@codemirror/language';
import { Compartment, EditorState } from '@codemirror/state';
import { EditorView, tooltips, placeholder } from '@codemirror/view';
import { ReactComponent as WandIcon } from 'assets/v2/wand.svg';
import { Button, type ButtonProps, IconShell } from 'components/ui/atomic-components';
import {
  type ExpressionSecondaryTextLookup,
  type FormulaAutocompleteOption,
} from 'components/ui/codemirror-v2/formula-bar/types';
import { indentFormula } from 'data/formula-editor/utils/indent-formula';
import {
  useRef,
  useEffect,
  type ReactElement,
  useState,
  useMemo,
  memo,
  type ReactNode,
} from 'react';
import {
  dtmlLanguage,
  syntaxHighlighting,
  closeBrackets,
  history,
  expressionConstructs,
  functionInfoTooltip,
  autoCompletion,
  updateListener,
  keymap,
  bracketMatching,
  beautify,
} from './extensions';
import { useFormulaEditorMetrics } from './hooks/use-formula-editor-metrics';
import styles from './styles';

const { Container, StyledEditor, TooltipWrapper, ButtonPanel } = styles;

const AUTO_COMPLETE_DIMENSION_OPTION_TYPES = [
  'DimensionGroupExpression',
  'DimensionExpression',
  'DatasetExpression',
];

const expressionConstructsCompartment = new Compartment();
const autoCompletionCompartment = new Compartment();
const beautifyFormulaCompartment = new Compartment();

interface ExtraButtonProps extends ButtonProps {
  text: string | ReactNode;
}

interface Props {
  value?: string;
  placeholderText?: string;
  grayOut?: boolean;
  readOnly?: boolean;
  autoCompletionOptions?: FormulaAutocompleteOption[];
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
  domain?: 'planning' | 'monitoring';
  extraButtonProps?: ExtraButtonProps;
  showBeautifyButton?: boolean;
  setEditorView?: (editorView: EditorView | null) => void;
  onPressEnter?: (value?: string) => void;
  onChange?: (value?: string) => void;
}

export const FormulaEditor = memo(
  ({
    value,
    placeholderText,
    grayOut,
    readOnly,
    autoCompletionOptions,
    customTimeAutoCompletionOptions,
    domain = 'planning',
    extraButtonProps,
    showBeautifyButton,
    setEditorView,
    onPressEnter,
    onChange,
  }: Props): ReactElement => {
    const editorDomRef = useRef(null);
    const editorViewRef = useRef<EditorView>();
    const tooltipDivRef = useRef<HTMLDivElement>(null);

    const { data: metrics, isLoading } = useFormulaEditorMetrics(domain);

    const [isFormulaBeautified, setIsFormulaBeautified] = useState(false);

    const beautifyFormula = () => {
      const doc = editorViewRef.current?.state.doc.toString() ?? '';
      const indentedFormula = indentFormula(doc);

      editorViewRef.current?.dispatch({
        changes: {
          from: 0,
          to: doc.length,
          insert: indentedFormula,
        },
        selection: { anchor: indentedFormula.length, head: indentedFormula.length },
      });

      editorViewRef.current?.focus();
      setIsFormulaBeautified(true);
    };

    const secondaryTextLookup = useMemo(() => {
      if (readOnly) {
        return undefined;
      }
      const sourceNameLookup: ExpressionSecondaryTextLookup = {};

      autoCompletionOptions?.forEach((opt) => {
        if (
          opt.secondaryDisplayText &&
          opt.type &&
          AUTO_COMPLETE_DIMENSION_OPTION_TYPES.includes(opt.type)
        ) {
          sourceNameLookup[opt.replacementText] = opt.secondaryDisplayText;
        }
      });

      return sourceNameLookup;
    }, [autoCompletionOptions, readOnly]);

    useEffect(() => {
      if (editorDomRef.current === null || tooltipDivRef.current === null) {
        return;
      }

      const startState = EditorState.create({
        doc: value,
        extensions: [
          keymap({ onPressEnter }),
          dtmlLanguage,
          syntaxHighlighting,
          closeBrackets,
          history,
          expressionConstructsCompartment.of(
            expressionConstructs({
              metrics,
              domain,
              secondaryTextLookup,
              readOnly: !!readOnly,
              customTimeAutoCompletionOptions,
            }),
          ),
          tooltips({ parent: tooltipDivRef.current, position: 'absolute' }),
          functionInfoTooltip,
          autoCompletionCompartment.of(autoCompletion(autoCompletionOptions || [])),
          placeholder(placeholderText || ''),
          updateListener({ onChange }),
          indentUnit.of('    '),
          beautifyFormulaCompartment.of(isFormulaBeautified ? beautify() : []),
          bracketMatching,
        ],
      });

      const view = new EditorView({
        state: startState,
        parent: editorDomRef.current,
      });

      // auto-focus and set the cursor position to the end of the line
      if (!readOnly) {
        view.focus();
        view.dispatch({ selection: { anchor: value?.length ?? 0, head: value?.length ?? 0 } });
      }

      setEditorView?.(view);
      editorViewRef.current = view;

      return () => {
        view.destroy();
        setEditorView?.(null);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, placeholderText, secondaryTextLookup]);

    useEffect(() => {
      editorViewRef.current?.dispatch({
        effects: expressionConstructsCompartment.reconfigure(
          expressionConstructs({
            metrics,
            domain,
            secondaryTextLookup,
            readOnly: !!readOnly,
            customTimeAutoCompletionOptions,
          }),
        ),
      });
    }, [
      customTimeAutoCompletionOptions,
      domain,
      metrics,
      readOnly,
      isLoading,
      secondaryTextLookup,
    ]);

    useEffect(() => {
      editorViewRef.current?.dispatch({
        effects: autoCompletionCompartment.reconfigure(autoCompletion(autoCompletionOptions || [])),
      });
    }, [autoCompletionOptions]);

    useEffect(() => {
      const textValue = !isLoading ? value : '';

      editorViewRef.current?.dispatch({
        changes: {
          from: 0,
          to: editorViewRef.current?.state.doc.length,
          insert: textValue,
        },
        selection: { anchor: textValue?.length ?? 0, head: textValue?.length ?? 0 },
      });
    }, [isLoading, value]);

    return (
      <Container>
        <StyledEditor
          ref={editorDomRef}
          grayOut={grayOut}
          isFormulaBeautified={isFormulaBeautified}
        />

        <TooltipWrapper ref={tooltipDivRef} />

        <ButtonPanel>
          {extraButtonProps && <Button {...extraButtonProps}>{extraButtonProps.text}</Button>}

          {showBeautifyButton && (
            <Button
              icon={<IconShell icon={WandIcon} />}
              size="extraSmall"
              onClick={beautifyFormula}
            />
          )}
        </ButtonPanel>
      </Container>
    );
  },
);

FormulaEditor.displayName = 'FormulaEditor';
