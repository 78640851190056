import { clone } from 'lodash';
import { type ListsStoreApi } from 'store/lists/store';
import { queueMacroTask } from 'utils/queue-macro-task';
import { type StoreApi } from 'zustand';
import { type UndoFilterChangeMeta } from '../types';

interface Props {
  meta: UndoFilterChangeMeta;
  listStore: StoreApi<ListsStoreApi>;
  type: 'undo' | 'redo';
}

export const undoColumnFilterChange = ({ meta, listStore, type }: Props): void => {
  const { gridApi, startUndoOperation, startRedoOperation, setColumnFilters, pushToUndoRedoStack } =
    listStore.getState();

  const { column, previousFilter, currentFilter } = meta;

  if (type === 'undo') {
    startUndoOperation();
  } else {
    startRedoOperation();
  }

  setColumnFilters(previousFilter);
  queueMacroTask(() => gridApi?.refreshCells({ columns: ['row-index'] }));

  pushToUndoRedoStack({
    type: 'column-filter-change',
    meta: {
      column,
      previousFilter: clone(currentFilter),
      currentFilter: clone(previousFilter),
    },
  });
};
