import { type EditorView } from '@codemirror/view';
import { Popover } from 'components/ui/atomic-components';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { TimeDimensionName } from 'data/dimension';
import { useRef, useState, type ReactElement } from 'react';
import { convertTimeValueToExpression } from '../../../utils/convert-time-value-to-expression';
import { FormulaBarPopoverOverlayClass } from '../../constants';
import { DimensionsSelectorMenu } from '../dimensions-selector-menu';

interface Props {
  metricName: string;
  subscriptsMap: Record<string, string[]>;
  onChange: (map: Record<string, string[]>) => void;
  children?: (open: boolean) => ReactElement;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
}

export const FilterButton = ({
  metricName,
  subscriptsMap,
  onChange,
  children,
  customTimeAutoCompletionOptions,
}: Props): ReactElement => {
  const [open, setOpen] = useState(false);

  const customTimeEditorView = useRef<EditorView | null>(null);

  const closeMenu = () => setOpen(false);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);

    if (open) {
      return;
    }

    const value = customTimeEditorView.current?.state.doc.toString();

    if (!value) {
      return;
    }

    const expr = convertTimeValueToExpression(value);
    const selectionsMapState = { ...subscriptsMap };

    selectionsMapState[TimeDimensionName] = [expr];

    onChange?.(selectionsMapState);
  };

  return (
    <Popover
      content={
        <DimensionsSelectorMenu
          closeMenu={closeMenu}
          customTimeAutoCompletionOptions={customTimeAutoCompletionOptions}
          initialSelectionsMap={subscriptsMap}
          metricName={metricName}
          setCustomTimeEditorView={(view) => (customTimeEditorView.current = view)}
          onChange={onChange}
        />
      }
      destroyTooltipOnHide
      open={open}
      overlayClassName={FormulaBarPopoverOverlayClass}
      placement="bottomLeft"
      onOpenChange={handleOpenChange}
    >
      {children?.(open)}
    </Popover>
  );
};
