import { ReactComponent as EditIcon } from 'assets/v2/edit.svg';
import { Button, IconShell, Popover } from 'components/ui/atomic-components';
import { isFilterDateSelectorOpen } from 'components/ui/filter-rule-value-selector-v2/utils';
import { type VisualQueryFilterRule } from 'data/big-query';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterRuleSelector } from '../../filter-rule-selector';

interface Props {
  selectedDimensions: string[];
  rule: VisualQueryFilterRule;
  onUpdate: (rule: VisualQueryFilterRule) => void;
}

export const EditPopover = ({ selectedDimensions, rule, onUpdate }: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState<VisualQueryFilterRule | undefined>(rule);

  return (
    <Popover
      afterOpenChange={(open) => {
        if (!open) {
          setSelectedRule(undefined);
        }
      }}
      confirmDisabled={!selectedRule}
      confirmText={<FormattedMessage id="done" />}
      content={
        <FilterRuleSelector
          existingRule={rule}
          selectedDimensions={selectedDimensions}
          setSelectedRule={(rule) => setSelectedRule(rule)}
        />
      }
      destroyTooltipOnHide
      open={isOpen}
      placement="topRight"
      zIndex={1030}
      onConfirm={() => {
        if (selectedRule) {
          onUpdate(selectedRule);
          setIsOpen(false);
        }
      }}
      onOpenChange={(v) => {
        if (isFilterDateSelectorOpen()) {
          return;
        }
        setIsOpen(v);
      }}
    >
      <Button icon={<IconShell color="gray400" icon={EditIcon} />} size="extraSmall" type="text" />
    </Popover>
  );
};
