import { LeftNavLayout } from 'components/ui/layout/left-nav-layout-v2';
import { useLeftNavResize } from 'components/ui/layout/left-nav-layout-v2/use-left-nav-resize';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { useState, type ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LeftNav } from './left-nav';
import { PageHeader } from './page-header';
import { RightSectionList } from './right-section';
import { RightSectionDimensionGroup } from './right-section-dim-group';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme: { font } }) => font.text};
  color: ${({ theme: { colors } }) => colors.textBody1};
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ListsEditorModule = (): ReactElement => {
  const { listId } = useParams() as { listId: string };

  const isDimGroup = listId.startsWith(DIM_GROUP_ID_PREFIX);

  const [showLeftNav, setShowLeftNav] = useState(true);

  const { onResize: onLeftNavResize, currentWidth: currentLeftNavWidth } = useLeftNavResize();

  const toggleShowLeftNav = () => setShowLeftNav((state) => !state);

  return (
    <Wrapper>
      <PageHeader />

      <Content>
        <LeftNavLayout>
          <LeftNavLayout.ResizableLeftPanel
            $hide={!showLeftNav}
            defaultWidth={currentLeftNavWidth}
            onPanelResize={onLeftNavResize}
          >
            <LeftNav />
          </LeftNavLayout.ResizableLeftPanel>

          {showLeftNav && <LeftNavLayout.ResizeHandle />}

          <LeftNavLayout.ResizableRightPanel>
            {!isDimGroup && (
              <RightSectionList
                key={listId}
                showLeftNav={showLeftNav}
                toggleShowLeftNav={toggleShowLeftNav}
              />
            )}

            {isDimGroup && (
              <RightSectionDimensionGroup
                showLeftNav={showLeftNav}
                toggleShowLeftNav={toggleShowLeftNav}
              />
            )}
          </LeftNavLayout.ResizableRightPanel>
        </LeftNavLayout>
      </Content>
    </Wrapper>
  );
};
